import { Nullable, SnakeCaseKeysToCamelCase } from '@eventbrite/ts-utils';
import { CreatorCollections, PublicCollectionsCamel } from './collections';

export interface DestinationResponse {
    events: DestinationEvent[];
    pagination: {};
    promoted_events?: DestinationEvent[];
}

export interface FormattedEventResponse {
    events: FormattedEvent[];
    promotedEvents?: FormattedEvent[];
    [key: string]: any;
}

// TODO: This should be renamed to ShareOptionsProps once legacy card is deprecated
export interface EventCardShareOptionsProps {
    /**
     * The ID of event that is being shared
     */
    eventId: string;
    /**
     * The name of event that is being shared
     */
    eventName: string;
    /**
     * The Url of event that is being shared
     */
    eventUrl: string;
    /**
     * If mobile or not
     */
    isMobile: boolean;
    twitterHandle: string;
    serverUrl: string;
    facebookOptions: {
        appId: string;
        version: string;
        xfbml: boolean;
        page?: string;
        locale?: string;
    };
    utmOptions?: ShareUTMOptions;
    trackingCategory?: string;
}

export type ShareUTMOptions = {
    'utm-campaign': string;
    'utm-content': string;
    'utm-medium': string;
    'utm-term': string;
    'utm-source'?: string;
    'utm-experiment'?: string;
    'utm-share-source'?: string;
};

interface EventPrice {
    currency: string;
    currencyFormat?: string;
}

export interface EventMinPrice extends EventPrice {
    minPriceValue: number;
}

export interface EventMaxPrice extends EventPrice {
    maxPriceValue: number;
}

/**
 * Data specific to a particular Event
 * formatted for rendering via the EventCard
 */
export interface FormattedEvent {
    /**
     * ID of the event
     */
    id: string;
    /**
     * URL to the event image
     */
    imageUrl: string;
    /**
     * Is a repeting event?
     */
    isRepeatingEvent?: boolean;
    /**
     * Whether event is free to attend or not
     */
    isFree: boolean;
    /**
     * Whether event is being advertised
     */
    isPromoted?: boolean;
    /**
     * Wheter event is private
     */
    isPrivate?: boolean;
    /**
     * Whether event is protected by password or requires invitation
     */
    isRestricted?: boolean;
    /**
     * Metadata for tracking promoted event placements
     */
    promotedListingMetadata?: {
        adId: string;
        indexInSearchResults: number;
        isPromotedEvent: boolean;
        placementId: string;
    };
    /**
     * If the event is located online or at
     * a physical location
     */
    isOnlineEvent: boolean;
    /**
     * @deprecated
     *
     * Use `venue`
     */
    locationInfo: string;
    /**
     * Name of the event
     */
    name: string;
    /**
     * If the current is saved by the current
     * user
     */
    savedByYou: boolean;
    /**
     * YYYY-MM-DD string
     */
    startDate?: string;
    /**
     * HH:MM:SS string
     */
    startTime: string;
    /**
     * The timezone of the event
     */
    timezone: string;
    /**
     * URL to the event listing
     */
    url: string;

    /**
     * The sales status of the event.
     */
    eventSalesStatus?: EventSalesStatus;
    /**
     * sold out status of the event
     */
    isSoldOut?: boolean;
    /**
     * If avilable, can be used to set a "loading state"
     * color for the image as it loads
     */
    edgeColor?: string;
    /**
     * Preformatted price string to display
     * that represents the event's cost.
     */
    formattedPriceString?: string;
    /**
     * The raw event minimum price data. The eventcard understands
     * how to render into a localized price string
     */
    minPrice?: EventMinPrice;
    /**
     * The raw event maximum price data. The eventcard understands
     * how to render into a localized price string
     */
    maxPrice?: EventMaxPrice;
    /**
     * Number of additional events in this series
     * ie. Mar 8 2020 + 23 more events
     */
    repeatingInstanceCount?: number;
    /**
     * @deprecated
     * URL to series parent
     */
    seriesUrl?: string;
    /**
     * In some cases, necessary for the formatted event
     * to assign informatin to the subtitle spot directly
     */
    subtitle?: string;
    /**
     * The ticket provider
     */
    ticketsBy?: string;
    /**
     * External ticket url
     */
    ticketsUrl?: string;
    /**
     * Organizer of the current event
     */
    organizerId?: string;
    /**
     * Organizer Name
     */
    organizerName?: string;
    /**
     * Organizer Follower Count
     */
    organizerFollowerCount?: number;
    /**
     * Collection membership
     */
    publicCollections?: PublicCollectionsCamel;
    /**
     * Tags representing the category, sub category, and format of the event
     */
    tags?: string[];
    /**
     * Venue representation of the
     */
    venue?: CamelVenue;
    /**
     * Data for the urgency signals
     */
    urgencySignals?: EventUrgencySignalsData;
    /**
     * Data for open discounts
     */
    openDiscount?: EventOpenDiscountData;
    /**
     * Number of duplicated events
     */
    dedup?: DedupData;
    /**
     * Debug information around elastic search
     * ranking information for provided results.
     *
     * Requires `debug: true` to be sent in the request
     */
    debugInfo?: CamelDebugInfo;
    /**
     * Data for the widgets of the event.
     * This is alternative media of a specific event, such as additional images or videos
     */
    widgets?: EventWidget[];
}

type DedupData = { count: number; hash?: string };

type EsDebugInfo = { children?: EsDebugInfo[]; value: number; label: string };
type DebugInfo = { es_debug_info?: EsDebugInfo };
type CamelDebugInfo = SnakeCaseKeysToCamelCase<DebugInfo>;

type CamelVenueTopLevelKeys = Omit<SnakeCaseKeysToCamelCase<Venue>, 'address'>;
export type CamelVenue = CamelVenueTopLevelKeys & {
    address: SnakeCaseKeysToCamelCase<VenueAddress>;
};

interface Venue {
    _type: string;
    name: string;
    venue_profile_id: Nullable<string>;
    address: VenueAddress;
    venue_profile_url: string;
    id: string;
}
interface VenueAddress {
    city: string;
    country: string;
    region: string;
    longitude: string;
    localized_address_display: string;
    postal_code: string;
    address_1: string;
    address_2: string;
    latitude: string;
    localized_multi_line_address_display: string[];
    localized_area_display: string;
}

interface EventSalesStatusSnakeCase {
    sales_status: string;
    message: string;
    message_code: string;
    message_type: string;
    default_message?: string;
}

type NulledKeys<Input> = {
    [K in keyof Input]: Nullable<Input[K]>;
};

export type EventSalesStatus =
    SnakeCaseKeysToCamelCase<EventSalesStatusSnakeCase>;

export interface DestinationOrganizerProfile {
    facebook: string | null;
    followed_by_you: boolean;
    id: string;
    image_id: string | null;
    image?: EventImageApiData;
    name: string | null;
    num_collections: null;
    num_followers: number | null;
    num_following: number | null;
    num_saves: number | null;
    num_upcoming_events: number | null;
    profile_type: string;
    summary: string | null;
    twitter: string | null;
    url: string;
    website_url: string | null;
    _type: string;
}

/**
 * Response shape of a fully expanded
 * event container from Destination or Search service
 */
export interface DestinationEvent {
    debug_info?: DebugInfo;
    image?: EventImageApiData;
    saves?: {
        saved_by_you: boolean;
    };
    event_sales_status?: NulledKeys<EventSalesStatusSnakeCase>;
    timezone: string;
    id: string;
    tickets_url: string;
    tickets_by: string;
    primary_organizer_id: string;
    primary_organizer?: DestinationOrganizerProfile;
    promoted_listing_metadata?: {
        ad_id: string;
        index_in_search_results: number;
        is_promoted_event: boolean;
        placement_id: string;
    };
    hide_end_date: boolean;
    start_date: string;
    end_time: string;
    _type: string;
    ticket_availability?: {
        is_sold_out: boolean;
        is_free: boolean;
        end_sales_date?: EventTime;
        minimum_ticket_price: {
            currency: string;
            major_value: string;
            value: number;
            display: string;
        };
        has_available_tickets: boolean;
        maximum_ticket_price: {
            currency: string;
            major_value: string;
            value: number;
            display: string;
        };
        open_discount?: EventOpenDiscountApiData;
    };
    end_date: string;
    tags?: {
        prefix: string;
        tag: string;
        display_name: string;
        localized?: {
            display_name: string;
        };
    }[];
    eventbrite_event_id: string;
    start_time: string;
    primary_venue?: {
        _type: string;
        name: string;
        address: {
            city: string;
            country: string;
            region: string;
            longitude: string;
            localized_address_display: string;
            postal_code: string;
            address_1: string;
            latitude: string;
            localized_multi_line_address_display: string[];
            localized_area_display: string;
        };
        id: string;
    };
    series_id: string | null;
    series?: {
        url: string;
        counts: { current_future: number };
        next_dates: { start: string }[];
    };
    image_id: string | null;
    is_protected_event: boolean;
    is_cancelled: boolean | null;
    primary_venue_id: string | null;
    checkout_flow: string;
    name: string;
    language: string;
    url: string;
    hide_start_date: boolean;
    summary: string;
    is_online_event: boolean;
    eid: string;
    published: string;
    num_children?: number;
    parent_url?: string;
    urgency_signals?: EventUrgencySignalsApiData | null;
    public_collections?: {
        creator_collections: CreatorCollections;
    };
    dedup?: DedupData;
    widgets?: EventWidgetApiData[];
}

/**
 * Shape returned from calls that leverage
 * EventFormatMixin on the Python side for
 * rendering legacy event cards.
 */
export interface DiscoveryEvent {
    collectionsFetched?: boolean;
    show_remaining: boolean;
    invite_only: boolean;
    subcategory_id: number;
    rank: number;
    currency: string;
    date_header: string;
    logo: {
        url: string;
        edge_color: string;
    };
    locale: string;
    id: string;
    category: {
        subcategories: [];
        short_name_localized: string;
        name: string;
        short_name: string;
        name_localized: string;
        id: number;
    };
    bookmarked: boolean;
    venue_id: string;
    user_id: string;
    source: string;
    show_seatmap_thumbnail: boolean;
    inventory_type: string;
    show_colors_in_seatmap_thumbnail: boolean;
    logo_id: string;
    start: EventTime;
    version: string;
    listed: boolean;
    is_series: boolean;
    organizer: {
        website: string;
        organization_id: string;
        _type: string;
        user_id: string;
        short_name: string;
        url: string;
        twitter: string;
        id: string;
        logo_id: string;
        facebook: string;
        disable_marketing_opt_in: boolean;
        vanity_url: string;
        name: string;
    };
    hide_end_date: boolean;
    hide_start_date: boolean;
    status: string;
    _type: string;
    capacity_is_custom: boolean;
    description: {
        text: string;
        html: string;
    };
    format: {
        short_name_localized: string;
        name: string;
        short_name: string;
        name_localized: string;
        schema_url: string;
        id: string;
    };
    show_pick_a_seat: boolean;
    is_free: boolean;
    organization_id: string;
    is_externally_ticketed: boolean;
    is_protected_event: boolean;
    is_series_parent: boolean;
    format_id: string;
    tld: string;
    end: EventTime;
    price_range: string;
    name: {
        text: string;
    };
    language: string;
    url: string;
    venue: {
        age_restriction: string;
        user_id: string;
        name: string;
        longitude: string;
        address: {
            city: string;
            country: string;
            region: string;
            longitude: string;
            localized_address_display: string;
            postal_code: string;
            address_1: string;
            latitude: string;
            localized_multi_line_address_display: string[];
            localized_area_display: string;
        };
        latitude: string;
        capacity: 500;
        organizer_id: string;
        google_place_id: string;
        id: string;
    };
    summary: string;
    is_locked: boolean;
    shareable: boolean;
    style_id: string;
    online_event: boolean;
    organizer_id: string;
    category_id: string;
    survey_type: string;
    published: string;
    collections: string[];
    /**
     * Metadata for tracking promoted event placements
     */
    promotedListingMetadata?: {
        adId: string;
        indexInSearchResults: number;
        isPromotedEvent: boolean;
        placementId: string;
    };
}

export type EventOpenDiscountData =
    SnakeCaseKeysToCamelCase<EventOpenDiscountApiData> & {
        currency: string;
    };

export type EventOpenDiscountApiData = {
    amount_off?: string;
    percent_off?: string;
    code: string;
    discount_type: string;
    event_id: string;
    quantity_available: number;
    quantity_sold: number;
    type: string;
    end_date: string;
};

export type EventUrgencySignalsData = Record<UrgencySignalsKeys, boolean>;
export enum UrgencySignalsKeys {
    fewTickets = 'fewTickets',
    salesEndSoon = 'salesEndSoon',
    earlyBird = 'earlyBird',
    popular = 'popular',
    new = 'new',
    goingFast = 'goingFast',
}

export type EventUrgencySignalsApiData = {
    categories: UrgencySignalsKeys[];
    messages: UrgencySignalsKeys[];
};

export type EventWidget = EventHeroCarouselData | EventHeroVideoData;

export type EventWidgetApiData =
    | EventHeroCarouselApiData
    | EventHeroVideoApiData;

export type EventHeroCarouselApiData = {
    id: string;
    type: 'herocarousel';
    data: {
        slides: Array<{ image: EventImageApiData }>;
        resource_url: EventWidgetResource;
    };
};

export type EventHeroCarouselData =
    SnakeCaseKeysToCamelCase<EventHeroCarouselApiData>;

export type EventHeroVideoData =
    SnakeCaseKeysToCamelCase<EventHeroVideoApiData>;

export type EventHeroVideoApiData = {
    id: string;
    type: 'featured_video';
    data: unknown;
};

export type EventWidgetResource = {
    relative: string;
    absolute: string;
};

export type EventImageApiData = {
    edge_color_set: boolean;
    edge_color: string | null;
    url: string;
    original: {
        url: string;
        width: number;
        height: number;
    };
    crop_mask: {
        width: number;
        height: number;
        top_left: {
            y: number;
            x: number;
        };
    };
    aspect_ratio: string;
    id: string;
};

export type EventTime = {
    utc: string;
    date_header?: string;
    timezone: string;
    local: string;
    formatted_time?: string;
};
