import { CoreApplicationContext } from '@eventbrite/context-gen';
import { getNarrowMenuInfo } from '@eventbrite/eds-global-header';
import { Icon } from '@eventbrite/eds-icon';
import { PlusChunky } from '@eventbrite/eds-iconography';
import {
    getCreateEventCTA,
    getHelpMenu,
    getNonUserQuickLinks,
    getOrganizeMenu,
    getUserMenuInfo,
    logCreateEventCTAClick,
} from '@eventbrite/site-header';
import React from 'react';
import { FeatureFlags, UserShape } from '../types/index';
import {
    GlobalHeaderCallToAction,
    GlobalHeaderMenu,
    GlobalHeaderQuickLinks,
} from './GlobalHeaderNavigationBar';
import {
    getBugQuickLink,
    getLikesQuickLinks,
    getMyTicketsQuickLinks,
} from './utils';

interface HeaderLinksProps {
    user: UserShape;
    env: CoreApplicationContext['env'] & {
        launchNewSignInSignUpLinks?: string;
        isManageOverviewActive?: boolean;
    };
    featureFlags: FeatureFlags;
    requestPath?: string;
    action_from: string;
    onAdditionalMenuTextItemSelect?: Function;
}

interface MobileLinksProps extends HeaderLinksProps {
    narrowMobileMenuInfo?: React.ReactElement[];
}

interface DesktopMenuLinksProps extends HeaderLinksProps {
    desktopMenuComponents?: React.ReactElement[];
}

const _getDefaultMobileNarrowMenuInfo = (params: HeaderLinksProps) => {
    const { user, env, requestPath, featureFlags } = params;
    const { isAuthenticated, canCreateEvents } = user;
    const {
        adminServerUrl,
        isManageOverviewActive,
        logoutUrl,
        serverUrl,
        isMobile,
        organizeUrls,
        helpUrls,
        ebDomain,
    } = env;

    const additionalMenus = [
        getOrganizeMenu(organizeUrls, requestPath),
        getHelpMenu(helpUrls, requestPath),
    ];

    const userMenuInfo = getUserMenuInfo({
        adminServerUrl,
        featureFlags,
        isManageOverviewActive,
        logoutUrl,
        requestPath,
        serverUrl,
        showBrowseEvents: true,
        user,
        isMobile,
    });

    const callToActionInfo = getCreateEventCTA(
        serverUrl,
        ebDomain,
        requestPath,
        isAuthenticated,
        null,
        { canCreateEvents },
        () => logCreateEventCTAClick(false),
    );

    const narrowMenuInfo = getNarrowMenuInfo(
        userMenuInfo,
        additionalMenus,
        [],
        callToActionInfo,
        [],
        true,
    );

    return narrowMenuInfo;
};

const _getDefaultDesktopNarrowMenuInfo = (params: HeaderLinksProps) => {
    const { user, env, requestPath, featureFlags, action_from } = params;
    const { isAuthenticated, canCreateEvents, publicId } = user;

    const { ebDomain, serverUrl } = env;

    const createEventCTA = getCreateEventCTA(
        serverUrl,
        ebDomain,
        requestPath,
        isAuthenticated,
        null,
        { canCreateEvents },
        () => logCreateEventCTAClick(false),
    );

    const likesLinks = getLikesQuickLinks(
        serverUrl,
        ebDomain,
        requestPath || '',
        action_from,
        publicId,
    );

    const myTicketsLinks = getMyTicketsQuickLinks(
        serverUrl,
        ebDomain,
        requestPath || '',
        action_from,
        publicId,
    );

    const ctaIcon = <Icon size="small" type={<PlusChunky />} />;

    let linkComponents: any[] = [];

    // on the search page, show britelings a link to report a bug
    if (featureFlags?.showReportBugLinkInHeader) {
        linkComponents = [
            <GlobalHeaderQuickLinks
                key="bugLinks"
                links={getBugQuickLink()}
                shouldShowIcon={true}
            />,
        ];
    }

    linkComponents = [
        ...linkComponents,
        <React.Fragment key="createEventClassicFlow">
            <GlobalHeaderCallToAction
                key="createEventLink"
                linkInfo={createEventCTA}
                iconType={ctaIcon}
                shouldShowIcon={true}
                iconColor="grey-800"
            />
            <GlobalHeaderQuickLinks
                key="likesLinks"
                links={likesLinks}
                shouldShowIcon={true}
            />
            <GlobalHeaderQuickLinks
                key="myTicketsLinks"
                links={myTicketsLinks}
                shouldShowIcon={true}
            />
        </React.Fragment>,
    ];

    return linkComponents;
};

export const MobileHeaderLinks: React.FC<MobileLinksProps> = ({
    user,
    env,
    featureFlags,
    requestPath,
    action_from,
    narrowMobileMenuInfo,
}) => {
    const { isAuthenticated } = user;
    const { signinUrl, signupUrl, loginUrl, launchNewSignInSignUpLinks } = env;

    const params = {
        env: env,
        user: user,
        featureFlags: featureFlags,
        requestPath: requestPath,
        action_from: action_from,
    };

    const signinPath = launchNewSignInSignUpLinks ? signinUrl : loginUrl;
    const nonUserQuickLinks = getNonUserQuickLinks(
        requestPath,
        isAuthenticated,
        signinPath,
        signupUrl,
        { launchNewSignInSignUpLinks },
    );

    const narrowMenuInfo = narrowMobileMenuInfo
        ? narrowMobileMenuInfo
        : _getDefaultMobileNarrowMenuInfo(params);

    const narrowMenu = [narrowMenuInfo].map((menuInfo, index) => (
        <span
            key={menuInfo.path + index}
            className="eds-global-header__narrow-menu"
        >
            <GlobalHeaderMenu
                menuInfo={menuInfo}
                data-spec="global-header-narrow-menu"
                noLabel={true}
            />
        </span>
    ));

    return (
        <div className="authenticated-header__mobile-links eds-align--space-between">
            <div className="authenticated-header__links">
                {narrowMenu}
                <GlobalHeaderQuickLinks
                    links={nonUserQuickLinks}
                    shouldShowIcon={false}
                />
            </div>
        </div>
    );
};

export const DesktopHeaderLinks: React.FC<DesktopMenuLinksProps> = ({
    user,
    env,
    featureFlags,
    requestPath,
    action_from,
    desktopMenuComponents,
}) => {
    const params = {
        env: env,
        user: user,
        featureFlags: featureFlags,
        requestPath: requestPath,
        action_from: action_from,
    };

    const linkComponents = desktopMenuComponents
        ? desktopMenuComponents
        : _getDefaultDesktopNarrowMenuInfo(params);

    return (
        <div
            className="authenticated-header__links"
            data-testid="authenticated-header-links-test"
        >
            {linkComponents}
        </div>
    );
};
