import classnames from 'classnames';
import React, { Children, PureComponent } from 'react';
import IconBottomNavigation from './IconBottomNavigation';
import NumberBottomNavigation from './NumberBottomNavigation';
import {
    AUTO_TRANSITION_INTERVAL,
    BASE_CAROUSEL_PROPTYPES,
    TRANSITION_TRIGGER_THRESHOLD,
    ANIMATION_DELAY,
    ANIMATION_MAP,
    MOBILE,
    TYPE_ICON,
} from '../constants';
import {
    getSlideItems,
    getTransition,
    getPreviousIndex,
    getNextIndex,
    getTouchDistance,
} from './utils';

export default class BaseCarousel extends PureComponent {
    static propTypes = BASE_CAROUSEL_PROPTYPES;

    static defaultProps = {
        transitionDuration: ANIMATION_DELAY,
    };

    constructor(props) {
        super(props);

        const { inheritedDisplayIndex, initialDisplayIndex } = props;

        this.state = {
            touchPosition: 0,
            distance: 0,
            displayIndex: inheritedDisplayIndex || initialDisplayIndex || 0,
        };
    }

    componentDidMount() {
        const { autoTransition } = this.props;

        this._startAutoTransition(autoTransition);
    }

    UNSAFE_componentWillReceiveProps({
        inheritedDisplayIndex = 0,
        autoTransition,
    }) {
        const { inheritedDisplayIndex: prevInheritedIndex } = this.props;

        if (inheritedDisplayIndex !== prevInheritedIndex) {
            this._queueNextItem(inheritedDisplayIndex);
        }

        if (autoTransition) {
            this._startAutoTransition(autoTransition);
        } else {
            this._stopAutoTransition();
        }
    }

    componentDidUpdate() {
        const { displayIndex, queuedDisplayIndex, transition } = this.state;

        if (
            displayIndex !== queuedDisplayIndex &&
            transition &&
            transition !== ANIMATION_MAP[MOBILE]
        ) {
            this._loadNextItem();
        }
    }

    componentWillUnmount() {
        this._stopAutoTransition();
    }

    _queueNextItem = (displayIndex) => {
        const {
            children: { length },
        } = this.props;

        this.setState(
            ({
                displayIndex: prevDisplayIndex,
                transition: prevTransition,
            }) => ({
                queuedDisplayIndex: displayIndex,
                transition: getTransition({
                    prevTransition,
                    prevDisplayIndex,
                    displayIndex,
                    totalItems: length,
                }),
            }),
        );
    };

    _loadNextItem = () => {
        const { transitionDuration } = this.props;

        setTimeout(() => {
            this.setState(
                ({
                    transition: prevTransition,
                    queuedDisplayIndex: displayIndex,
                }) => {
                    const { onTransition } = this.props;
                    const transition = ANIMATION_MAP[prevTransition];

                    if (onTransition) {
                        onTransition(displayIndex);
                    }

                    return {
                        displayIndex,
                        transition,
                    };
                },
            );
        }, transitionDuration);
    };

    _startAutoTransition = (shouldAutoTransition) => {
        if (shouldAutoTransition && !this._autoTransition) {
            this._autoTransition = setInterval(
                this._goToNextIndex,
                AUTO_TRANSITION_INTERVAL,
            );
        }
    };

    _stopAutoTransition = () => {
        if (this._autoTransition) {
            clearInterval(this._autoTransition);
            delete this._autoTransition;
        }
    };

    _goToNextIndex = () => {
        const {
            children: { length },
            isNotContinuous,
            onNext,
        } = this.props;

        if (onNext) {
            onNext();
        } else {
            this.setState(getNextIndex.bind(null, length, !isNotContinuous));
        }
    };

    _goToPreviousIndex = () => {
        const {
            children: { length },
            isNotContinuous,
            onPrevious,
        } = this.props;

        if (onPrevious) {
            onPrevious();
        } else {
            this.setState(
                getPreviousIndex.bind(null, length, !isNotContinuous),
            );
        }
    };

    _handleNavigate = (displayIndex) => {
        this._queueNextItem(displayIndex);
    };

    _handleTouchStart = ({ changedTouches }) => {
        this.setState({
            touchPosition: changedTouches[0].clientX,
        });
    };

    _handleTouchMove = ({ changedTouches }) => {
        const { touchPosition } = this.state;
        const { clientX, screenX } = changedTouches[0];

        this.setState({
            distance: getTouchDistance({ clientX, screenX, touchPosition }),
        });
    };

    _handleTouchEnd = ({ changedTouches }) => {
        const { touchPosition } = this.state;
        const { onSwipeRight, onSwipeLeft } = this.props;
        const { clientX } = changedTouches[0];
        const delta = clientX - touchPosition;

        this.setState({
            transition: ANIMATION_MAP[MOBILE],
            distance: 0,
        });

        // If it's within an acceptable range, then snap back to original slide
        if (Math.abs(delta) < TRANSITION_TRIGGER_THRESHOLD) {
            return;
        }

        if (delta > 0) {
            this._goToPreviousIndex();

            if (onSwipeLeft) {
                onSwipeLeft();
            }
        } else {
            this._goToNextIndex();

            if (onSwipeRight) {
                onSwipeRight();
            }
        }
    };

    _getLeftNavigation = () => {
        const { leftNavigationNode, bottomNavigationType } = this.props;

        if (bottomNavigationType === TYPE_ICON) {
            return leftNavigationNode;
        }
        return null;
    };

    _getRightNavigation = () => {
        const { rightNavigationNode, bottomNavigationType } = this.props;

        if (bottomNavigationType === TYPE_ICON) {
            return rightNavigationNode;
        }
        return null;
    };

    render() {
        const {
            children,
            itemsPerSlide,
            customNavigation,
            showNavigation,
            leftNavigationNode,
            rightNavigationNode,
            bottomNavigationType,
            fixAspectRatio,
            isNotContinuous,
            usesNewStyle,
            title,
        } = this.props;

        const { displayIndex, distance, transition } = this.state;
        const componentChildren = Children.toArray(children);
        const { onScreen, offScreen } = getSlideItems(
            componentChildren,
            displayIndex,
            itemsPerSlide,
            isNotContinuous,
        );
        const style = {
            transform: `translatex(${distance}px)`,
        };
        const slideClassName = classnames({
            'eds-carousel-slide--fixed-ratio': fixAspectRatio,
            'eds-align--center-horizontal': !fixAspectRatio,
            'eds-base-carousel__slide': usesNewStyle,
            [transition]: transition,
        });
        let navigation = null;
        let newStyleHeader = null;

        const bottomNavOptions = {
            icons: IconBottomNavigation,
            numbers: NumberBottomNavigation,
        };

        const baseContentClassName = classnames('eds-base-carousel__content', {
            'eds-base-carousel__content--fixed-ratio': fixAspectRatio,
        });

        const newStyleHeaderClassName = classnames({
            'eds-align--center-vertical eds-align--space-between': title,
            'eds-l-mar-bot-8 eds-align--right': !title,
            'eds-l-mar-bot-8': !title?.props.newTitleStyle,
            'eds-l-mar-bot-4': title?.props.newTitleStyle,
        });

        if (showNavigation) {
            const NavComponent = bottomNavOptions[bottomNavigationType];

            navigation = (
                <NavComponent
                    displayIndex={displayIndex}
                    onClick={this._handleNavigate}
                    totalItems={children.length}
                    itemsPerSlide={itemsPerSlide}
                    customNavigation={customNavigation}
                    leftNavigation={leftNavigationNode}
                    rightNavigation={rightNavigationNode}
                />
            );
        }

        if (usesNewStyle) {
            newStyleHeader = (
                <div className={newStyleHeaderClassName}>
                    {title}
                    <div className="eds-align--center-vertical">
                        {this._getLeftNavigation()}
                        {this._getRightNavigation()}
                    </div>
                </div>
            );
        }

        return (
            <div
                className="eds-base-carousel"
                onTouchStart={this._handleTouchStart}
                onTouchMove={this._handleTouchMove}
                onTouchEnd={this._handleTouchEnd}
            >
                <div className={baseContentClassName}>
                    {newStyleHeader}
                    <div className="eds-align--center-vertical eds-align--space-around">
                        {!usesNewStyle && this._getLeftNavigation()}
                        <div
                            aria-live="polite"
                            className={slideClassName}
                            style={style}
                            data-testid="on-screen-slides"
                        >
                            {onScreen}
                        </div>
                        {!usesNewStyle && this._getRightNavigation()}
                    </div>
                </div>
                <div className="eds-is-hidden-accessible" aria-live="off">
                    {offScreen}
                </div>
                <div className="eds-align--center">{navigation}</div>
            </div>
        );
    }
}
