import * as React from 'react';

const StatusDotChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="status-dot-chunky_svg__eds-icon--alert-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <circle cx={12} cy={12} r={3} />
    </svg>
);

StatusDotChunkySvg.displayName = 'StatusDotChunkySvg';
export default StatusDotChunkySvg;
