import last from 'lodash/last';
import range from 'lodash/range';
import {
    ANIMATION_MAP,
    DIRECTION_LEFT,
    DIRECTION_RIGHT,
    TOUCH_DISTANCE_DIVIDER,
} from '../constants';

// To allow for infinite scroll, reset to last index if there are no more previous items unless it should not cycle
export const getPreviousIndex = (
    totalItems,
    shouldCycle,
    { displayIndex: prevDisplayIndex },
) => {
    const cycledDisplayIndex = shouldCycle ? totalItems - 1 : 0;

    return {
        displayIndex:
            prevDisplayIndex === 0 ? cycledDisplayIndex : prevDisplayIndex - 1,
    };
};

// To allow for infinite scroll, reset to 0 index if there are no more next items unless it should not cycle
export const getNextIndex = (
    totalItems,
    shouldCycle,
    { displayIndex: prevDisplayIndex },
) => {
    const cycledDisplayIndex = shouldCycle ? 0 : prevDisplayIndex;

    return {
        displayIndex:
            prevDisplayIndex === totalItems - 1
                ? cycledDisplayIndex
                : prevDisplayIndex + 1,
    };
};

export const getTouchDistance = ({ clientX, screenX, touchPosition }) => {
    const distance = clientX - touchPosition;
    // Limit client from sliding the current item out of view
    const normalizedDistance = Math.min(
        Math.abs(clientX - touchPosition),
        screenX / TOUCH_DISTANCE_DIVIDER,
    );

    // Restore distance to a signed number so we can later determine the direction of the swipe
    return distance > 0 ? normalizedDistance : -normalizedDistance;
};

/**
 * Given a list of children, splits it into what should be in the
 * previous, current, or next categories based on current display index and items per slide
 */
export const getSlideItems = (
    children,
    displayIndex,
    itemsPerSlide,
    shouldCycle = true,
) => {
    const indexes = range(itemsPerSlide).reduce((memo, next, index) => {
        let indexToShow = displayIndex + index;

        if (!children[indexToShow]) {
            const lastIndex = last(memo);

            indexToShow = getNextIndex(children.length, shouldCycle, {
                displayIndex: lastIndex,
            }).displayIndex;
        }

        return [...memo, indexToShow];
    }, []);

    return {
        onScreen: indexes.map((index) => children[index]),
        offScreen: children.filter((child, i) => indexes.indexOf(i) === -1),
    };
};

export const getTransition = ({
    prevTransition,
    prevDisplayIndex,
    displayIndex,
    totalItems,
}) => {
    let transition = prevTransition;

    // The next index is greater than previous, so move previous items to the left
    if (prevDisplayIndex < displayIndex) {
        transition = ANIMATION_MAP[DIRECTION_LEFT];
    }

    // The next index is less than previous, so move previous items to the right
    if (prevDisplayIndex > displayIndex) {
        transition = ANIMATION_MAP[DIRECTION_RIGHT];
    }

    // Override direction in case we're moving from last index to zero (infinite scroll)
    if (prevDisplayIndex === totalItems - 1 && displayIndex === 0) {
        transition = ANIMATION_MAP[DIRECTION_LEFT];
    }

    // Override direction in case we're moving from zero to last index (infinite scroll)
    if (prevDisplayIndex === 0 && displayIndex === totalItems - 1) {
        // The next index is less than previous, or moving from zero to last index (infinite scroll)
        // In this case, we want to show previous item, and move previous items to the right
        transition = ANIMATION_MAP[DIRECTION_RIGHT];
    }

    return transition;
};
