import { Avatar } from '@eventbrite/eds-avatar';
import { gettext } from '@eventbrite/i18n';
import React, { useContext } from 'react';
import { ApplicationContext } from '../context';
import { AnalyticsAction, AnalyticsCategory, trackEvent } from '../_shared';
import { defaultAutocreatedCollectionSummary } from '../_shared/constants';
import {
    isAutocreatedCollectionPreviewWithTips,
    isAutocreatedCollectionUnedited,
} from '../_shared/utils';

export type CollectionDetailsProps = {
    collectionName: string;
    collectionSummary?: string;
    organizerName?: string;
    organizerUrl?: string;
    organizerLogoUrl?: string;
    organizerFollowersCount?: number;
};

export const CollectionDetails: React.FunctionComponent<
    CollectionDetailsProps
> = ({
    collectionName,
    collectionSummary,
    organizerName,
    organizerUrl,
    organizerLogoUrl,
    organizerFollowersCount = 0,
}) => {
    const minFollowersNumber = 10;
    const appContext = useContext(ApplicationContext);
    const summary =
        isAutocreatedCollectionUnedited(appContext?.collection) &&
        isAutocreatedCollectionPreviewWithTips(appContext)
            ? defaultAutocreatedCollectionSummary
            : collectionSummary;

    return (
        <>
            <div className="cc-signifier eds-text-bl">
                {gettext('Collection')}
            </div>

            <h1 className="cc-title eds-text-hl">{collectionName}</h1>

            <div className="cc-organizer-snippet eds-l-mar-bot-8">
                {organizerLogoUrl && (
                    <div className="cc-organizer-snippet__avatar">
                        <Avatar imageUrl={organizerLogoUrl} />
                    </div>
                )}
                {organizerName && organizerUrl && (
                    <div className="cc-organizer-snippet__info">
                        <div className="eds-text-bm eds-text-weight--bold">
                            <a
                                href={organizerUrl}
                                target="_blank"
                                rel="noreferrer"
                                onClick={() => {
                                    trackEvent({
                                        action: AnalyticsAction.OrganizerName,
                                        category: AnalyticsCategory.Listing,
                                        label: 'organizer-details',
                                    });
                                }}
                            >
                                {organizerName}
                            </a>
                        </div>
                        {organizerFollowersCount >= minFollowersNumber && (
                            <div className="eds-text-sm eds-text-color--ui-600">
                                {organizerFollowersCount} {gettext('followers')}
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className="cc-main-description eds-l-mar-bot-8">
                <p className="eds-text-bl">{summary}</p>
            </div>
        </>
    );
};
