import { ContactOrganizerButton } from '@eventbrite/contact-organizer-button';
import { Avatar } from '@eventbrite/eds-avatar';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { AnalyticsAction, AnalyticsCategory, trackEvent } from '../../_shared';
import {
    FollowButtonProps,
    FollowOrganizerButton,
} from '../FollowOrganizerButton/FollowOrganizerButton';

export interface OrganizerPanelProps extends FollowButtonProps {
    organizerName?: string;
    organizerLogoUrl?: string;
    organizerUrl?: string;
    organizerDescription?: string;
    collectionName: string;
    collectionId: string;
    isGDPRCountry: boolean;
    userId?: string;
}

export const OrganizerPanel: React.FunctionComponent<OrganizerPanelProps> = ({
    organizerName,
    organizerLogoUrl,
    organizerUrl,
    organizerDescription,
    collectionName,
    collectionId,
    isAuthenticated,
    organizers,
    organizerId,
    gaSettings,
    gaCategory,
    isFollowing,
}) => {
    const contactOrganizerProps = {
        organizerId: organizerId,
        organizerName: organizerName,
        captchaKey: '6LcSJB0TAAAAAMOx2-6t20g-uWY2DXextp7Enqvn',
        isVisible: false,
        eventName: collectionName,
        isAuthenticated: isAuthenticated,
        collectionId: collectionId,
        gaCategory: gaCategory,
    };

    return (
        <section className="cc-organizer-panel " aria-label="Organizer profile">
            {organizerLogoUrl ? (
                <div className="cc-organizer-panel__avatar">
                    <Avatar imageUrl={organizerLogoUrl} />
                </div>
            ) : null}
            {organizerName ? (
                <div className="eds-l-mar-bot-2">
                    <h2 className="eds-text-bm">
                        <a
                            className="eds-btn eds-btn--button eds-btn--link"
                            href={organizerUrl}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                                trackEvent({
                                    action: AnalyticsAction.OrganizerName,
                                    category: AnalyticsCategory.Listing,
                                    label: 'organizer-panel',
                                });
                            }}
                        >
                            {organizerName}
                        </a>
                    </h2>
                </div>
            ) : null}

            <div className="eds-text-bm eds-l-mar-bot-4">
                {gettext('Organizer of %(collectionName)s', { collectionName })}
            </div>

            {organizerDescription && (
                <div className="cc-organizer-panel__description eds-text-bm eds-text-color--grey-600">
                    {organizerDescription}
                </div>
            )}

            {organizerId && (
                <div className="cc-organizer-panel__actions">
                    <ul className="cc-list-actions">
                        {organizerName ? (
                            <li className="cc-list-actions__item eds-l-mar-right-4">
                                <FollowOrganizerButton
                                    isAuthenticated={isAuthenticated}
                                    gaSettings={gaSettings}
                                    gaCategory={gaCategory}
                                    organizers={organizers}
                                    organizerId={organizerId}
                                    isFollowing={isFollowing}
                                />
                            </li>
                        ) : null}
                        <li
                            className="cc-list-actions__item"
                            aria-label={gettext('Contact').toString()}
                        >
                            <ContactOrganizerButton
                                {...contactOrganizerProps}
                            />
                        </li>
                    </ul>
                </div>
            )}
        </section>
    );
};
