import styled from '@emotion/styled';

export const PillWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    background: #1e0a3c;
    height: 315px;
    width: 100%;
    padding: 24px;
    color: white;
    box-shadow: 0px 1px 17px rgba(40, 44, 53, 0.1),
        0px 2px 5px rgba(40, 44, 53, 0.1);
    @media all and (min-width: 768px) {
        z-index: 4;
        position: fixed;
        top: 230px;
        right: 5%;
        width: 263px;
        border-radius: 8px;
    }

    @media all and (min-width: 1800px) {
        right: 12%;
    }
`;

export const BackgroundLayer = styled.div`
    position: absolute;
    top: -5px;
    left: -5px;
`;

export const Title = styled.h2`
    margin-bottom: 0.75rem;
    font-size: 1.5rem;
    font-weight: 900;
`;

export const StepsList = styled.ol`
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    list-style: none;
`;

export const Step = styled.li`
    margin: 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 0.875rem;
`;

export const FinishEditing = styled.div`
    margin-top: 1rem;
    border-top: 2px solid rgba(169, 168, 179, 0.5);
    width: 100%;
    padding-top: 0.875rem;
    font-size: 0.875rem;
    cursor: pointer;

    &:hover {
        text-decoration: underline;

        & > div {
            transition: 0.1s linear;
            -webkit-transition: 0.1s linear;
            transform: translateX(4px);
        }
    }
`;

export const StepCounter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    background: white;
    height: 21px;
    width: 21px;
    min-height: 21px;
    min-width: 21px;
    border-radius: 50%;
    color: #1e0a3c;
    font-weight: bold;
`;

export const ArrowIcon = styled.div`
    display: inline-block;
    margin-left: 4px;
`;
