import React, { createContext, PureComponent } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { addWindowListener, removeWindowListener } from '@eventbrite/eds-utils';
import { HAS_WINDOW } from '@eventbrite/feature-detection';
import {
    setupNotificationAutoDelay,
    clearNotificationTimeouts,
} from './helper';
import { ADD_MAIN_CONTROLS_NAMESPACE } from './constants';
import { MainControlsProvider } from '../context/MainControls';

const NOTIFICATION_STICKY_HEIGHT = 60;
const SCROLL_EVENT_INTERVAL = 50;

// addMainControls is a HOC used to wrap a page component
// it adds several function props into the scope of the page component which is
// used in tandem with `withMainControls` to pass main content related options
// from sub components

const addMainControls = (PageComponent) =>
    class AddMainControlsWrapper extends PureComponent {
        static childContextTypes = {
            showMainBottomBar: PropTypes.func,
            closeMainBottomBar: PropTypes.func,
            addMainNotification: PropTypes.func,
            hideMainNotification: PropTypes.func,
        };

        state = {
            bottomBarOptions: undefined,
            notificationOptions: undefined,
            childContext: {
                ...this.getChildContext(),
            },
        };

        getChildContext() {
            return {
                showMainBottomBar: this._showMainBottomBar.bind(this),
                closeMainBottomBar: this._closeMainBottomBar.bind(this),
                addMainNotification: this._addMainNotification.bind(this),
                hideMainNotification: this._hideMainNotification.bind(this),
            };
        }

        componentWillUnmount() {
            this._unSubscribeWindowListener();
            // Make sure Notification timers are cleared to prevent memory leaks
            clearNotificationTimeouts(ADD_MAIN_CONTROLS_NAMESPACE);
        }

        _showMainBottomBar(options = {}) {
            // managing the state of the main bottom bar w/in this HOC so that
            // components further down the tree don't have to
            this.setState({
                bottomBarOptions: {
                    ...options,
                    isShown: true,
                },
            });
        }

        _closeMainBottomBar() {
            // NOTE: We could also set `bottomBarOptions` to undefined, but since the
            // bottom bar is hidden, we don't *have* to clear out the content. that
            // would result in the markup also being cleared out. If the next time
            // the bottom bar is shown and the values end up being the same,
            // we could save some unnecessary DOM rewrites by *only* changing the
            // shown property

            // NOTE: using the callback form for `setState` since the new state
            // I want to set depends on the previous state. Because `setState`
            // is async this ensures that we're using the right version
            this.setState(
                ({ bottomBarOptions: prevBottomBarOptions = {} }) => ({
                    bottomBarOptions: {
                        ...prevBottomBarOptions,
                        isShown: false,
                    },
                }),
            );
        }

        _handleScrollNotification(stickyTopHeight) {
            const { notificationOptions } = this.state;
            const children = notificationOptions?.children;
            let shouldStick = notificationOptions?.shouldStick;
            let shouldSetState = false;

            if (children) {
                /* We only want to call `setState` once each time the notification bar should transition
                from sticky to non-sticky, so only call `setState` when the value of 'shouldStick' should be different
                than what it currently is. */
                if (
                    (window.pageYOffset >= stickyTopHeight && !shouldStick) ||
                    (window.pageYOffset < stickyTopHeight && shouldStick)
                ) {
                    shouldStick = !shouldStick;
                    shouldSetState = true;
                }

                /* Additionally, we do not want to update our state when we don't have any children
                as they are a required prop for the notification bar component. */
                if (shouldSetState) {
                    this.setState({
                        notificationOptions: {
                            ...notificationOptions,
                            shouldStick,
                        },
                    });
                }
            }
        }

        _addMainNotification(options = {}) {
            // Need to clear any lingering timeouts
            clearNotificationTimeouts(ADD_MAIN_CONTROLS_NAMESPACE);

            // managing the state of the notificationBar w/in this HOC so that
            // components further down the tree don't have to
            const {
                isNotificationSticky,
                shouldPersist,
                stickyTopHeight = NOTIFICATION_STICKY_HEIGHT,
                ...notificationOptions
            } = options;
            let shouldStick = false;

            if (isNotificationSticky) {
                this._unSubscribeWindowListener();

                this._debouncedHandleScrollNotification = debounce(
                    this._handleScrollNotification.bind(this, stickyTopHeight),
                    SCROLL_EVENT_INTERVAL,
                );

                addWindowListener(
                    'scroll',
                    this._debouncedHandleScrollNotification,
                );
                //If the user is already scrolled a distance down the page,
                //notification should initially render as sticky
                if (HAS_WINDOW && window.pageYOffset >= stickyTopHeight) {
                    shouldStick = true;
                }
            }

            this.setState({
                notificationOptions: {
                    shouldStick,
                    isClosing: false,
                    hasCloseButton: true,
                    ...notificationOptions,

                    // Need to always pass in `onClose` so that if the notificationBar
                    // button is pressed this HOC can set its internal state.
                    // If an `onClose` was passed in, it'll get called.
                    onClose: this._hideMainNotification.bind(
                        this,
                        notificationOptions.onClose,
                    ),
                },
            });

            // Self remove non-callAction notifications. Notifications
            // which have CTAs should not be auto removed
            if (!notificationOptions.callAction && !shouldPersist) {
                // Fire the notification removal sequence
                setupNotificationAutoDelay(
                    this._animateClose,
                    this._hideMainNotification.bind(
                        this,
                        notificationOptions.onClose,
                    ),
                    ADD_MAIN_CONTROLS_NAMESPACE,
                );
            }
        }

        _animateClose = (next) => {
            this.setState(
                (prevState) => ({
                    notificationOptions: {
                        ...prevState.notificationOptions,
                        // isClosing signals Structure to apply a css transition effect to the notification
                        isClosing: true,
                    },
                }),
                next,
            );
        };

        _hideMainNotification(onClose) {
            // Need to clear any lingering timeouts
            clearNotificationTimeouts(ADD_MAIN_CONTROLS_NAMESPACE);

            this._unSubscribeWindowListener();

            // NOTE: We set the notificationOptions to null so that nothing is displayed
            this.setState(() => ({
                notificationOptions: null,
            }));

            if (onClose) {
                onClose();
            }
        }

        _unSubscribeWindowListener() {
            if (this._debouncedHandleScrollNotification) {
                removeWindowListener(
                    'scroll',
                    this._debouncedHandleScrollNotification,
                );

                this._debouncedHandleScrollNotification = null;
            }
        }

        // Pass bottomBarOptions & notificationOptions back into the page component's scope as props
        render() {
            const { bottomBarOptions, notificationOptions } = this.state;

            return (
                <MainControlsProvider childContext={this.state.childContext}>
                    <PageComponent
                        mainBottomBarOptions={bottomBarOptions}
                        notificationOptions={notificationOptions}
                        {...this.props}
                    />
                </MainControlsProvider>
            );
        }
    };

export default addMainControls;
