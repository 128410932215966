import React, { useContext } from 'react';
import { ApplicationContext } from '../../context';
import '../../styles/main.scss';
import { AUTOCREATED_COLLECTION_DEFAULT_IMAGE_URL } from '../../_shared/constants';
import {
    isAutocreatedCollectionPreviewWithTips,
    isAutocreatedCollectionUnedited,
} from '../../_shared/utils';

export const CollectionHero: React.FC = ({ children }) => {
    const context = useContext(ApplicationContext);
    const heroImage =
        isAutocreatedCollectionUnedited(context?.collection) &&
        isAutocreatedCollectionPreviewWithTips(context)
            ? AUTOCREATED_COLLECTION_DEFAULT_IMAGE_URL
            : context?.collection?.image?.url;

    return (
        <div role="banner" className="cc-hero">
            {heroImage ? (
                <div aria-label="Hero Image" className="cc-hero__image-wrapper">
                    <picture data-testid="collection-hero-image">
                        <source srcSet={heroImage} sizes="100vw" />
                        <img alt={''} />
                    </picture>
                </div>
            ) : null}

            <div
                className="cc-hero__button-share"
                aria-label="Share collection"
            >
                {children}
            </div>
        </div>
    );
};
