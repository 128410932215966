import React from 'react';
import '../../global.scss';
import { GlobalNavProvider } from '../../state';
import { DesktopNav } from '../DesktopNav';
import { MobileNav } from '../MobileNav';
import { GlobalNavProps } from '../types';
import GlobalNavStyles from './GlobalNav.module.scss';

const GlobalNav: React.FC<GlobalNavProps> = ({
    positionSticky,
    searchBar,
    logo,
    navigation,
    user,
    env,
}) => {
    return (
        <GlobalNavProvider
            positionSticky={positionSticky}
            searchBar={searchBar}
            logo={logo}
            user={user}
            env={env}
            navigation={navigation}
        >
            <header className={GlobalNavStyles.main}>
                <DesktopNav />
                <MobileNav />
            </header>
        </GlobalNavProvider>
    );
};

export default GlobalNav;
