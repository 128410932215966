import { Icon } from '@eventbrite/eds-icon';
import {
    PlusChunky as PlusChunkySvg,
    UserChunky as UserChunkySvg,
} from '@eventbrite/eds-iconography';
import { ROUTER_LINK } from '@eventbrite/eds-nav-list-item';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Link } from 'react-router-dom';
import { MenuInfoShape } from '../types/index';
import {
    DropdownButton,
    DropdownComponentByType,
    GlobalHeaderMenuIcon,
} from './common';

// Anchor needs to be a React component in order to re-render properly
const AnchorLink = (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <a {...props}>{props.children}</a>
);

export const GlobalHeaderQuickLinks = ({
    links,
    shouldShowIcon,
}: {
    links: {
        url: string;
        content: React.ReactNode;
        iconType: React.ReactNode;
        narrowIconType?: React.ReactNode;
        narrowIconColor?: string;
        iconColor?: string;
        isActive?: boolean;
        type?: string;
        restProps?: any;
    }[];
    shouldShowIcon: boolean;
}) => {
    if (!links) {
        return null;
    }
    const linkComponents = links.map(
        ({
            url,
            content,
            narrowIconType,
            iconType,
            narrowIconColor,
            iconColor,
            isActive,
            type,
            ...restProps
        }) => {
            let narrowIcon;

            let Component: any = AnchorLink;
            const linkProps: any = restProps;
            const quickLinkWrapperClassName = classNames(
                'authenticated-header__quick-link',
                {
                    // If the link collapses into an icon on narrow screens,
                    // we want to make sure we're not hiding it
                    'authenticated-header__quick-link--narrow': narrowIconType,
                    // If link is active apply this class
                    'authenticated-header__quick-link--active': isActive,
                },
            );

            let icon = null;

            if (shouldShowIcon) {
                icon = <Icon size="small" type={iconType} color={iconColor} />;
            }

            if (narrowIconType) {
                narrowIcon = (
                    <GlobalHeaderMenuIcon
                        iconType={narrowIconType}
                        iconColor={narrowIconColor || ''}
                    />
                );
            }

            if (type === ROUTER_LINK && linkProps.to) {
                Component = Link;
                linkProps.to = url;
            } else {
                linkProps.href = url;
            }

            return (
                <Component
                    {...linkProps}
                    key={url}
                    className={quickLinkWrapperClassName}
                >
                    <span>{icon || narrowIcon}</span>
                    <span>{content}</span>
                </Component>
            );
        },
    );
    return (
        <div
            className="authenticated-header__quick-links eds-l-mar-left-2"
            data-testid="authenticated-header-quick-links"
        >
            {linkComponents}
        </div>
    );
};

export const GlobalHeaderCallToAction = ({
    linkInfo,
    iconType,
    shouldShowIcon,
}: {
    linkInfo: {
        url: string;
        content: JSX.Element;
        linkProps: any;
    };
    // TODO REMOVE TYPE iconColor
    iconColor?: string;
    iconType?: React.ReactNode;
    shouldShowIcon: boolean;
}) => {
    let component = null;

    if (linkInfo) {
        const { url, content, ...linkProps } = linkInfo;
        let icon = null;

        if (shouldShowIcon) {
            icon = iconType ? (
                iconType
            ) : (
                <Icon size="small" type={<PlusChunkySvg />} />
            );
        }

        component = (
            <a
                {...linkProps}
                href={url}
                data-testid="authenticated-header-create-event-link"
                data-spec="authenticated-header__quick-link"
                className="authenticated-header__quick-link authenticated-header__quick-link--cta authenticated-header__menu-label create-event-link"
            >
                {icon}
                {content}
            </a>
        );
    }

    return component;
};

export const GlobalHeaderMenu = ({
    menuInfo,
    onAdditionalMenuTextItemSelect,
    noLabel = false,
    ...additionalProps
}: {
    menuInfo: MenuInfoShape;
    noLabel?: boolean;
    narrowIconType?: React.ReactNode;
    narrowIconColor?: string;
    onAdditionalMenuTextItemSelect?: Function;
    additionalProps?: any;
}) => {
    let component = null;

    if (menuInfo) {
        const {
            textItems = [],
            iconType,
            iconColor,
            narrowIconType,
            narrowIconColor,
        } = menuInfo;
        const hasOnlyOneItem = textItems.length === 1;

        if (hasOnlyOneItem) {
            const item = textItems[0];

            component = (
                <DropdownButton
                    item={item}
                    iconType={iconType}
                    iconColor={iconColor || ''}
                    narrowIconType={narrowIconType}
                    narrowIconColor={narrowIconColor || ''}
                    onClick={
                        onAdditionalMenuTextItemSelect ||
                        (() => {
                            return;
                        })
                    }
                />
            );
        } else {
            component = (
                <DropdownComponentByType
                    {...additionalProps}
                    menuInfo={menuInfo}
                    noLabel={noLabel}
                    onAdditionalMenuTextItemSelect={
                        onAdditionalMenuTextItemSelect
                    }
                />
            );
        }
    }

    return component;
};

export const GlobalHeaderMenus = ({
    userMenuInfo,
    additionalMenus,
    onAdditionalMenuTextItemSelect,
}: {
    userMenuInfo?: any;
    additionalMenus?: MenuInfoShape[];
    onAdditionalMenuTextItemSelect?: Function;
}) => {
    let menus: React.ReactNode[] = [];

    let component = null;
    const className = classNames('authenticated-header__menus');

    if (!isEmpty(additionalMenus) && additionalMenus) {
        menus = additionalMenus?.map((menuInfo) => (
            <GlobalHeaderMenu
                key={menuInfo.fallbackUrl}
                menuInfo={menuInfo}
                onAdditionalMenuTextItemSelect={onAdditionalMenuTextItemSelect}
            />
        ));
    }
    if (userMenuInfo) {
        const userMenuInfoWithIcon = {
            iconType: <UserChunkySvg />,
            className: 'authenticated-header__user-menu',
            ...userMenuInfo,
        };

        menus = [
            ...menus,
            <GlobalHeaderMenu
                key="userMenu"
                menuInfo={userMenuInfoWithIcon}
                data-spec="authenticated-header-user-menu"
            />,
        ];
    }

    if (!isEmpty(menus)) {
        component = (
            <div className={className} data-spec="authenticated-header-menus">
                {menus}
            </div>
        );
    }

    return component;
};
