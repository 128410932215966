import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';
import FieldWrapper from './FieldWrapper';
import { Icon } from '@eventbrite/eds-icon';
import SelectSuffix from './_internals/SelectSuffix';
import { getInitialValue } from '@eventbrite/eds-inputs';
import { getAdditionalProps } from '@eventbrite/eds-utils';

import * as constants from './constants';

import './inputField.scss';

import { ChevronDownChunky } from '@eventbrite/eds-iconography';

const _getOptions = (values, selectedValue) => {
    // Build up the list of optionComponents and determine the selected
    // display in one iteration. We're determining this derived value here
    // instead of storing it in state
    let selectedDisplay;
    const optionComponents = values.map(
        ({ value, display, isDisabled, key }) => {
            let disabled;

            // setting this value is a side effect of the single iteration
            if (value === selectedValue) {
                selectedDisplay = display;
            }

            if (isDisabled) {
                disabled = 'disabled';
            }

            return (
                <option
                    disabled={disabled}
                    key={key || value}
                    value={value}
                    data-spec="select-option"
                >
                    {display}
                </option>
            );
        },
    );

    return { selectedDisplay, optionComponents };
};

const SelectElement = ({
    dataAutomation,
    disabled,
    hasError,
    id,
    onBlur,
    onChange,
    onFocus,
    name,
    required,
    role,
    selectedValue,
    style,
    suffix,
    values,
    ...extraAttrs
}) => {
    const { selectedDisplay, optionComponents } = _getOptions(
        values,
        selectedValue,
    );

    let requiredIndicator = null;

    if (required && style === constants.STYLE_BASIC) {
        requiredIndicator = (
            <span className="eds-label__required-indicator eds-text-bm">
                {' '}
                *
            </span>
        );
    }

    return (
        <div className="eds-field-styled__select-wrapper">
            <span className="eds-field-styled__select-value" aria-hidden="true">
                <span className="eds-field-styled__select-value-text">
                    {selectedDisplay}
                    {requiredIndicator}
                </span>
                <SelectSuffix suffix={suffix} />
                <span className="eds-field-styled__select-icon">
                    <Icon
                        type={
                            <ChevronDownChunky
                                {...(id
                                    ? {
                                          id: `chevron-down-chunky_svg__eds-icon--chevron-down-chunky_svg-${id}`,
                                      }
                                    : {})}
                            />
                        }
                    />
                </span>
            </span>

            {/* VoiceOver won't read the label if the role is not defined for the select element */
            /* eslint-disable jsx-a11y/no-redundant-roles */}
            <select
                aria-invalid={hasError}
                aria-required={required}
                aria-labelledby={`${id}-label`}
                className="eds-field-styled__input eds-field-styled__select"
                data-automation={dataAutomation}
                disabled={disabled}
                id={id}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                role={role}
                value={selectedValue}
                {...extraAttrs}
            >
                {optionComponents}
            </select>
            {/* eslint-enable jsx-a11y/no-redundant-roles */}
        </div>
    );
};

export default class SelectField extends PureComponent {
    static propTypes = {
        annotationTag: PropTypes.string,
        /**
         * Array of formatted options
         */
        values: constants.VALUES_PROP_TYPE.isRequired,

        /**
         * Text to be displayed as annotation below the select element.
         */
        annotationNote: PropTypes.node,

        /**
         * Field border type chosen for the input. It can be one of: 'default', 'simple' or 'none'.
         * Default: the border changes from grey on idle to a coloured state on focus.
         * Simple: the border preserves its grey colour and has no rounded corners.
         * None: the border is hidden and is replaced by a line below the input to point the writtable area.
         */
        borderType: PropTypes.oneOf(constants.BORDER_TYPES),

        /**
         * The amount of spacing for the bottom
         */
        bottomSpacing: PropTypes.number,

        /**
         * Default value of select element.
         * This is the initial value.
         * After the select input has been rendered the first time,
         * if the default value changes, it will *not* update the input.
         */
        defaultValue: PropTypes.string,

        /**
         * Sets disabled attribute to the select element.
         */
        disabled: PropTypes.bool,

        /**
         * Sets error state (adds error classes and aria-invalid).
         */
        hasError: PropTypes.bool,

        /**
         * Sets the id for the select element and htmlFor attribute for the label.
         */
        id: PropTypes.string,

        /**
         * Text to be displayed as the label of the select element.
         * Required for accessibility reasons but it can be hidden.
         */
        label: PropTypes.node.isRequired,

        /**
         * Name of select element.
         */
        name: PropTypes.string,

        /**
         * {function} onBlur
         * By default sets isActive state to false and delegates the control to
         * the onBlur function passed as prop.
         */
        onBlur: PropTypes.func,

        /**
         * {function} onChange
         * By default sets the select value to the state and delegates
         * the control to the onChange function passed as prop.
         */
        onChange: PropTypes.func,

        /**
         * {function} onFocus
         * By default sets isActive state to true and delegates the control to
         * the onFocus function passed as prop.
         */
        onFocus: PropTypes.func,

        /**
         * Text to be displayed when no option is selected.
         * if you need a blank option that is selectable
         *    - remove the placeholder prop
         *    - add a new value list with the proper display text and empty value
         */
        placeholder: translationPropType,

        /**
         * Marks the select as required (adds * and aria-required).
         */
        required: PropTypes.bool,

        /**
         * Field style chosen for the input. It can be one of: 'static' or 'basic'.
         * Static: the label and the placeholder are always in place and visible.
         * Basic: the label is hidden but still present for accessibility purposes.
         */
        style: PropTypes.oneOf(constants.SELECT_STYLES),

        role: PropTypes.string,
        /**
         * Node to be displayed to the right of the select field but to the left of the chevron.
         */
        suffix: PropTypes.node,

        /**
         * Current selected value.
         */
        value: PropTypes.string,

        /**
         * Identifier for unit tests.
         */
        'data-spec': PropTypes.string,
        /**
         * Identifier for automation tests
         */
        'data-automation': PropTypes.string,
    };

    static defaultProps = {
        bottomSpacing: 2,
        defaultValue: '',
        style: constants.STYLE_STATIC,
        borderType: constants.BORDER_DEFAULT,
        'data-spec': 'select-field',
    };

    constructor(props) {
        super(props);

        const { value, defaultValue, placeholder, values } = props;

        this.state = {
            isActive: false,
            selectedValue: getInitialValue(
                value || defaultValue,
                values,
                placeholder,
            ),
        };
    }

    UNSAFE_componentWillReceiveProps({ value, values, placeholder }) {
        // allows setting an existing `input` element's value via props from a parent
        if (value !== undefined) {
            this.setState({
                selectedValue: getInitialValue(value, values, placeholder),
            });
        }
    }

    _handleBlur() {
        this.setState({ isActive: false });

        if (this.props.onBlur) {
            this.props.onBlur(this.state.selectedValue);
        }
    }

    _handleChange(e) {
        const { value, selectedIndex } = e.target;

        const { values, placeholder } = this.props;

        let selectedValue = value;
        let _selectedIndex = selectedIndex;

        /**
         * The placeholder adds one more option in the component but we don't have that in the `values` from the props
         * so before accessing it we need to remove that extra value.
         * We can potentially use the options that comes from the target but that may not be safe.
         */
        if (placeholder) {
            _selectedIndex = selectedIndex - 1;
        }

        if (values.length && values[_selectedIndex]) {
            selectedValue = values[_selectedIndex].value;
        }

        this.setState({ selectedValue });

        if (this.props.onChange) {
            this.props.onChange(selectedValue, selectedIndex);
        }
    }

    _handleFocus() {
        this.setState({ isActive: true });

        if (this.props.onFocus) {
            this.props.onFocus(this.state.selectedValue);
        }
    }

    render() {
        const {
            annotationTag,
            annotationNote,
            borderType,
            bottomSpacing,
            disabled,
            hasError,
            id,
            label,
            name,
            placeholder,
            required,
            role = 'listbox',
            style,
            suffix,
            'data-spec': dataSpec,
            'data-automation': dataAutomation,
        } = this.props;
        let { values } = this.props;
        const { isActive, selectedValue } = this.state;
        const extraAttrs = getAdditionalProps(this);
        const wrapperDataAutomation = dataAutomation
            ? `${dataAutomation}-wrapper`
            : 'select-field-wrapper';

        if (placeholder) {
            values = [
                // placeholder is not natively supported in dropdowns
                // to achieve the same functionality, we place an empty disabled value as the first option in the list
                {
                    display: placeholder,
                    value: constants.PLACEHOLDER_DUMMY_VALUE,
                    isDisabled: true,
                },
                ...values,
            ];
        }

        return (
            <FieldWrapper
                annotationTag={annotationTag}
                annotationNote={annotationNote}
                borderType={borderType}
                bottomSpacing={bottomSpacing}
                dataAutomation={wrapperDataAutomation}
                dataSpec={dataSpec}
                disabled={disabled}
                hasError={hasError}
                id={id}
                isActive={isActive}
                label={label}
                required={required}
                style={style}
                value={selectedValue}
            >
                <SelectElement
                    {...extraAttrs}
                    dataAutomation={dataAutomation}
                    disabled={disabled}
                    hasError={hasError}
                    id={id}
                    onBlur={this._handleBlur.bind(this)}
                    onChange={this._handleChange.bind(this)}
                    onFocus={this._handleFocus.bind(this)}
                    name={name}
                    required={required}
                    role={role}
                    selectedValue={selectedValue}
                    style={style}
                    suffix={suffix}
                    values={values}
                />
            </FieldWrapper>
        );
    }
}
