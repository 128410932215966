import {
    addFollow,
    FollowButton,
    withFollow,
} from '@eventbrite/follow-organizer-ebui';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { ApplicationContext } from '../../context';
import { AnalyticsCategory } from '../../_shared';

enum FollowButtonStyle {
    STANDARD_BUTTON_STYLE,
    SOLITARY_BUTTON_STYLE,
}
export interface FollowButtonProps {
    isAuthenticated: boolean;
    gaSettings: any;
    gaCategory: AnalyticsCategory;
    organizers: string[];
    isFollowing: boolean;
    organizerId: string;
    style?: FollowButtonStyle;
    text?: { active: string; default: string };
}

const EnhancedFollowButton = withFollow(FollowButton);
const OrganizerPanelFollowButton: React.FunctionComponent<FollowButtonProps> = (
    props,
) => {
    return (
        <EnhancedFollowButton
            isOnlyButton={true}
            organizerId={props.organizerId}
            isAuthenticated={props.isAuthenticated}
            gaSettings={props.gaSettings}
            gaCategory={props.gaCategory}
            isFollowing={props.isFollowing}
            style={props.style}
            text={props.text}
        />
    );
};

export const FollowOrganizerButton: React.FunctionComponent<FollowButtonProps> =
    addFollow(OrganizerPanelFollowButton);

export const FollowOrganizer = () => (
    <ApplicationContext.Consumer>
        {(context) => (
            <FollowOrganizerButton
                isAuthenticated={context?.user?.isAuthenticated || false}
                gaSettings={context?.gaSettings}
                gaCategory={AnalyticsCategory.Listing}
                organizers={
                    context?.collection
                        ? [context.collection?.organizer.id]
                        : []
                }
                organizerId={context?.collection?.organizer.id || ''}
                isFollowing={
                    context?.collection?.organizer.follow_status
                        ?.followed_by_you || false
                }
                text={{
                    active: gettext('Following organizer').toString(),
                    default: gettext('Follow organizer').toString(),
                }}
            />
        )}
    </ApplicationContext.Consumer>
);
