import { CopyButton } from '@eventbrite/eds-copy-button';
import { Icon } from '@eventbrite/eds-icon';
import { CheckChunky, CopyChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import React, { useContext, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ApplicationContext } from '../context';
import { AnalyticsAction, AnalyticsCategory, trackEvent } from '../_shared';

export const CopyUrlButton: React.FC = () => {
    const [copied, setCopied] = useState(false);
    const context = useContext(ApplicationContext);
    const toastDuration = 3000;

    useEffect(() => {
        if (copied) {
            const timer = setTimeout(() => {
                setCopied(false);
            }, toastDuration);
            return () => clearTimeout(timer);
        }
    }, [copied]);

    return (
        <>
            <CopyButton
                value={context?.collection.url}
                render={() => (
                    <button className="cc-copy-button">
                        <Icon type={<CopyChunky />} />
                        <span className="cc-copy-button__text">
                            {gettext('Copy link')}
                        </span>
                    </button>
                )}
                onCopyClick={() => {
                    setCopied(true);
                    trackEvent({
                        category: AnalyticsCategory.Listing,
                        action: AnalyticsAction.CopyURL,
                        label: 'organizer-banner',
                    });
                }}
            />
            <CSSTransition
                in={copied}
                timeout={700}
                classNames="slide-up"
                unmountOnExit={true}
            >
                <Toast />
            </CSSTransition>
        </>
    );
};

export const Toast = () => {
    return (
        <div data-spec="toast" className="cc-toast">
            <div className="eds-l-pad-left-3">
                <Icon size="xsmall" type={<CheckChunky />} color="white" />
            </div>
            <span className="eds-text-color--white eds-l-pad-left-1">
                {gettext('Collection link copied')}
            </span>
        </div>
    );
};
