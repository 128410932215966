import {
    CoreEnv,
    CoreFooterLinks,
    CoreRequest,
    CoreUser,
} from '@eventbrite/context-gen';
import { FeatureFlags } from '@eventbrite/feature-flag-utils';
import {
    AuthenticatedHeader,
    RenderGlobalNavOrUndefined,
} from '@eventbrite/global-nav';
import { gettext } from '@eventbrite/i18n';
import { SiteStructure } from '@eventbrite/site-structure';
import React from 'react';
import { PageLayoutPreview } from './';

interface Props {
    preview?: boolean;
    env: CoreEnv;
    request: CoreRequest;
    user: CoreUser;
    footerLinks: CoreFooterLinks;
    featureFlags: FeatureFlags;
}

export const PageLayout: React.FC<Props> = ({
    children,
    preview,
    env,
    request,
    user,
    footerLinks,
    featureFlags,
}) => {
    if (preview) {
        return <PageLayoutPreview>{children}</PageLayoutPreview>;
    }

    const globalHeader = user.isAuthenticated ? (
        <AuthenticatedHeader
            env={env}
            request={request}
            user={user}
            featureFlags={featureFlags}
            action_from={'creator_collection'}
        />
    ) : (
        RenderGlobalNavOrUndefined({
            config: {
                env,
                user,
                searchBar: {
                    renderSearchBar: true,
                    desktopLabel: gettext('Search events'),
                    mobileLabel: gettext('Search'),
                },
            },
            featureFlag: featureFlags?.enableIaGlobalNav ?? false,
        })
    );

    return (
        <SiteStructure
            env={env}
            request={request}
            user={user}
            footerLinks={footerLinks}
            fullScreenMain={true}
            showMarketingLinks={true}
            mainBackgroundColor="eds-bg-color--ui-100"
            globalHeader={globalHeader}
        >
            {children}
        </SiteStructure>
    );
};
