import { CoreApplicationContext } from '@eventbrite/context-gen';
import { gettext } from '@eventbrite/i18n';
import { getLogoInfo, getUserMenuInfo } from '@eventbrite/site-header';

import React from 'react';
import ConsumerHeaderSearchBar from '../ConsumerHeaderSearchBar';
import {
    CamelCaseLocation,
    FeatureFlags,
    MenuInfoShape,
    RequestShape,
    UserShape,
} from '../types/index';
import GlobalHeaderLogo from './GlobalHeaderLogo';
import { GlobalHeaderMenus } from './GlobalHeaderNavigationBar';
import './HeaderIndex.scss';
import { DesktopHeaderLinks, MobileHeaderLinks } from './HeaderLinks';

interface ConsumerHeaderProps {
    env: CoreApplicationContext['env'];
    request: RequestShape;
    user: UserShape;
    onAdditionalMenuTextItemSelect?: Function;
    isHomePage?: boolean;
    isSearchPage?: boolean;
    id?: string;
    featureFlags: FeatureFlags;
    hasBreadcrumbs?: boolean;
    searchQuery?: string;
    currentPlace?: string;
    isUsingCurrentLocation?: boolean;
    action_from: string;
    userMenuInfo?: MenuInfoShape;
    desktopMenuLinkComponents?: React.ReactElement[];
    mobileMenuLinks?: React.ReactElement[];
    location?: CamelCaseLocation;
}

const AuthenticatedHeader: React.FunctionComponent<ConsumerHeaderProps> = (
    props,
) => {
    const {
        searchQuery = '',
        user,
        env,
        id,
        featureFlags,
        hasBreadcrumbs,
        request,
        isUsingCurrentLocation = false,
        action_from,
        location,
    } = props;

    const label = searchQuery || gettext('Search events');
    const searchBar = {
        renderSearchBar: true,
        desktopLabel: label,
        mobileLabel: label,
        isUsingCurrentLocation: isUsingCurrentLocation || false,
        location,
    };

    const { serverUrl, adminServerUrl, logoutUrl } = env;
    const { path: requestPath } = request;

    const defaultUserMenuInfo = getUserMenuInfo({
        adminServerUrl,
        featureFlags,
        logoutUrl,
        requestPath,
        serverUrl,
        showBrowseEvents: true,
        user,
    });

    const userMenuInfo = props.userMenuInfo
        ? props.userMenuInfo
        : defaultUserMenuInfo;

    const getHeaderClasses = () => {
        let headerClasses = 'authenticated-header';
        if (hasBreadcrumbs) {
            headerClasses += ' authenticated-header__has-breadcrumbs';
        }
        return headerClasses;
    };

    return (
        <header
            id={id || 'global-header'}
            className={getHeaderClasses()}
            data-spec="authenticated-header"
        >
            <a
                className="authenticated-header__skip-links eds-is-hidden-accessible"
                href="#skip-heading"
            >
                {gettext('Skip Main Navigation')}
            </a>
            <div className="authenticated-header__main" data-role="header">
                <GlobalHeaderLogo
                    info={getLogoInfo(serverUrl, requestPath, {
                        iconWidth: '110px',
                    })}
                />
                <ConsumerHeaderSearchBar
                    user={user}
                    env={env}
                    searchBar={searchBar}
                />
                <div
                    className="authenticated-header__desktop"
                    data-spec="authenticated-header-desktop"
                >
                    <DesktopHeaderLinks
                        user={user}
                        env={env}
                        featureFlags={featureFlags}
                        requestPath={requestPath}
                        action_from={action_from}
                        desktopMenuComponents={props.desktopMenuLinkComponents}
                    />
                    <GlobalHeaderMenus userMenuInfo={userMenuInfo} />
                </div>
                <div
                    className="authenticated-header__mobile"
                    data-spec="authenticated-header-mobile"
                >
                    <MobileHeaderLinks
                        user={user}
                        env={env}
                        featureFlags={featureFlags}
                        requestPath={requestPath}
                        action_from={action_from}
                        narrowMobileMenuInfo={props.mobileMenuLinks}
                    />
                </div>
            </div>
        </header>
    );
};

export default AuthenticatedHeader;
