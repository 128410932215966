import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
    LOCALE_INFO_SHAPE_PROP_TYPE,
    LINKS_DATA_SHAPE_PROP_TYPE,
} from './constants';
import { getMainSections } from './utils';

const _getMarketingLinkItems = (links = []) =>
    links.map(({ content, url, additionalClassName = '', ...linkProps }) => {
        const className = classnames('eds-global-footer__link', {
            [additionalClassName]: additionalClassName,
        });
        let key = content;

        if (typeof content === 'object' || !content) {
            key = url;
        }

        return (
            <li key={key}>
                <a href={url} {...linkProps} className={className}>
                    {content}
                </a>
            </li>
        );
    });

const _getMarketingLinks = (linkTypes) =>
    linkTypes.map(({ title, links }) => (
        <div
            key={title.toString()}
            className="mobile-footer__align eds-g-cell eds-g-cell-12-12 eds-g-cell-sw-3-12 eds-l-pad-left-6 eds-l-sn-pad-left-0 eds-l-sm-pad-left-0 eds-l-pad-top-4"
        >
            <h3 className="eds-l-pad-vert-2">{title}</h3>
            <ul className="eds-l-pad-vert-2 eds-l-mar-all-0 eds-l-pad-hor-0">
                {_getMarketingLinkItems(links)}
            </ul>
        </div>
    ));

export default class MarketingSection extends React.Component {
    static propTypes = {
        serverUrl: PropTypes.string.isRequired,
        localeInfo: LOCALE_INFO_SHAPE_PROP_TYPE.isRequired,
        popularSearches: LINKS_DATA_SHAPE_PROP_TYPE.isRequired,
        eventsToPlan: LINKS_DATA_SHAPE_PROP_TYPE.isRequired,
        thirdFooterColumn: LINKS_DATA_SHAPE_PROP_TYPE.isRequired,
        thirdFooterColumnName: PropTypes.string.isRequired,
    };

    render() {
        const {
            localeInfo,
            serverUrl,
            popularSearches,
            eventsToPlan,
            thirdFooterColumn,
            thirdFooterColumnName,
        } = this.props;

        const marketingLinks = _getMarketingLinks(
            getMainSections(
                serverUrl,
                localeInfo,
                popularSearches,
                eventsToPlan,
                thirdFooterColumn,
                thirdFooterColumnName,
            ),
        );

        return (
            <div
                className="eds-bg-color--grey-900"
                data-testid="marketing-section"
            >
                {marketingLinks}
            </div>
        );
    }
}
