import { getOnlineEventsOption } from '@eventbrite/location-autocomplete';
import {
    getLocationData,
    setLocationData,
} from '@eventbrite/redux-destination';
import {
    deepKeysToCamel,
    keysCamelToSnake,
} from '@eventbrite/transformation-utils';
import React from 'react';
import { useMount } from 'react-use';
import { getLocationFromRequest } from '../api';
import { useHandleUserCurrentLocation } from '../hooks';
import { CamelCaseLocation, LocationAction, LocationState } from '../types';

export const useSetLocationOnMount = (
    callback: Function,
    initialLocation?: CamelCaseLocation,
) => {
    useMount(() => {
        if (initialLocation) {
            return callback(initialLocation);
        }

        const locationFromCookie = getLocationData();

        if (locationFromCookie && locationFromCookie.slug) {
            return callback(deepKeysToCamel(locationFromCookie));
        }

        getLocationFromRequest()
            .then((res) => {
                const formatLocationResponse = deepKeysToCamel(
                    res.place,
                ) as CamelCaseLocation;
                callback(formatLocationResponse);
            })
            .catch(() => {
                callback({
                    ...getOnlineEventsOption(),
                    placeId: null,
                });
            });
    });
};

export const useLocationContext = () => {
    const value = React.useContext(LocationContext);

    if (value.location === undefined && value.setLocation === undefined) {
        throw new Error(
            'useLocationContext must be used within LocationProvider',
        );
    }

    return value;
};

const locationStateReducer = (
    prevState: LocationState,
    action: LocationAction,
): LocationState => {
    switch (action.type) {
        case 'waitForLocation':
            return {
                ...prevState,
                waitingForCurrentLocation: true,
                isUsingUserLocation: false,
            };
        case 'setLocation':
            return {
                location: action.payload || prevState.location,
                shouldUseUserLocation: false,
                waitingForCurrentLocation: false,
                isUsingUserLocation: false,
            };
        case 'getCurrentLocation':
            return {
                ...prevState,
                shouldUseUserLocation: true,
                waitingForCurrentLocation: true,
            };
        case 'setUserLocation':
            return {
                location: action.payload || prevState.location,
                shouldUseUserLocation: false,
                waitingForCurrentLocation: false,
                isUsingUserLocation: true,
            };
        default:
            return prevState;
    }
};

export const LocationContext = React.createContext<
    Partial<{
        location: CamelCaseLocation;
        waitForLocation(): void;
        setLocation(location: CamelCaseLocation): void;
        waitingForCurrentLocation: boolean;
        isUsingUserLocation: boolean;
        getCurrentLocation(): void;
        setLocationCookie(location: CamelCaseLocation): void;
    }>
>({});

interface LocationProviderProps {
    location?: CamelCaseLocation;
}
/**
 * LocationInputProvider
 *  provides information about the location input:
 *      • isFocused / active
 *      • Query
 *
 * LocationProvider
 *  provides current set location information
 *      • onMount: get an initial location
 *      • update when location selected
 *
 * @param props
 * @returns
 */
export const LocationProvider: React.FunctionComponent<
    LocationProviderProps
> = (props) => {
    const [state, dispatch] = React.useReducer(locationStateReducer, {
        location: props.location,
        shouldUseUserLocation: false,
        waitingForCurrentLocation: false,
        isUsingUserLocation: false,
    });

    useSetLocationOnMount(
        (location: CamelCaseLocation) =>
            dispatch({ type: 'setLocation', payload: location }),
        props.location,
    );

    useHandleUserCurrentLocation({
        dispatch,
        shouldUseUserLocation: state.shouldUseUserLocation,
        location: state.location,
    });

    return (
        <LocationContext.Provider
            value={{
                location: state.location,
                waitingForCurrentLocation: state.waitingForCurrentLocation,
                isUsingUserLocation: state.isUsingUserLocation,
                setLocation: (location) =>
                    dispatch({ type: 'setLocation', payload: location }),
                getCurrentLocation: () =>
                    dispatch({ type: 'getCurrentLocation' }),
                waitForLocation: () => dispatch({ type: 'waitForLocation' }),
                setLocationCookie: (location) =>
                    setLocationData(keysCamelToSnake(location)),
            }}
        >
            {props.children}
        </LocationContext.Provider>
    );
};

export default LocationProvider;
