import times from 'lodash/times';
import { gettext } from '@eventbrite/i18n';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@eventbrite/eds-icon';
import { Button } from '@eventbrite/eds-button';
import { CUSTOM_NAVIGATION_PROP_TYPE } from '../constants';

import { StatusDotChunky } from '@eventbrite/eds-iconography';

const _getNavigationItems = ({
    totalItems,
    activeIndex,
    customNavigation,
    onClick,
}) =>
    times(totalItems, (index) => {
        const isActive = activeIndex === index;
        const color = isActive ? 'grey-800' : 'grey-300';
        const title = gettext('Slide %(index)s', { index });
        let innerNavigation = (
            <Icon type={<StatusDotChunky />} color={color} title={title} />
        );
        const customNavigationItem =
            customNavigation && customNavigation[index];

        if (customNavigationItem) {
            const { base, active } = customNavigationItem;

            innerNavigation = isActive ? active : base;
        }

        return (
            <Button
                key={index}
                style="none"
                onClick={onClick.bind(null, index)}
                __containerClassName="eds-carousel-bottom-navigation__content"
            >
                {innerNavigation}
            </Button>
        );
    });

export default class IconBottomNavigation extends PureComponent {
    static propTypes = {
        displayIndex: PropTypes.number.isRequired,
        totalItems: PropTypes.number.isRequired,
        onClick: PropTypes.func.isRequired,
        customNavigation: CUSTOM_NAVIGATION_PROP_TYPE,
    };

    render() {
        const { customNavigation, displayIndex, onClick, totalItems } =
            this.props;
        const activeIndex =
            displayIndex >= totalItems
                ? displayIndex % totalItems
                : displayIndex;

        const navigation = _getNavigationItems({
            customNavigation,
            activeIndex,
            totalItems,
            onClick,
        });

        return (
            <nav className="eds-align--center eds-l-pad-vert-4">
                {navigation}
            </nav>
        );
    }
}
