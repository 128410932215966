import {
    EVENTBRITE_CATEGORY,
    EVENTBRITE_CATEGORY_MAPPING,
    EVENTBRITE_FORMAT,
    EVENTBRITE_SUB_CATEGORY,
    FORMATTED_SUBCATEGORY_MAP,
    SUBCATEGORY_REFINEMENT_TO_KEY,
} from './constants';

export function isCategory(target: string) {
    return target.startsWith(EVENTBRITE_CATEGORY);
}

export function isSubCategory(target: string) {
    return target.startsWith(EVENTBRITE_SUB_CATEGORY);
}

export function isFormat(target: string) {
    return target.startsWith(EVENTBRITE_FORMAT);
}

export const getParentCategoryFromSubCategory = (
    subcategory: string,
): string => {
    const subcategoryId = subcategory.split('/')[1];
    const shouldGrabOneDigit = subcategoryId.length === 4;
    const categoryId = shouldGrabOneDigit
        ? `10${subcategoryId.slice(0, 1)}`
        : `1${subcategoryId.slice(0, 2)}`;

    const categoryKey = `${EVENTBRITE_CATEGORY}/${categoryId}`;
    return categoryKey;
};

export const findCategoryByTitle = (categoryTitle: string): string | null => {
    const entry = Object.entries(EVENTBRITE_CATEGORY_MAPPING).find(
        ([_, value]) =>
            value
                .toString()
                .toLowerCase()
                .includes(categoryTitle.toLowerCase()),
    );
    return entry ? entry[0] : null;
};

export const getSubCategoriesByCategory = (category: string) => {
    const SUBCATEGORIES = SUBCATEGORY_REFINEMENT_TO_KEY[category];
    if (!SUBCATEGORIES) return [];
    if ('other' in SUBCATEGORIES) {
        delete SUBCATEGORIES['other'];
    }
    const subcategories = Object.values(SUBCATEGORIES);

    return (subcategories || [])
        .map((subcategory: string) => FORMATTED_SUBCATEGORY_MAP[subcategory])
        .filter(
            (subcategoryData) =>
                subcategoryData.imageUrl !== '' ||
                subcategoryData.imageUrlSmall !== '',
        );
};
