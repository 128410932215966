import { gettext } from '@eventbrite/i18n';
import {
    SHARE_ON_EMAIL,
    SHARE_ON_FACEBOOK,
    SHARE_ON_FACEBOOK_MESSENGER,
    SHARE_ON_TWITTER,
} from '@eventbrite/eds-share-box';

export const EVENTBRITE = 'Eventbrite';
export const TICKETFLY = 'Ticketfly';
export const GOLDSTAR = 'Goldstar';

export const LINK_FOLLOW_ALLOWLIST = [EVENTBRITE, TICKETFLY];

/****************************** APP LEVEL ********************************/

export const EVENT_TYPE = 'event';
export const EVENTS_TYPE = 'events';
export const ALL_EVENTS_TYPE = 'all events';
export const GOOGLE_MAPS_CLIENT_KEY = 'gme-eventbrite';

/****************************** TEMPLATES AND CARD TYPES ********************************/

export const EVENT_CARD_TYPE = EVENT_TYPE;
export const ARTICLE_CARD_TYPE = 'article';
export const PROFILE_CARD_TYPE = 'profile';
export const CATEGORY_TYPE = 'category';
export const NEARBY_CITY_TYPE = 'place';
export const TRENDING_SEARCH_TYPE = 'trending';
export const COLLECTION_TYPE = 'collection';
export const FOLLOW_ORGANIZER_TYPE = 'follow_organizer';

export const ENTITY_TYPE_MAP = {
    [EVENT_CARD_TYPE]: 'events',
    [ARTICLE_CARD_TYPE]: 'articles',
    [CATEGORY_TYPE]: 'tags',
    [NEARBY_CITY_TYPE]: 'places',
    [TRENDING_SEARCH_TYPE]: 'trending',
    [COLLECTION_TYPE]: 'collections',
    [FOLLOW_ORGANIZER_TYPE]: 'follow_organizer',
};

export const SINGULARIZED_ENTITY_TYPE_MAP = {
    events: EVENT_CARD_TYPE,
    profiles: PROFILE_CARD_TYPE,
    articles: ARTICLE_CARD_TYPE,
    places: NEARBY_CITY_TYPE,
};

/****************************** SEARCH CONSTANTS ********************************/

export const SEARCH_CRITERIA_TYPE_MAP = [
    'event_search',
    'article_search',
    'profile_search',
];
export const SEARCH_TYPE_MAP = ['events', 'articles', 'profiles'];

export const START_DATE = 'start_date';
export const END_DATE = 'end_date';
export const EVENT_PAGE_PARAM = 'page';
export const SEARCH_TYPE_PAGE_PARAM_MAP = {
    [EVENT_CARD_TYPE]: EVENT_PAGE_PARAM,
    [ARTICLE_CARD_TYPE]: 'a_page',
    [PROFILE_CARD_TYPE]: 'p_page',
};

/****************************** CATEGORY MAPPING ********************************/

export const CATEGORY_TAG_TYPE = 'Category';
export const SUBCATEGORY_TAG_TYPE = 'SubCategory';

//Eventbrite Category ID Mapping
export const BUSINESS = 101;
export const SCIENCE = 102;
export const MUSIC = 103;
export const FILM = 104;
export const PERFORMING = 105;
export const FASHION = 106;
export const HEALTH = 107;
export const SPORTS = 108;
export const TRAVEL = 109;
export const FOOD = 110;
export const CHARITY = 111;
export const POLITICS = 112;
export const COMMUNITY = 113;
export const RELIGION = 114;
export const FAMILY_EDUCATION = 115;
export const SEASONAL = 116;
export const HOME_LIFESTYLE = 117;
export const AUTO_BOAT_AIR = 118;
export const HOBBIES = 119;
export const SCHOOL = 120;
export const OTHER = 199;

export const EVENTBRITE_CATEGORY = 'EventbriteCategory';
export const BUSINESS_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${BUSINESS}`;
export const SCIENCE_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${SCIENCE}`;
export const MUSIC_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${MUSIC}`;
export const FILM_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${FILM}`;
export const PERFORMING_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${PERFORMING}`;
export const FASHION_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${FASHION}`;
export const HEALTH_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${HEALTH}`;
export const SPORTS_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${SPORTS}`;
export const TRAVEL_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${TRAVEL}`;
export const FOOD_AND_DRINK_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${FOOD}`;
export const CHARITY_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${CHARITY}`;
export const POLITICS_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${POLITICS}`;
export const COMMUNITY_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${COMMUNITY}`;
export const RELIGION_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${RELIGION}`;
export const FAMILY_EDUCATION_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${FAMILY_EDUCATION}`;
export const SEASONAL_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${SEASONAL}`;
export const HOME_LIFESTYLE_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${HOME_LIFESTYLE}`;
export const AUTO_BOAT_AIR_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${AUTO_BOAT_AIR}`;
export const HOBBIES_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${HOBBIES}`;
export const SCHOOL_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${SCHOOL}`;
export const OTHER_EVENTBRITE_CATEGORY = `${EVENTBRITE_CATEGORY}/${OTHER}`;

export const EVENTBRITE_CATEGORY_ID_STRINGS = [
    BUSINESS_EVENTBRITE_CATEGORY,
    SCIENCE_EVENTBRITE_CATEGORY,
    MUSIC_EVENTBRITE_CATEGORY,
    FILM_EVENTBRITE_CATEGORY,
    PERFORMING_EVENTBRITE_CATEGORY,
    FASHION_EVENTBRITE_CATEGORY,
    HEALTH_EVENTBRITE_CATEGORY,
    SPORTS_EVENTBRITE_CATEGORY,
    TRAVEL_EVENTBRITE_CATEGORY,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    CHARITY_EVENTBRITE_CATEGORY,
    POLITICS_EVENTBRITE_CATEGORY,
    COMMUNITY_EVENTBRITE_CATEGORY,
    RELIGION_EVENTBRITE_CATEGORY,
    FAMILY_EDUCATION_EVENTBRITE_CATEGORY,
    SEASONAL_EVENTBRITE_CATEGORY,
    HOME_LIFESTYLE_EVENTBRITE_CATEGORY,
    AUTO_BOAT_AIR_EVENTBRITE_CATEGORY,
    HOBBIES_EVENTBRITE_CATEGORY,
    SCHOOL_EVENTBRITE_CATEGORY,
    OTHER_EVENTBRITE_CATEGORY,
];

export const EVENTBRITE_CATEGORY_MAPPING = {
    [BUSINESS_EVENTBRITE_CATEGORY]: gettext('Business'),
    [SCIENCE_EVENTBRITE_CATEGORY]: gettext('Science & Tech'),
    [MUSIC_EVENTBRITE_CATEGORY]: gettext('Music'),
    [FILM_EVENTBRITE_CATEGORY]: gettext('Film & Media'),
    [PERFORMING_EVENTBRITE_CATEGORY]: gettext('Performing & Visual Arts'),
    [FASHION_EVENTBRITE_CATEGORY]: gettext('Fashion'),
    [HEALTH_EVENTBRITE_CATEGORY]: gettext('Health'),
    [SPORTS_EVENTBRITE_CATEGORY]: gettext('Sports & Fitness'),
    [TRAVEL_EVENTBRITE_CATEGORY]: gettext('Travel & Outdoor'),
    [FOOD_AND_DRINK_EVENTBRITE_CATEGORY]: gettext('Food & Drink'),
    [CHARITY_EVENTBRITE_CATEGORY]: gettext('Charity & Causes'),
    [POLITICS_EVENTBRITE_CATEGORY]: gettext('Government'),
    [COMMUNITY_EVENTBRITE_CATEGORY]: gettext('Community'),
    [RELIGION_EVENTBRITE_CATEGORY]: gettext('Spirituality'),
    [FAMILY_EDUCATION_EVENTBRITE_CATEGORY]: gettext('Family & Education'),
    [SEASONAL_EVENTBRITE_CATEGORY]: gettext('Holiday'),
    [HOME_LIFESTYLE_EVENTBRITE_CATEGORY]: gettext('Home & Lifestyle'),
    [AUTO_BOAT_AIR_EVENTBRITE_CATEGORY]: gettext('Auto, Boat & Air'),
    [HOBBIES_EVENTBRITE_CATEGORY]: gettext('Hobbies'),
    [SCHOOL_EVENTBRITE_CATEGORY]: gettext('School Activities'),
    [OTHER_EVENTBRITE_CATEGORY]: gettext('Other'),
};

// We want a different order for search with most popular filters at the top
export const EVENTBRITE_CATEGORY_MAPPING_SEARCH = {
    [BUSINESS_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[BUSINESS_EVENTBRITE_CATEGORY],
    [FOOD_AND_DRINK_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[FOOD_AND_DRINK_EVENTBRITE_CATEGORY],
    [HEALTH_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[HEALTH_EVENTBRITE_CATEGORY],
    [MUSIC_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[MUSIC_EVENTBRITE_CATEGORY],
    [AUTO_BOAT_AIR_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[AUTO_BOAT_AIR_EVENTBRITE_CATEGORY],
    [CHARITY_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[CHARITY_EVENTBRITE_CATEGORY],
    [COMMUNITY_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[COMMUNITY_EVENTBRITE_CATEGORY],
    [FAMILY_EDUCATION_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[FAMILY_EDUCATION_EVENTBRITE_CATEGORY],
    [FASHION_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[FASHION_EVENTBRITE_CATEGORY],
    [FILM_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[FILM_EVENTBRITE_CATEGORY],
    [HOBBIES_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[HOBBIES_EVENTBRITE_CATEGORY],
    [HOME_LIFESTYLE_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[HOME_LIFESTYLE_EVENTBRITE_CATEGORY],
    [PERFORMING_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[PERFORMING_EVENTBRITE_CATEGORY],
    [POLITICS_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[POLITICS_EVENTBRITE_CATEGORY],
    [RELIGION_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[RELIGION_EVENTBRITE_CATEGORY],
    [SCHOOL_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[SCHOOL_EVENTBRITE_CATEGORY],
    [SCIENCE_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[SCIENCE_EVENTBRITE_CATEGORY],
    [SEASONAL_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[SEASONAL_EVENTBRITE_CATEGORY],
    [SPORTS_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[SPORTS_EVENTBRITE_CATEGORY],
    [TRAVEL_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[TRAVEL_EVENTBRITE_CATEGORY],
    [OTHER_EVENTBRITE_CATEGORY]:
        EVENTBRITE_CATEGORY_MAPPING[OTHER_EVENTBRITE_CATEGORY],
};

// Translators: these are going to be used as part of a sentence
export const EVENTBRITE_CATEGORY_LOWERCASE_MAPPING = {
    [BUSINESS_EVENTBRITE_CATEGORY]: gettext('business'),
    [SCIENCE_EVENTBRITE_CATEGORY]: gettext('science & tech'),
    [MUSIC_EVENTBRITE_CATEGORY]: gettext('music'),
    [FILM_EVENTBRITE_CATEGORY]: gettext('film & media'),
    [PERFORMING_EVENTBRITE_CATEGORY]: gettext('arts'),
    [FASHION_EVENTBRITE_CATEGORY]: gettext('fashion'),
    [HEALTH_EVENTBRITE_CATEGORY]: gettext('health'),
    [SPORTS_EVENTBRITE_CATEGORY]: gettext('sports & fitness'),
    [TRAVEL_EVENTBRITE_CATEGORY]: gettext('travel & outdoor'),
    [FOOD_AND_DRINK_EVENTBRITE_CATEGORY]: gettext('food & drink'),
    [CHARITY_EVENTBRITE_CATEGORY]: gettext('charity & causes'),
    [POLITICS_EVENTBRITE_CATEGORY]: gettext('government'),
    [COMMUNITY_EVENTBRITE_CATEGORY]: gettext('community'),
    [RELIGION_EVENTBRITE_CATEGORY]: gettext('spirituality'),
    [FAMILY_EDUCATION_EVENTBRITE_CATEGORY]: gettext('family & education'),
    [SEASONAL_EVENTBRITE_CATEGORY]: gettext('holiday'),
    [HOME_LIFESTYLE_EVENTBRITE_CATEGORY]: gettext('home & lifestyle'),
    [AUTO_BOAT_AIR_EVENTBRITE_CATEGORY]: gettext('auto, boat & air'),
    [HOBBIES_EVENTBRITE_CATEGORY]: gettext('hobbies'),
    [SCHOOL_EVENTBRITE_CATEGORY]: gettext('school activities'),
    [OTHER_EVENTBRITE_CATEGORY]: gettext('other'),
};

export const EVENTBRITE_CATEGORY_TO_REFINEMENT = {
    [BUSINESS_EVENTBRITE_CATEGORY]: 'business',
    [SCIENCE_EVENTBRITE_CATEGORY]: 'science-and-tech',
    [MUSIC_EVENTBRITE_CATEGORY]: 'music',
    [FILM_EVENTBRITE_CATEGORY]: 'film-and-media',
    [PERFORMING_EVENTBRITE_CATEGORY]: 'arts',
    [FASHION_EVENTBRITE_CATEGORY]: 'fashion',
    [HEALTH_EVENTBRITE_CATEGORY]: 'health',
    [SPORTS_EVENTBRITE_CATEGORY]: 'sports-and-fitness',
    [TRAVEL_EVENTBRITE_CATEGORY]: 'travel-and-outdoor',
    [FOOD_AND_DRINK_EVENTBRITE_CATEGORY]: 'food-and-drink',
    [CHARITY_EVENTBRITE_CATEGORY]: 'charity-and-causes',
    [POLITICS_EVENTBRITE_CATEGORY]: 'government',
    [COMMUNITY_EVENTBRITE_CATEGORY]: 'community',
    [RELIGION_EVENTBRITE_CATEGORY]: 'spirituality',
    [FAMILY_EDUCATION_EVENTBRITE_CATEGORY]: 'family-and-education',
    [SEASONAL_EVENTBRITE_CATEGORY]: 'holiday',
    [HOME_LIFESTYLE_EVENTBRITE_CATEGORY]: 'home-and-lifestyle',
    [AUTO_BOAT_AIR_EVENTBRITE_CATEGORY]: 'auto-boat-and-air',
    [HOBBIES_EVENTBRITE_CATEGORY]: 'hobbies',
    [SCHOOL_EVENTBRITE_CATEGORY]: 'school-activities',
    [OTHER_EVENTBRITE_CATEGORY]: 'other',
};

/****************************** DATES MAPPING ****************************/

const TODAY = 'today';
const TOMORROW = 'tomorrow';
const THIS_MONTH = 'this_month';
const THIS_WEEK = 'this_week';
const THIS_WEEKEND = 'this_weekend';
const NEXT_WEEK = 'next_week';
const NEXT_MONTH = 'next_month';

export const DATE_TAG = 'date';

// only used as default value, DON'T use it on the dropdowns (don't add it to DATES_MAPPING)
export const CURRENT_FUTURE = 'current_future';

export const CUSTOM_DATE = 'custom_date';

export const DATES_MAPPING = {
    [TODAY]: gettext('Today'),
    [TOMORROW]: gettext('Tomorrow'),
    [THIS_WEEKEND]: gettext('This weekend'),
    [THIS_WEEK]: gettext('This week'),
    [NEXT_WEEK]: gettext('Next week'),
    [THIS_MONTH]: gettext('This month'),
    [NEXT_MONTH]: gettext('Next month'),
    [CUSTOM_DATE]: gettext('Pick a date...'),
};

// We want a different order for search with most popular filters at the top
export const DATES_MAPPING_SEARCH = {
    [CUSTOM_DATE]: DATES_MAPPING[CUSTOM_DATE],
    [TODAY]: DATES_MAPPING[TODAY],
    [TOMORROW]: DATES_MAPPING[TOMORROW],
    [THIS_WEEKEND]: DATES_MAPPING[THIS_WEEKEND],
    [THIS_WEEK]: DATES_MAPPING[THIS_WEEK],
    [NEXT_WEEK]: DATES_MAPPING[NEXT_WEEK],
    [THIS_MONTH]: DATES_MAPPING[THIS_MONTH],
    [NEXT_MONTH]: DATES_MAPPING[NEXT_MONTH],
};

export const DATES_UPPERCASE_MAPPING = {
    [TODAY]: gettext('Today'),
    [TOMORROW]: gettext('Tomorrow'),
    [THIS_WEEKEND]: gettext('This Weekend'),
    [THIS_WEEK]: gettext('This Week'),
    [NEXT_WEEK]: gettext('Next Week'),
    [THIS_MONTH]: gettext('This Month'),
    [NEXT_MONTH]: gettext('Next Month'),
    [CUSTOM_DATE]: gettext('Pick a date...'),
};

// Translators: these are going to be used as part of a sentence
export const DATES_LOWERCASE_MAPPING = {
    [TODAY]: gettext('today'),
    [TOMORROW]: gettext('tomorrow'),
    [THIS_WEEKEND]: gettext('this weekend'),
    [THIS_WEEK]: gettext('this week'),
    [NEXT_WEEK]: gettext('next week'),
    [THIS_MONTH]: gettext('this month'),
    [NEXT_MONTH]: gettext('next month'),
};

export const DATES_TO_REFINEMENT = {
    [TODAY]: 'today',
    [TOMORROW]: 'tomorrow',
    [THIS_WEEKEND]: 'this-weekend',
    [THIS_WEEK]: 'this-week',
    [NEXT_WEEK]: 'next-week',
    [THIS_MONTH]: 'this-month',
    [NEXT_MONTH]: 'next-month',
};

/****************************** FORMAT MAPPING ****************************/

//Eventbrite Format ID Mappings
const CONFERENCE = 1;
const SEMINAR = 2;
const EXPO = 3;
const CONVENTION = 4;
const FESTIVAL = 5;
const PERFORMANCE = 6;
const SCREENING = 7;
const GALA = 8;
const CLASS = 9;
const NETWORKING = 10;
const PARTY = 11;
const RALLY = 12;
const TOURNAMENT = 13;
const GAME = 14;
const RACE = 15;
const TOUR = 16;
const ATTRACTION = 17;
const RETREAT = 18;
const APPEARANCE = 19;

export const FORMAT_TAG_TYPE = 'Format';
const EVENTBRITE_FORMAT = 'EventbriteFormat';

export const CONFERENCE_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${CONFERENCE}`;
export const SEMINAR_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${SEMINAR}`;
export const EXPO_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${EXPO}`;
export const CONVENTION_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${CONVENTION}`;
export const FESTIVAL_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${FESTIVAL}`;
export const PERFORMANCE_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${PERFORMANCE}`;
export const SCREENING_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${SCREENING}`;
export const GALA_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${GALA}`;
export const CLASS_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${CLASS}`;
export const NETWORKING_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${NETWORKING}`;
export const PARTY_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${PARTY}`;
export const RALLY_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${RALLY}`;
export const TOURNAMENT_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${TOURNAMENT}`;
export const GAME_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${GAME}`;
export const RACE_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${RACE}`;
export const TOUR_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${TOUR}`;
export const ATTRACTION_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${ATTRACTION}`;
export const RETREAT_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${RETREAT}`;
export const APPEARANCE_EVENTBRITE_FORMAT = `${EVENTBRITE_FORMAT}/${APPEARANCE}`;

export const EVENTBRITE_FORMAT_MAPPING = {
    [CONFERENCE_EVENTBRITE_FORMAT]: gettext('Conference'),
    [SEMINAR_EVENTBRITE_FORMAT]: gettext('Seminar'),
    [EXPO_EVENTBRITE_FORMAT]: gettext('Expo'),
    [CONVENTION_EVENTBRITE_FORMAT]: gettext('Convention'),
    [FESTIVAL_EVENTBRITE_FORMAT]: gettext('Festival'),
    [PERFORMANCE_EVENTBRITE_FORMAT]: gettext('Performance'),
    [SCREENING_EVENTBRITE_FORMAT]: gettext('Screening'),
    [GALA_EVENTBRITE_FORMAT]: gettext('Gala'),
    [CLASS_EVENTBRITE_FORMAT]: gettext('Class'),
    [NETWORKING_EVENTBRITE_FORMAT]: gettext('Networking'),
    [PARTY_EVENTBRITE_FORMAT]: gettext('Party'),
    [RALLY_EVENTBRITE_FORMAT]: gettext('Rally'),
    [TOURNAMENT_EVENTBRITE_FORMAT]: gettext('Tournament'),
    [GAME_EVENTBRITE_FORMAT]: gettext('Game'),
    [RACE_EVENTBRITE_FORMAT]: gettext('Race'),
    [TOUR_EVENTBRITE_FORMAT]: gettext('Tour'),
    [ATTRACTION_EVENTBRITE_FORMAT]: gettext('Attraction'),
    [RETREAT_EVENTBRITE_FORMAT]: gettext('Retreat'),
    [APPEARANCE_EVENTBRITE_FORMAT]: gettext('Appearance'),
};

// We want a different order for search with most popular filters at the top
export const EVENTBRITE_FORMAT_MAPPING_SEARCH = {
    [CLASS_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[CLASS_EVENTBRITE_FORMAT],
    [CONFERENCE_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[CONFERENCE_EVENTBRITE_FORMAT],
    [FESTIVAL_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[FESTIVAL_EVENTBRITE_FORMAT],
    [PARTY_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[PARTY_EVENTBRITE_FORMAT],
    [APPEARANCE_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[APPEARANCE_EVENTBRITE_FORMAT],
    [ATTRACTION_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[ATTRACTION_EVENTBRITE_FORMAT],
    [CONVENTION_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[CONVENTION_EVENTBRITE_FORMAT],
    [EXPO_EVENTBRITE_FORMAT]: EVENTBRITE_FORMAT_MAPPING[EXPO_EVENTBRITE_FORMAT],
    [GALA_EVENTBRITE_FORMAT]: EVENTBRITE_FORMAT_MAPPING[GALA_EVENTBRITE_FORMAT],
    [GAME_EVENTBRITE_FORMAT]: EVENTBRITE_FORMAT_MAPPING[GAME_EVENTBRITE_FORMAT],
    [NETWORKING_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[NETWORKING_EVENTBRITE_FORMAT],
    [PERFORMANCE_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[PERFORMANCE_EVENTBRITE_FORMAT],
    [RACE_EVENTBRITE_FORMAT]: EVENTBRITE_FORMAT_MAPPING[RACE_EVENTBRITE_FORMAT],
    [RALLY_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[RALLY_EVENTBRITE_FORMAT],
    [RETREAT_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[RETREAT_EVENTBRITE_FORMAT],
    [SCREENING_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[SCREENING_EVENTBRITE_FORMAT],
    [SEMINAR_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[SEMINAR_EVENTBRITE_FORMAT],
    [TOURNAMENT_EVENTBRITE_FORMAT]:
        EVENTBRITE_FORMAT_MAPPING[TOURNAMENT_EVENTBRITE_FORMAT],
    [TOUR_EVENTBRITE_FORMAT]: EVENTBRITE_FORMAT_MAPPING[TOUR_EVENTBRITE_FORMAT],
};

export const EVENTBRITE_FORMAT_TO_REFINEMENT = {
    [CONFERENCE_EVENTBRITE_FORMAT]: 'conferences',
    [SEMINAR_EVENTBRITE_FORMAT]: 'seminars',
    [EXPO_EVENTBRITE_FORMAT]: 'expos',
    [CONVENTION_EVENTBRITE_FORMAT]: 'conventions',
    [FESTIVAL_EVENTBRITE_FORMAT]: 'festivals',
    [PERFORMANCE_EVENTBRITE_FORMAT]: 'performances',
    [SCREENING_EVENTBRITE_FORMAT]: 'screenings',
    [GALA_EVENTBRITE_FORMAT]: 'galas',
    [CLASS_EVENTBRITE_FORMAT]: 'classes',
    [NETWORKING_EVENTBRITE_FORMAT]: 'networking',
    [PARTY_EVENTBRITE_FORMAT]: 'parties',
    [RALLY_EVENTBRITE_FORMAT]: 'rallies',
    [TOURNAMENT_EVENTBRITE_FORMAT]: 'tournaments',
    [GAME_EVENTBRITE_FORMAT]: 'games',
    [RACE_EVENTBRITE_FORMAT]: 'races',
    [TOUR_EVENTBRITE_FORMAT]: 'tours',
    [ATTRACTION_EVENTBRITE_FORMAT]: 'attractions',
    [RETREAT_EVENTBRITE_FORMAT]: 'retreats',
    [APPEARANCE_EVENTBRITE_FORMAT]: 'appearances',
};

/****************************** PRICE MAPPING ****************************/

const FREE = 'free';
const PAID = 'paid';

export const PRICE_MAPPING = {
    [FREE]: gettext('Free'),
    [PAID]: gettext('Paid'),
};

/****************************** SORT MAPPING ****************************/
export const QUALITY = 'quality';

/****************************** COPY ********************************/

export const DEFAULT_BLURB_NO_BUCKETS = gettext(
    "Looks like we don't have any current events listed nearby. Try searching another city.",
);
export const DEFAULT_BLURB_NO_EVENTS = gettext(
    "Looks like we don't have any current events listed, let's see what's going on in nearby cities.",
);
export const getDefaultBlurb = (city) =>
    gettext(
        "Looking for something to do in %(city)s? Whether you're a local, new in town or just cruising through we've got loads of great tips and events. You can explore by location, what's popular, our top picks, free stuff... you got this. Ready?",
        { city },
    );

export const EVENT_SAVE = gettext('Event has been saved successfully.');
export const EVENT_SAVE_ERROR = gettext(
    'There was a problem saving the event, please try again.',
);

/****************************** Utils Constants ********************************/

export const CITY_CUTOFF = 6;
export const CITY_NAME_SHORT_CUT = 2;
export const CITY_NAME_DEFAULT_CUT = 4;
export const CITY_GRADIENTS = ['eds-bg-color--ui-orange'];
export const SHORT_STATES = {
    alabama: 'AL',
    arizona: 'AZ',
    arkansas: 'AR',
    california: 'CA',
    colorado: 'CO',
    connecticut: 'CT',
    delaware: 'DE',
    'district of columbia': 'DC',
    florida: 'FL',
    georgia: 'GA',
    idaho: 'ID',
    illinois: 'IL',
    indiana: 'IN',
    iowa: 'IA',
    kansas: 'KS',
    kentucky: 'KY',
    louisiana: 'LA',
    maine: 'ME',
    maryland: 'MD',
    massachusetts: 'MA',
    michigan: 'MI',
    minnesota: 'MN',
    mississippi: 'MS',
    missouri: 'MO',
    montana: 'MT',
    nebraska: 'NE',
    nevada: 'NV',
    'new hampshire': 'NH',
    'new jersey': 'NJ',
    'new mexico': 'NM',
    'new york': 'NY',
    'north carolina': 'NC',
    'north dakota': 'ND',
    ohio: 'OH',
    oklahoma: 'OK',
    oregon: 'OR',
    pennsylvania: 'PA',
    'rhode island': 'RI',
    'south carolina': 'SC',
    'south dakota': 'SD',
    tennessee: 'TN',
    texas: 'TX',
    utah: 'UT',
    vermont: 'VT',
    virginia: 'VA',
    washington: 'WA',
    'west virginia': 'WV',
    wisconsin: 'WI',
    wyoming: 'WY',
};

/****************************** Autocomplete History Constants ********************************/

export const HISTORY_SUGGESTIONS_SHOWN = 5;

/****************************** COOKIES CONSTANTS ********************************/

export const LOCATION_COOKIE = 'location';
export const EVENT_COOKIE = 'ebEventId';
export const LEGAL_UPDATE_DISMISSED_COOKIE = 'dismissedLegalUpdate2019';
export const LEGAL_UPDATE_SEEN_COOKIE = 'seenLegalUpdate2019';

/****************************** GA ******************************/

export const GA_USER_CURRENT_LOCATION_ACTION = 'UseCurrentLocation';
export const GA_USER_CURRENT_LOCATION_DENIED_LABEL = 'access denied';
export const GA_USER_CURRENT_LOCATION_ERROR_LABEL = 'location error';

export const VIEW_EVENT_GA_ACTION = 'ViewEvent';
export const SHARE_GA_ACTION = 'Share';
export const SHARE_ATTEMPT_GA_ACTION = 'ShareAttempt';
export const BOOKMARK_GA_ACTION = 'Bookmark';
export const UNBOOKMARK_GA_ACTION = 'Unbookmark';

const GA_TWITTER_IDENTIFIER = 'tw';
const GA_FACEBOOK_IDENTIFIER = 'fb';
const GA_FACEBOOK_MESSENGER_IDENTIFIER = 'fbm';
const GA_EMAIL_IDENTIFIER = 'em';
const GA_COPY_IDENTIFIER = 'cp';
const SHARE_BY_COPY = 'copy';

export const SHARE_TYPE_GA_MAP = {
    [SHARE_ON_EMAIL]: GA_EMAIL_IDENTIFIER,
    [SHARE_ON_FACEBOOK]: GA_FACEBOOK_IDENTIFIER,
    [SHARE_ON_FACEBOOK_MESSENGER]: GA_FACEBOOK_MESSENGER_IDENTIFIER,
    [SHARE_ON_TWITTER]: GA_TWITTER_IDENTIFIER,
    [SHARE_BY_COPY]: GA_COPY_IDENTIFIER,
};
export const ONLINE_EVENTS_LOCATION_SLUG = 'online';
export const BROWSING_ONLINE_EVENTS_TEXT = gettext('Online Events');

//  This constants are used to track the different type of suggestions listed on the global header
export const SUGGESTION_TYPE_HISTORY = 'recent';
export const SUGGESTION_TYPE_POPULAR = 'popular';
