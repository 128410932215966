import { DEFAULT_LOGO_SIZE } from '@eventbrite/eds-global-header';
import { Icon } from '@eventbrite/eds-icon';
import { LogoEBrand, LogoWordmarkBrand } from '@eventbrite/eds-iconography';
import classNames from 'classnames';
import React from 'react';
import styles from './GlobalHeaderLogo.module.scss';

const DEFAULT_LOGO_TYPE = <LogoWordmarkBrand />;
const DEFAULT_LOGO_COLOR = 'ui-orange';
const DEFAULT_LOGO_TITLE = 'Eventbrite';

const GlobalHeaderLogo = ({
    info,
}: {
    info: {
        url?: string;
        iconType: string;
        iconColor: string;
        iconTitle: string;
        iconWidth: number;
        iconHeight: number;
        backgroundColor?: string;
        shouldUseSpecialWidth?: boolean;
        linkProps: any;
    };
}) => {
    const {
        url,
        iconType = DEFAULT_LOGO_TYPE,
        iconColor = DEFAULT_LOGO_COLOR,
        iconTitle = DEFAULT_LOGO_TITLE,
        iconWidth = DEFAULT_LOGO_SIZE.width,
        iconHeight = DEFAULT_LOGO_SIZE.height,
        backgroundColor,
        shouldUseSpecialWidth,
        ...linkProps
    } = info;
    const className = classNames('authenticated-header__logo-link', {
        [`eds-bg-color--${backgroundColor}`]: backgroundColor,
        'authenticated-header--logo-special-width': !!shouldUseSpecialWidth,
    });

    return (
        <a
            href={url}
            className={className}
            data-spec="authenticated-header-logo-link"
            {...linkProps}
        >
            <div className={styles.desktopLogo}>
                <Icon
                    type={iconType}
                    color={iconColor}
                    title={iconTitle}
                    isBlockLevel={true}
                    height={iconHeight}
                    width={iconWidth}
                    data-spec="authenticated-header-logo"
                />
            </div>

            <div className={styles.mobileLogo}>
                <Icon
                    type={<LogoEBrand />}
                    color={iconColor}
                    title={iconTitle}
                    isBlockLevel={true}
                    height={iconHeight}
                    data-spec="authenticated-header-logo"
                />
            </div>
        </a>
    );
};

export default GlobalHeaderLogo;
