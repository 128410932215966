import * as React from 'react';

const ArrowLeftSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className="arrow-left_svg__eds-icon--left-arrow_svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            className="arrow-left_svg__eds-icon--left-arrow_base"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M4 12l.2.2L9.8 18l.8-.7-4.7-4.8H20v-1H5.9l4.7-4.8-.7-.7z"
        />
    </svg>
);

ArrowLeftSvg.displayName = 'ArrowLeftSvg';
export default ArrowLeftSvg;
