import { gettext } from '@eventbrite/i18n';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconButton } from '@eventbrite/eds-icon-button';
import {
    DIRECTION_LEFT,
    DIRECTION_RIGHT,
    BOTTOM_NAVIGATION_TYPE,
    TYPE_NUMBER,
} from '../constants';
import { ArrowLeftChunky } from '@eventbrite/eds-iconography';
import { ArrowRightChunky } from '@eventbrite/eds-iconography';
import { ChevronLeftChunky } from '@eventbrite/eds-iconography';
import { ChevronRightChunky } from '@eventbrite/eds-iconography';

export default class SideNavigation extends PureComponent {
    static propTypes = {
        bottomNavigationType: PropTypes.oneOf(BOTTOM_NAVIGATION_TYPE),
        type: PropTypes.oneOf([DIRECTION_LEFT, DIRECTION_RIGHT]).isRequired,
        onClick: PropTypes.func.isRequired,
        shouldShow: PropTypes.bool,
        fullWidth: PropTypes.bool,
        isDisabled: PropTypes.bool,
        usesNewStyle: PropTypes.bool,
    };

    render() {
        const {
            type,
            onClick,
            fullWidth,
            shouldShow,
            bottomNavigationType,
            isDisabled,
            usesNewStyle,
        } = this.props;

        if (!shouldShow && !fullWidth) {
            return null;
        }

        const className = classNames('eds-carousel__side-navigation', {
            'eds-carousel__side-navigation--full-width-hidden':
                !shouldShow && fullWidth,
            [`eds-carousel__side-navigation--full-width-${type}`]:
                fullWidth && bottomNavigationType !== TYPE_NUMBER,
        });
        const title = gettext('%(direction)s navigation', { direction: type });
        let iconType =
            type === DIRECTION_LEFT ? (
                <ChevronLeftChunky />
            ) : (
                <ChevronRightChunky />
            );

        if (usesNewStyle) {
            iconType =
                type === DIRECTION_LEFT ? (
                    <ArrowLeftChunky />
                ) : (
                    <ArrowRightChunky />
                );
        }

        return (
            <div className={className}>
                <IconButton
                    iconType={iconType}
                    isActive={fullWidth}
                    title={title}
                    onClick={onClick}
                    disabled={isDisabled}
                />
            </div>
        );
    }
}
