import * as React from 'react';

const ScreenLargeSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="screen-large_svg__eds-icon--screen-large_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="screen-large_svg__eds-icon--screen-large_base"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M3 18h18v-2H3v2zm0-3h18V8H3v7zm0-8h18V4H3v3zM2 3v16h9.5v2H9v1h6v-1h-2.5v-2H22V3H2z"
        />
        <path
            id="screen-large_svg__eds-icon--screen-large_dash"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M11 5h2v1h-2z"
        />
    </svg>
);

ScreenLargeSvg.displayName = 'ScreenLargeSvg';
export default ScreenLargeSvg;
