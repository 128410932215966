import { gettext } from '@eventbrite/i18n';
import {
    ShareCollectionProps,
    ShareInline,
    ShareModal,
} from '@eventbrite/social-share';
import { keysCamelToSnake } from '@eventbrite/transformation-utils';
import React from 'react';
import { formatUrl } from 'url-lib';
import { AffiliateCodes } from '../../types';

type Props = ShareCollectionProps & {
    container: React.MutableRefObject<HTMLDivElement | null>;
};

export const ShareCollectionModal: React.FC<Props> = (props) => {
    const {
        collectionName,
        collectionUrl,
        container,
        isMobile,
        trackingCategory,
    } = props;

    const utmOptions = {
        'utm-campaign': 'social',
        'utm-content': 'attendeeshare',
        'utm-medium': 'discovery',
        'utm-term': AffiliateCodes.SocialShareToolsInCollection,
    };

    const dataWebShareApi = {
        text: collectionName,
        title: collectionName,
        url: formatUrl(
            collectionUrl,
            keysCamelToSnake({
                ...utmOptions,
                'utm-source': 'wsa',
                aff: AffiliateCodes.SocialShareToolsInCollection,
            }),
        ),
    };
    const urlCopyField = formatUrl(
        collectionUrl,
        keysCamelToSnake({
            ...utmOptions,
            'utm-source': 'cp',
            aff: AffiliateCodes.SocialShareToolsInCollection,
        }),
    );
    return (
        <ShareModal
            dataWebShareApi={dataWebShareApi}
            title={gettext('Share with friends').toString()}
            inputLabel={gettext('Collection link').toString()}
            url={urlCopyField}
            container={container}
            tooltipTitle={gettext('Share this Collection')}
            tooltipText={gettext('Share collection')}
            trackingCategory={trackingCategory}
            isMobile={isMobile}
            useWebShareApi={true}
        >
            <ShareInline {...props} utmOptions={utmOptions} />
        </ShareModal>
    );
};

export const ShareCollectionInline = ShareInline;
