import { LogoEBrand, LogoWordmarkBrand } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import React, { createContext, useContext } from 'react';
import { GlobalNavContextProps, GlobalNavProps } from '../modules/types';
import { getDefaultLocale } from '../utils';
import { defaultNavigation } from './DefaultNavigation';

export const defaultGlobalNavProps: Omit<GlobalNavProps, 'user' | 'env'> = {
    positionSticky: { mobile: false, desktop: false },
    searchBar: {
        renderSearchBar: true,
        desktopLabel: gettext('Search events'),
        mobileLabel: gettext('Search'),
    },
    logo: {
        path: '/',
        ariaLabel: gettext('Home'),
        desktopLogo: {
            iconType: <LogoWordmarkBrand />,
            iconWidth: '110px',
            iconHeight: 'auto',
            backgroundColor: 'transparent',
            iconTitle: 'Eventbrite',
            iconColor: 'ui-orange',
        },
        mobileLogo: {
            iconType: <LogoEBrand />,
            iconWidth: '24px',
            iconHeight: '24px',
            backgroundColor: 'transparent',
            iconTitle: 'Eventbrite',
            iconColor: 'ui-orange',
        },
    },
};

// Create a context for GlobalNav
const GlobalNavContext = createContext<GlobalNavContextProps | undefined>(
    undefined,
);

export const GlobalNavProvider: React.FC<GlobalNavContextProps> = ({
    children,
    ...props
}) => {
    const mergedProps: GlobalNavProps = {
        ...defaultGlobalNavProps,
        user: undefined,
        env: {},
    };

    let key: keyof GlobalNavProps;

    for (key in props) {
        if (props[key] !== undefined) {
            mergedProps[key] = props[key];
        }
    }

    const { localeInfo } = mergedProps?.env;
    const defaultLanguage = getDefaultLocale(
        localeInfo?.default_language ?? 'en-us',
    );
    const tld = localeInfo?.tld ?? '.com';
    mergedProps['navigation'] =
        props?.navigation || defaultNavigation({ defaultLanguage, tld });

    return (
        <GlobalNavContext.Provider value={mergedProps}>
            {children}
        </GlobalNavContext.Provider>
    );
};

export const useGlobalNavContext = () => {
    const globalNavContext = useContext(GlobalNavContext);
    if (!globalNavContext) {
        throw new Error(
            'useGlobalNavContext must be used within a GlobalNavProvider',
        );
    }
    return globalNavContext;
};
