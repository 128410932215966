import * as React from 'react';

const CloudAbstractSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={255} height={159} fill="none" {...props}>
        <path
            d="M114.286-24.712C65.106-22.598 11.371-12.518-4.307 13.35c-9.302 15.366 14.637 23.26 1.692 34.115C-22.522 64.172-57 64.666-57 96.173c0 60.266 282.072 78.311 306.272 49.2 28.428-34.257-58.158-43.209-59.069-57.095-.975-13.886 54.645-37.287 50.417-64.425-4.229-27.137-50.677-51.808-126.334-48.565z"
            fill="#fff"
            fillOpacity={0.15}
        />
    </svg>
);

CloudAbstractSvg.displayName = 'CloudAbstractSvg';
export default CloudAbstractSvg;
