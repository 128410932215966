import { Icon } from '@eventbrite/eds-icon';
import { ArrowLeft, LockChunky } from '@eventbrite/eds-iconography';
import { useFeatureFlags } from '@eventbrite/feature-flag-utils';
import { gettext } from '@eventbrite/i18n';
import React, { useContext } from 'react';
import { AnalyticsContext, ApplicationContext } from '../context';
import { AnalyticsAction, AnalyticsCategory, trackEvent } from '../_shared';
import { CopyUrlButton } from './CopyUrlButton';

export const OrganizerUiBanner: React.FC = () => {
    const context = useContext(ApplicationContext);
    const { collectionId } = useContext(AnalyticsContext);
    const { launchPrivateCollections } = useFeatureFlags();

    const justPublished = context?.request.params.just_published || false;
    const isCollectionPrivate = context?.collection.is_private;
    const promoteLink = `/organizations/marketing/boost-collection?collection_id=${context?.collection.id}&utm_source=collection-promote-cta&source=organizer-bottom-actions`;

    const onPromoteCollectionClick = (position: string) => {
        trackEvent({
            category: AnalyticsCategory.Listing,
            action: AnalyticsAction.CollectionPromote,
            label: position,
        });
    };

    return (
        <>
            <div
                className="cc-organizer-banner"
                role="banner"
                aria-labelledby="banner-text"
            >
                {collectionId && (
                    <div className="cc-organizer-banner__container eds-show-up-md">
                        <a
                            href={`/manage/collections/${collectionId}/publish`}
                            className="eds-text-bm eds-text-weight--heavy eds-text-color--control cc-organizer-banner__link"
                            onClick={() => {
                                trackEvent({
                                    action: AnalyticsAction.EditCollection,
                                    label: 'organizer-banner',
                                    category: AnalyticsCategory.Listing,
                                });
                            }}
                        >
                            <Icon
                                arial-label="hidden"
                                color="ui-blue--hover"
                                size="xsmall"
                                type={<ArrowLeft />}
                            />
                            <span className="eds-l-mar-left-3">
                                {gettext('Edit this collection')}
                            </span>
                        </a>
                    </div>
                )}
                <div className="cc-organizer-banner__container mid-container-content-alignment">
                    {launchPrivateCollections && isCollectionPrivate && (
                        <div
                            data-testid="collection-private-badge"
                            className="cc-organizer-banner__private-tag"
                        >
                            <Icon
                                color="ui-700"
                                size="small"
                                type={<LockChunky />}
                            />
                            <span className="cc-organizer-banner__text ">
                                {gettext('Private')}
                            </span>
                        </div>
                    )}
                </div>
                <div className="cc-organizer-banner__container">
                    <div className="cc-organizer-banner__buttons-wrapper">
                        <a
                            className="cc-organizer-banner__text"
                            href={promoteLink}
                            onClick={() =>
                                onPromoteCollectionClick('bottom-actions')
                            }
                        >
                            {gettext('Promote this collection')}
                        </a>
                        <CopyUrlButton />
                    </div>
                </div>
            </div>
            {justPublished && (
                <div
                    className="cc-just-published-notification"
                    data-testid="just-published-notification"
                >
                    <p>
                        {gettext(
                            "Congratulations! You've published your collection. 🎉",
                        )}
                    </p>
                </div>
            )}
        </>
    );
};
