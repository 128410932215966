import { gettext } from '@eventbrite/i18n';

export const MORE_COLLECTIONS_FROM_THIS_ORGANIZER_TITLE = gettext(
    'More collections from this organizer',
);
export const OTHER_EVENTS_YOU_MAY_LIKE_TITLE = gettext(
    'Other events you may like',
);

export const AUTOCREATED_COLLECTION_DEFAULT_IMAGE_URL =
    'https://cdn.evbstatic.com/s3-build/perm_001/9d9878/django/images/creator_collections/autocreated-collection-default.png';

export const defaultAutocreatedCollectionName = gettext(
    'Your first collection',
).toString();

export const defaultAutocreatedCollectionSummary = gettext(
    "Edit this description to explain the theme of your collection. Don't be shy, have fun!",
).toString();

export const defaultAutocreatedCollectionPillTitle = gettext(
    "Let's publish your first collection",
).toString();
