import { CoreEnv } from '@eventbrite/context-gen';
import { Layout } from '@eventbrite/eds-layout';
import { gettext } from '@eventbrite/i18n';
import { ShareCollectionProps } from '@eventbrite/social-share';
import React from 'react';
import { CardsCarousel, CardsType } from '../CardsCarousel/CardsCarousel';
import { CollectionEvents } from '../CollectionEvents';
import { CollectionHero } from '../components/CollectionHero/CollectionHero';
import {
    OrganizerPanel,
    OrganizerPanelProps,
} from '../components/OrganizerPanel/OrganizerPanel';
import {
    ShareCollectionInline,
    ShareCollectionModal,
} from '../components/Share/ShareCollection';
import {
    AffiliateCodes,
    CollectionCardOriginType,
    MoreCollectionsFromThisOrganizer,
    OtherEventsYouMayLike,
} from '../types';
import {
    MORE_COLLECTIONS_FROM_THIS_ORGANIZER_TITLE,
    OTHER_EVENTS_YOU_MAY_LIKE_TITLE,
} from '../_shared/constants';
import { CollectionDetails, CollectionDetailsProps } from './';

interface Props {
    collectionDetailsProps: CollectionDetailsProps;
    organizerPanelProps: OrganizerPanelProps;
    collectionShareProps: ShareCollectionProps;
    moreCollectionsFromThisOrganizerProps?: MoreCollectionsFromThisOrganizer;
    otherEventsYouMayLikeProps?: OtherEventsYouMayLike;
    env: CoreEnv;
}

const filterCollectionCardOwner = (
    collections: CollectionCardOriginType[] | undefined,
    collectionId: string,
) => collections?.filter((collection) => collection.id !== collectionId);

export const Collection: React.FunctionComponent<Props> = ({
    collectionDetailsProps,
    organizerPanelProps,
    collectionShareProps,
    moreCollectionsFromThisOrganizerProps,
    otherEventsYouMayLikeProps,
    env,
}) => {
    const modalContainer: React.MutableRefObject<HTMLDivElement | null> =
        React.useRef(null);
    const moreCollectionFromThisOrganizer = filterCollectionCardOwner(
        moreCollectionsFromThisOrganizerProps?.event_groups,
        collectionShareProps.collectionId,
    );
    const otherEventsYouMayLike = otherEventsYouMayLikeProps?.events;

    return (
        <>
            <CollectionHero>
                <ShareCollectionModal
                    container={modalContainer}
                    {...collectionShareProps}
                />
            </CollectionHero>

            <Layout maxWidth="large" hasHorizontalGutters={false}>
                <div className="cc-main-wrapper">
                    <CollectionDetails {...collectionDetailsProps} />

                    <CollectionEvents env={env} />

                    <div className="eds-l-mar-bot-10">
                        <h2 className="eds-text-hm eds-l-mar-bot-4 cc-heading">
                            {gettext('Share')}
                        </h2>
                        <ShareCollectionInline {...collectionShareProps} />
                    </div>

                    {/* We Should replace the next code by the <Hr /> EDS component */}
                    <hr
                        className="eds-divider__hr eds-bg-color--grey-200 eds-divider--horizontal"
                        aria-hidden="true"
                    />
                    <OrganizerPanel {...organizerPanelProps} />
                </div>
            </Layout>

            {moreCollectionFromThisOrganizer?.length ? (
                <Layout maxWidth="large" hasHorizontalGutters={true}>
                    <div className="cc-outer-wrapper">
                        <CardsCarousel
                            cards={moreCollectionFromThisOrganizer}
                            cardsType={CardsType.COLLECTION}
                            title={MORE_COLLECTIONS_FROM_THIS_ORGANIZER_TITLE}
                            newTitleStyle={true}
                            affCode={
                                AffiliateCodes.RelatedCollectionsFromOrganizer
                            }
                            dataTestId="mcfto-carousel"
                        />
                    </div>
                </Layout>
            ) : null}

            {otherEventsYouMayLike?.length ? (
                <Layout maxWidth="large" hasHorizontalGutters={true}>
                    <div className="cc-outer-wrapper">
                        <CardsCarousel
                            cards={otherEventsYouMayLike}
                            cardsType={CardsType.EVENT}
                            title={OTHER_EVENTS_YOU_MAY_LIKE_TITLE}
                            newTitleStyle={true}
                            affCode={
                                AffiliateCodes.RelatedEventsOtherOrganizers
                            }
                            isFromDestination={true}
                            dataTestId="oeyml-carousel"
                        />
                    </div>
                </Layout>
            ) : null}

            <div id="share-modal-wrapper" ref={modalContainer}></div>
        </>
    );
};
