import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { v4 as UUID } from 'uuid';
import { DesktopDropdown, NavLink } from '../../../common';
import { NavItemType } from '../../../types';
import styles from './NavItemList.module.scss';
export interface NavItemListProps {
    navItems?: NavItemType[];
    serverUrl: string;
    signinUrl: string;
    signupUrl: string;
}

const NavItemList: React.FC<NavItemListProps> = ({
    navItems,
    serverUrl,
    signinUrl,
    signupUrl,
}) => {
    return (
        <ul className={styles.main}>
            {navItems?.map((item) => (
                <li key={UUID()} className={styles.list}>
                    {'path' in item ? (
                        <NavLink
                            path={new URL(item.path, serverUrl ?? '').href}
                            heapIdentifier={item.heapIdentifier}
                            translatedText={item.translatedText}
                            textColor={item.textColor}
                            iconType={item.iconType}
                            iconPosition={item.iconPosition}
                            className={item.className}
                        />
                    ) : (
                        <DesktopDropdown
                            dropdownItems={item.dropdownItems}
                            translatedText={item.translatedText}
                            heapIdentifier={item.heapIdentifier}
                        />
                    )}
                </li>
            ))}
            {/* Auth Links */}
            <li className={styles.list}>
                <NavLink
                    path={signinUrl}
                    heapIdentifier={'login'}
                    translatedText={gettext('Log In')}
                    className={styles.login}
                />
            </li>
            <li className={styles.list}>
                <NavLink
                    path={signupUrl}
                    heapIdentifier={'signup'}
                    translatedText={gettext('Sign Up')}
                    className={styles.signup}
                />
            </li>
        </ul>
    );
};

export default NavItemList;
