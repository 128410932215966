import { getFeedbackScript } from './getFeedback';

export type FXSurveyProps = {
    email: string;
    organizationId: string;
    isProductionEnv: boolean;
};

export const displayFXSurveyIntercept = ({
    email,
    organizationId,
    isProductionEnv,
}: FXSurveyProps) => {
    getFeedbackScript({ email, organizationId, isProductionEnv });
};
