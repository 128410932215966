import { gettext } from '@eventbrite/i18n';
import { Typography, TypographyProps } from '@eventbrite/marmalade';
import classNames from 'classnames';
import React, { useState } from 'react';

import { Icon } from '@eventbrite/eds-icon';
import { HelpChunky } from '@eventbrite/eds-iconography';
import { withTooltip } from '@eventbrite/eds-tooltip';

import styles from './EventCardPromoted.module.scss';
import { PromotedUpsellModal } from './PromotedUpsellModal';

interface EventCardPromotedProps extends Omit<TypographyProps, 'children'> {
    isPromoted?: boolean;
    label?: string;
    withUpsellModal?: boolean;
}

const IconWithTooltip = withTooltip(Icon);

const EventCardPromotedBase: React.FunctionComponent<
    EventCardPromotedProps
> = ({
    isPromoted,
    className,
    withUpsellModal,
    label = gettext('Promoted').toString(),
    ...typographyProps
}) => {
    const [showModal, setShowModal] = useState(false);
    const openModal = () => setShowModal(true);

    if (!isPromoted) {
        return null;
    }

    return (
        <>
            <div className={classNames(className, styles.root)}>
                <Typography
                    variant="body-sm"
                    color="neutral-600"
                    {...typographyProps}
                    className={classNames(
                        withUpsellModal && styles.withUpsellModal,
                    )}
                    onClick={withUpsellModal ? openModal : undefined}
                    role={withUpsellModal ? 'button' : undefined}
                >
                    {label}
                </Typography>
                <IconWithTooltip
                    type={<HelpChunky fill="#6f7287" />}
                    size="xsmall"
                    tooltipId="tooltip-info"
                    tooltipStyle="phab"
                    wrapperComponent="span"
                    tooltipText={
                        <Typography variant="body-sm">
                            {gettext(
                                'Business owners and event organizers paid for these ads.',
                            )}
                        </Typography>
                    }
                    tooltipArrowLocation="left"
                />
            </div>
            {showModal && (
                <PromotedUpsellModal handleClose={() => setShowModal(false)} />
            )}
        </>
    );
};

//Memoize result to prevent needing to re-render
//withTooltip every instance unless necessary.
export const EventCardPromoted = React.memo(
    EventCardPromotedBase,
    (prevProps, nextProps) => prevProps.isPromoted === nextProps.isPromoted,
);
