export const AR_LOCALE = 'es_AR';
export const AT_LOCALE = 'de_AT';
export const AU_LOCALE = 'en_AU';
export const BE_LOCALE = 'nl_BE';
export const BR_LOCALE = 'pt_BR';
export const CA_LOCALE = 'en_CA';
export const DE_CH_LOCALE = 'de_CH';
export const FR_CH_LOCALE = 'fr_CH';
export const CL_LOCALE = 'es_CL';
export const CO_LOCALE = 'es_CO';
export const DE_LOCALE = 'de_DE';
export const DK_LOCALE = 'en_DK';
export const ES_LOCALE = 'es_ES';
export const FI_LOCALE = 'en_FI';
export const FR_BE_LOCALE = 'fr_BE';
export const FR_CA_LOCALE = 'fr_CA';
export const FR_LOCALE = 'fr_FR';
export const GB_LOCALE = 'en_GB';
export const HI_IN_LOCALE = 'hi_IN';
export const HK_LOCALE = 'en_HK';
export const IE_LOCALE = 'en_IE';
export const IN_LOCALE = 'en_IN';
export const IT_LOCALE = 'it_IT';
export const MY_LOCALE = 'en_MY';
export const MX_LOCALE = 'es_MX';
export const NO_LOCALE = 'nn_NO';
export const PE_LOCALE = 'es_PE';
export const NL_LOCALE = 'nl_NL';
export const NZ_LOCALE = 'en_NZ';
export const PT_LOCALE = 'pt_PT';
export const SE_LOCALE = 'en_SE';
export const SG_LOCALE = 'en_SG';
export const US_LOCALE = 'en_US';
export const ES_US_LOCALE = 'es_US';

export const COUNTRY_DEFAULT_LOCALE_MAP = {
    AR: 'es_AR',
    AT: 'de_AT',
    AU: 'en_AU',
    BE: 'nl_BE',
    BR: 'pt_BR',
    CA: 'en_CA',
    CH: 'de_DE',
    CL: 'es_CL',
    CO: 'es_CO',
    DE: 'de_DE',
    DK: 'en_DK',
    ES: 'es_ES',
    FI: 'en_FI',
    FR: 'fr_FR',
    GB: 'en_GB',
    HI: 'hi_IN',
    HK: 'en_HK',
    IE: 'en_IE',
    IN: 'en_IN',
    IT: 'it_IT',
    MX: 'es_MX',
    MY: 'en_MY',
    NL: 'nl_NL',
    NO: 'nn_NO',
    NZ: 'en_NZ',
    PE: 'es_PE',
    PT: 'pt_PT',
    SE: 'sv',
    SG: 'en_SG',
    UK: 'en_GB',
    US: 'en_US',
};

/**
 * Returns the default locale for a given country code
 *
 * @param  {string} countryCode e.g. US, GB, etc.
 * @return {string} defaultValue if locale is not supported
 */
export const getDefaultLocale = (countryCode, defaultValue) => {
    return COUNTRY_DEFAULT_LOCALE_MAP[countryCode] || defaultValue;
};
