import omit from 'lodash/omit';
import PropTypes from 'prop-types';

export const AUTO_TRANSITION_INTERVAL = 5000;
export const TRANSITION_TRIGGER_THRESHOLD = 100;
export const ANIMATION_DELAY = 500;
export const TOUCH_DISTANCE_DIVIDER = 3;
export const DIRECTION_RIGHT = 'right';
export const DIRECTION_LEFT = 'left';
export const MOBILE = 'mobile';
export const ANIMATION_MAP = {
    [DIRECTION_LEFT]: 'eds-base-carousel__slide--exit-left',
    [DIRECTION_RIGHT]: 'eds-base-carousel__slide--exit-right',
    'eds-base-carousel__slide--exit-right':
        'eds-base-carousel__slide--enter-right',
    'eds-base-carousel__slide--exit-left':
        'eds-base-carousel__slide--enter-left',
    [MOBILE]: 'eds-base-carousel__slide--mobile',
};
export const TYPE_ICON = 'icons';
export const TYPE_NUMBER = 'numbers';
export const BOTTOM_NAVIGATION_TYPE = [TYPE_ICON, TYPE_NUMBER];

export const CUSTOM_NAVIGATION_PROP_TYPE = PropTypes.arrayOf(
    PropTypes.shape({
        base: PropTypes.node.isRequired,
        active: PropTypes.node,
    }),
);

export const BASE_CAROUSEL_PROPTYPES = {
    /**
     * Children to render as slides inside carousel
     */
    children: PropTypes.node.isRequired,
    /**
     * Items to show per slide
     */
    itemsPerSlide: PropTypes.number,
    /**
     * Display index passed from parent carousel
     */
    inheritedDisplayIndex: PropTypes.number,
    /**
     * Initial index of item to display
     */
    initialDisplayIndex: PropTypes.number,
    /**
     * Whether or not carousel should take up full width
     */
    fullWidth: PropTypes.bool,
    /**
     * The transition duration, in milliseconds.
     */
    transitionDuration: PropTypes.number,
    /**
     * Autoplay slides
     */
    autoTransition: PropTypes.bool,
    /**
     * Callback to trigger when a new slide is shown
     */
    onTransition: PropTypes.func,
    /**
     * Callback to trigger when client swipes right
     */
    onSwipeRight: PropTypes.func,
    /**
     * Callback to trigger when client swipes left
     */
    onSwipeLeft: PropTypes.func,
    /**
     * Callback to trigger when a new slide is shown
     */
    customNavigation: CUSTOM_NAVIGATION_PROP_TYPE,
    /**
     * Whether or not to show navigation at the bottom
     */
    showNavigation: PropTypes.bool,
    /**
     * Left side navigation item inherited from parent
     */
    leftNavigation: PropTypes.node,
    /**
     * Right side navigation item inherited from parent
     */
    rightNavigation: PropTypes.node,
    /**
     * Select bottom navigation style. Select 'numbers' to show a numbered counter
     * of which photo is displayed eg. 2/6.
     * Navigation arrows will be displayed alongside the counter at the bottom of the Carousel.
     * Select 'icons' to show a horizontal customizable icon list below the Carousel to navigate
     * between slides, as well as side arrow navigation at larger breakpoints.
     */
    bottomNavigationType: PropTypes.oneOf(BOTTOM_NAVIGATION_TYPE),
    /**
     * Ensure Carousel has a 16:9 aspect ratio
     */
    fixAspectRatio: PropTypes.bool,

    /**
     * The carousel does not go back to the start after the last element.
     */
    isNotContinuous: PropTypes.bool,
};
export const BASE_CAROUSEL_PROPS = Object.keys(BASE_CAROUSEL_PROPTYPES);

export const FULL_CAROUSEL_PROP_TYPES = {
    ...omit(
        BASE_CAROUSEL_PROPTYPES,
        'showNavigation',
        'leftNavigation',
        'rightNavigation',
    ),
    /**
     * Show side navigation (arrows)
     */
    initialShowSideNavigation: PropTypes.bool,
    /**
     * Auto play mode
     */
    initialAutoTransition: PropTypes.bool,
    /**
     * Show bottom navigation
     */
    showBottomNavigation: PropTypes.bool,
    /**
     * Enables the new style for the carousel.
     */
    usesNewStyle: PropTypes.bool,
    /**
     * Enables the new style for the carousel title.
     */
    newTitleStyle: PropTypes.bool,
};
export const FULL_CAROUSEL_PROPS = Object.keys(FULL_CAROUSEL_PROP_TYPES);

export const CAROUSEL_PROP_TYPES = {
    ...omit(
        FULL_CAROUSEL_PROP_TYPES,
        'initialShowSideNavigation',
        'initialAutoTransition',
        'showBottomNavigation',
    ),
    /**
     * Autoplay slides
     */
    autoTransition: PropTypes.bool,
    /**
     * Show main navigation besides hover state
     */
    alwaysShowSideNavigation: PropTypes.bool,
    /**
     * Show bottom navigation for all screen sizes
     */
    alwaysShowBottomNavigation: PropTypes.bool,
    /**
     * The navigation is placed in top of the carousel.
     */
    hasTopControls: PropTypes.bool,
    /**
     * Enables the new style for the carousel.
     */
    usesNewStyle: PropTypes.bool,
};
