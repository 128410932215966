import { CoreEnv } from '@eventbrite/context-gen';
import { FeatureFlagsProvider } from '@eventbrite/feature-flag-utils';
import { initializeRealUserMonitoring } from '@eventbrite/real-user-monitoring';
import { ShareProps } from '@eventbrite/social-share';
import React, { useEffect } from 'react';
import { Collection, CollectionError } from './Collection';
import { AnalyticsContext, ApplicationContext } from './context';
import { displayFXSurveyIntercept } from './fxSurvey';
import { OrganizerUiBanner } from './OrganizerUi';
import { PageLayout } from './PageLayout';
import './styles/main.scss';
import {
    Collection as CollectionType,
    CollectionMovedServerData,
    CollectionServerData,
    MoreCollectionsFromThisOrganizer,
    OtherEventsYouMayLike,
} from './types';
import { AnalyticsCategory } from './_shared';

const getPropsFrom = (
    user: any,
    gaSettings: any,
    collection: CollectionType,
    share_props: ShareProps,
    more_collections_from_this_organizer?: MoreCollectionsFromThisOrganizer,
    other_events_you_may_like?: OtherEventsYouMayLike,
) => {
    return {
        collectionDetailsProps: {
            collectionName: collection.name,
            collectionSummary: collection.summary,
            organizerName: collection.organizer?.name,
            organizerUrl: collection.organizer?.url,
            organizerLogoUrl: collection.organizer?.logo?.url,
            organizerFollowersCount:
                collection.organizer?.follow_status?.num_followers,
        },
        organizerPanelProps: {
            organizerName: collection.organizer?.name,
            organizerDescription: collection.organizer?.description,
            organizerLogoUrl: collection.organizer?.logo?.url,
            organizerUrl: collection.organizer?.url,
            organizerId: collection.organizer?.id,
            organizers: [collection.organizer?.id],
            collectionName: collection.name,
            collectionId: collection.id,
            gaCategory: AnalyticsCategory.Listing,
            gaSettings: gaSettings,
            isAuthenticated: user.isAuthenticated,
            isGDPRCountry: true,
            isFollowing:
                collection.organizer?.follow_status?.followed_by_you || false,
        },
        moreCollectionsFromThisOrganizerProps:
            more_collections_from_this_organizer,
        otherEventsYouMayLikeProps: other_events_you_may_like,
        collectionShareProps: {
            collectionId: collection.id,
            collectionUrl: collection.url,
            collectionName: collection.name,
            ...share_props,
        },
    };
};

const isProd = (env: CoreEnv) => {
    return env?.ebDomain?.includes('eventbrite') || false;
};

export const App: React.FunctionComponent<
    CollectionMovedServerData | CollectionServerData
> = (serverDataProps) => {
    const {
        env,
        app_version,
        app_name,
        request,
        user,
        footerLinks,
        gaSettings,
        preview,
        featureFlags = {},
    } = serverDataProps;

    useEffect(() => {
        if (isProd(env)) {
            initializeRealUserMonitoring({
                sessionSampleRate: 20,
                version: app_version,
                service: app_name,
            });
        }
        //force only on mount
        //eslint-disable-next-line
    }, []);

    const isOrganizerInListingView =
        (serverDataProps as CollectionServerData).permissions?.can_manage &&
        !preview;
    const isMovedCollection = 'collection_moved' in serverDataProps;

    useEffect(() => {
        const isMovedCollection = 'collection_moved' in serverDataProps;
        if (!isMovedCollection) {
            const shouldShowFXSurvey = isOrganizerInListingView && isProd(env);

            if (shouldShowFXSurvey) {
                displayFXSurveyIntercept({
                    email: user.email,
                    organizationId: (serverDataProps as CollectionServerData)
                        .collection.organizer.organization_id,
                    isProductionEnv: true,
                });
            }
        }
    }, [env, isOrganizerInListingView, serverDataProps, user]);

    const otherEventsYouMayLikeProps =
        serverDataProps.other_events_you_may_like;

    if (isMovedCollection) {
        return (
            <FeatureFlagsProvider value={featureFlags}>
                <PageLayout
                    env={env}
                    request={request}
                    user={user}
                    footerLinks={footerLinks}
                    preview={preview}
                    featureFlags={featureFlags}
                >
                    <CollectionError
                        otherEventsYouMayLikeProps={otherEventsYouMayLikeProps}
                    />
                </PageLayout>
            </FeatureFlagsProvider>
        );
    }

    const serverProps = serverDataProps as CollectionServerData;
    const derivedProps = getPropsFrom(
        user,
        gaSettings,
        serverProps.collection,
        serverProps.share_props,
        serverProps.more_collections_from_this_organizer,
        serverProps.other_events_you_may_like,
    );

    return (
        <ApplicationContext.Provider value={serverProps}>
            <AnalyticsContext.Provider
                value={{
                    collectionId: serverProps.collection.id,
                    organizerId: serverProps.collection.organizer?.id,
                }}
            >
                <FeatureFlagsProvider value={featureFlags}>
                    <PageLayout
                        env={env}
                        request={request}
                        user={user}
                        footerLinks={footerLinks}
                        preview={preview}
                        featureFlags={featureFlags}
                    >
                        {isOrganizerInListingView && (
                            <>
                                <OrganizerUiBanner />
                            </>
                        )}
                        <Collection
                            env={env}
                            collectionDetailsProps={
                                derivedProps.collectionDetailsProps
                            }
                            organizerPanelProps={
                                derivedProps.organizerPanelProps
                            }
                            collectionShareProps={
                                derivedProps.collectionShareProps
                            }
                            moreCollectionsFromThisOrganizerProps={
                                derivedProps.moreCollectionsFromThisOrganizerProps
                            }
                            otherEventsYouMayLikeProps={
                                derivedProps.otherEventsYouMayLikeProps
                            }
                        />
                    </PageLayout>
                </FeatureFlagsProvider>
            </AnalyticsContext.Provider>
        </ApplicationContext.Provider>
    );
};
