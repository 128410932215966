import { gettext } from '@eventbrite/i18n';
import { deepKeysToCamel } from '@eventbrite/transformation-utils';
import momentTimezone from 'moment-timezone';
import {
    DestinationEvent,
    DiscoveryEvent,
    EventHeroCarouselData,
    EventUrgencySignalsApiData,
    EventUrgencySignalsData,
    FormattedEvent,
    UrgencySignalsKeys,
} from '../types';

const getLocationInfo = (venue?: {
    name: string;
    address?: { localized_area_display: string };
}) => {
    //If we have both name and address add comma separated address
    if (venue?.name && venue?.address?.localized_area_display) {
        //ex. Eventbrite • San Francisco CA
        return `${venue?.name} \u2022 ${venue?.address?.localized_area_display}`;
    }

    return venue?.name || venue?.address?.localized_area_display || '';
};

export const transformDiscoveryEvent = (
    event: DiscoveryEvent,
): FormattedEvent => {
    let [startDate, startTime] = event.start.local.split('T');
    let subtitle;

    if (event.is_series_parent) {
        startDate = '';
        subtitle = gettext('MULTIPLE DATES').toString();
    }

    return {
        subtitle,
        isRepeatingEvent: event.is_series_parent,
        id: event.id,
        isFree: event.is_free,
        imageUrl: event?.logo?.url,
        name: event?.name.text,
        isOnlineEvent: event.online_event,
        formattedPriceString: event.is_free ? '' : event.price_range,
        startTime: startTime,
        startDate: event.hide_start_date ? undefined : startDate,
        timezone: event.start.timezone,
        url: event.url,
        locationInfo: getLocationInfo(event?.venue),
        savedByYou: event.bookmarked,
        organizerId: event.organizer_id,
        widgets: [],
    };
};

export const transformDestinationEvent = (
    event: DestinationEvent,
): FormattedEvent => {
    const fullInstanceCount =
        event?.series?.counts?.current_future || event?.num_children;
    const upcomingRepeatingInstanceCount = fullInstanceCount
        ? fullInstanceCount - 1
        : fullInstanceCount;

    const currency =
        event?.ticket_availability?.minimum_ticket_price?.currency ||
        event?.ticket_availability?.maximum_ticket_price?.currency ||
        '';

    return {
        dedup: event.dedup,
        debugInfo: event?.debug_info?.es_debug_info
            ? {
                  esDebugInfo: event.debug_info.es_debug_info,
              }
            : undefined,
        edgeColor: event?.image?.edge_color || undefined,
        eventSalesStatus: {
            //Default to on_sale in cases where expansion fails or data is not present
            salesStatus: event?.event_sales_status?.sales_status || 'on_sale',
            message: event?.event_sales_status?.message || '',
            messageType: event?.event_sales_status?.message_type || '',
            messageCode: event?.event_sales_status?.message_code || '',
            defaultMessage: event?.event_sales_status?.default_message || '',
        },
        id: event.id,
        imageUrl: event?.image?.url || '',
        isFree: !!event?.ticket_availability?.is_free,
        isOnlineEvent: event.is_online_event,
        isPromoted: !!event?.promoted_listing_metadata,
        isRepeatingEvent: !!event.series_id,
        isSoldOut:
            event?.event_sales_status?.sales_status === 'sold_out' &&
            !event?.series_id,
        locationInfo: getLocationInfo(event?.primary_venue),
        maxPrice: event?.ticket_availability?.maximum_ticket_price
            ? {
                  currency,
                  maxPriceValue:
                      event?.ticket_availability?.maximum_ticket_price?.value,
              }
            : undefined,
        minPrice: event?.ticket_availability?.minimum_ticket_price
            ? {
                  //Default format. Consuming app
                  //will pass this directly to the eventcard
                  //leaving in place for backwards compatibility
                  currencyFormat: '¤#,##0.00',
                  currency,
                  minPriceValue:
                      event?.ticket_availability?.minimum_ticket_price?.value,
              }
            : undefined,
        name: event.name,
        organizerFollowerCount:
            event?.primary_organizer?.num_followers || undefined,
        organizerId: event.primary_organizer_id,
        organizerName: event?.primary_organizer?.name || '',
        promotedListingMetadata: deepKeysToCamel(
            event?.promoted_listing_metadata,
        ),
        publicCollections: event?.public_collections?.creator_collections
            ? {
                  creatorCollections: {
                      collections:
                          event.public_collections.creator_collections
                              .collections,
                      objectCount:
                          event.public_collections.creator_collections
                              .object_count,
                  },
              }
            : undefined,
        repeatingInstanceCount: upcomingRepeatingInstanceCount,
        savedByYou: !!event.saves?.saved_by_you,
        startDate: event.hide_start_date
            ? undefined
            : event?.series?.next_dates?.[0]?.start.split('T')[0] ||
              event.start_date,
        startTime:
            event?.series?.next_dates?.[0]?.start.split('T')[1] ||
            event.start_time,
        tags: event.tags?.map(
            (tag: {
                prefix: string;
                tag: string;
                display_name: string;
                localized?: {
                    display_name: string;
                };
            }) => tag.tag,
        ),
        ticketsUrl: event.tickets_url,
        ticketsBy: event.tickets_by,
        timezone: event.timezone,
        urgencySignals: getUrgencySignals(event.urgency_signals),
        openDiscount: getPromoCodeData(event.ticket_availability, {
            timezone: event.timezone,
            currency,
        }),
        url:
            !!event.series_id && event.parent_url
                ? event.parent_url
                : event.url,
        venue: deepKeysToCamel(event?.primary_venue),
        widgets: event?.widgets?.map(deepKeysToCamel),
    };
};

export function getPromoCodeData(
    ticketAvailability: DestinationEvent['ticket_availability'],
    { timezone, currency }: { timezone: string; currency: string },
) {
    const openDiscount = ticketAvailability?.open_discount;

    if (!openDiscount || !ticketAvailability) {
        return undefined;
    }

    const endSalesDateInUtc = openDiscount.end_date
        ? momentTimezone.tz(openDiscount.end_date, timezone).utc().toISOString()
        : undefined;

    return {
        ...deepKeysToCamel(openDiscount),
        currency,
        endDate: endSalesDateInUtc || ticketAvailability.end_sales_date?.utc,
    };
}

export const getUrgencySignals = (
    apiUrgencySignals?: EventUrgencySignalsApiData | null,
) => {
    if (!apiUrgencySignals) {
        return undefined;
    }

    const urgencySignals: EventUrgencySignalsData = {
        [UrgencySignalsKeys.fewTickets]: false,
        [UrgencySignalsKeys.salesEndSoon]: false,
        [UrgencySignalsKeys.earlyBird]: false,
        [UrgencySignalsKeys.popular]: false,
        [UrgencySignalsKeys.new]: false,
        [UrgencySignalsKeys.goingFast]: false,
    };

    const activeUrgencySignals = [
        ...apiUrgencySignals.categories,
        ...apiUrgencySignals.messages,
    ];
    activeUrgencySignals.forEach(
        (activeUrgencySignal) => (urgencySignals[activeUrgencySignal] = true),
    );

    return urgencySignals;
};

export function getCarouselWidget(
    event: Pick<FormattedEvent, 'widgets'>,
): EventHeroCarouselData | undefined {
    return event.widgets?.find(({ type }) => type === 'herocarousel') as
        | EventHeroCarouselData
        | undefined;
}

export function hasCarouselWidget(event: Pick<FormattedEvent, 'widgets'>) {
    const carousel = getCarouselWidget(event);
    return !!carousel && carousel.data.slides.length > 1;
}

export function hasTicketsFromExternalProvider({
    ticketsBy = 'Eventbrite',
}: Pick<FormattedEvent, 'ticketsBy'>): boolean {
    return ticketsBy !== 'Eventbrite';
}
