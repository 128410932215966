import { Divider } from '@eventbrite/eds-divider';
import { gettext } from '@eventbrite/i18n';
import {
    MagnifyingGlassIcon,
    MapPinIcon,
    Typography,
} from '@eventbrite/marmalade';
import {
    LocationProvider,
    useLocationContext,
} from '@eventbrite/search-takeover';
import loadable from '@loadable/component';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useState } from 'react';
import { DEFAULT_QUERYCLIENT_OPTIONS } from '../../../../../constants/reactQuery';
import { useGlobalNavContext } from '../../../../../state';
import { onEnterKey } from '../../../../../utils';
import {
    getHeapDataAttribute,
    HeapAttributes,
} from '../../../../../utils/heap-data';
import SearchBar from '../SearchBar/SearchBar';
import styles from './SearchBarFull.module.scss';

const LazySearchTakeover = loadable.lib(
    () => import('@eventbrite/search-takeover'),
);

const queryClient = new QueryClient(DEFAULT_QUERYCLIENT_OPTIONS);

const SearchBarContent = () => {
    const { searchBar } = useGlobalNavContext();
    const { location, isUsingUserLocation } = useLocationContext();
    const { Teams, Features, Components, Actions } = HeapAttributes;
    const currentLocationString = gettext('Current location');

    const [showSearch, setShowSearch] = useState(false);

    const handleClick = () => {
        setShowSearch(true);
    };

    if (!searchBar?.renderSearchBar) {
        return null;
    }

    // If the user is using their current location, show the "Current location" string
    // Otherwise, if the searchBar Context provides a location, use that
    let locationString;
    if (isUsingUserLocation) {
        locationString = currentLocationString;
    } else {
        locationString = location?.currentPlace || currentLocationString;
    }

    return (
        <>
            <div className={styles.searchBarNoLocation}>
                <SearchBar />
            </div>
            <div
                className={styles.searchBarWithLocation}
                data-testid="header-search-full"
                onClick={handleClick}
                onKeyDown={(e) => onEnterKey(e, handleClick)}
                aria-label={gettext('open search bar').toString()}
                data-heap-id={getHeapDataAttribute({
                    team: Teams.SEO,
                    feature: Features.GlobalNav,
                    component: Components.SearchBar,
                    name: 'search-bar',
                    action: Actions.Click,
                })}
            >
                <Typography
                    variant="body-md"
                    as="span"
                    color="neutral-700"
                    className={styles.searchBarContent}
                >
                    <div className={styles.searchLabelContainer}>
                        <MagnifyingGlassIcon size="small" />
                        <div className={styles.labelsWrapper}>
                            <span className={styles.desktopLabel}>
                                {searchBar?.desktopLabel}
                            </span>
                            <span className={styles.mobileLabel}>
                                {searchBar?.mobileLabel}
                            </span>
                            <span className={styles.mobileLocation}>
                                {locationString}
                            </span>
                        </div>
                    </div>
                    <div className={styles.dividerContainer}>
                        <Divider direction="vertical" color="ui-500" />
                    </div>
                    <div className={styles.locationContainer}>
                        <MapPinIcon size="small" />
                        {locationString}
                    </div>
                </Typography>
                {showSearch ? (
                    <LazySearchTakeover data-testid="search-takeover">
                        {({ SearchTakeover }: { SearchTakeover: any }) => (
                            <SearchTakeover
                                domNodeId="root"
                                onClose={() => setShowSearch(false)}
                                id="search-takeover"
                                isVisible={showSearch}
                                location={location}
                            />
                        )}
                    </LazySearchTakeover>
                ) : null}
            </div>
        </>
    );
};

const SearchBarFull = () => {
    const { searchBar } = useGlobalNavContext();
    return (
        <QueryClientProvider client={queryClient}>
            <LocationProvider location={searchBar?.location}>
                <SearchBarContent />
            </LocationProvider>
        </QueryClientProvider>
    );
};

export default SearchBarFull;
