import { CoreEnv } from '@eventbrite/context-gen';
import { Button } from '@eventbrite/eds-button';
import { Divider } from '@eventbrite/eds-divider';
import { LoadingCards } from '@eventbrite/eds-event-card';
import { Icon } from '@eventbrite/eds-icon';
import { CalendarFill } from '@eventbrite/eds-iconography';
import { Tabs } from '@eventbrite/eds-tabs';
import {
    EventCardShareAction,
    EventClient,
    EventClientProvider,
    getEventsById,
    getShareActionProps,
} from '@eventbrite/event-renderer';
import { sdkRequest, translateServerErrors } from '@eventbrite/http';
import { gettext } from '@eventbrite/i18n';
import React, { useContext, useEffect, useState } from 'react';
import { FollowOrganizer } from '../components/FollowOrganizerButton/FollowOrganizerButton';
import { AnalyticsContext, ApplicationContext } from '../context';
import { CreatorCollectionsVerticalEventCard } from '../CreatorCollectionsVerticalEventCard';
import {
    AffiliateCodes,
    AppProps,
    CollectionEventCard,
    EventListProps,
    EventOriginType,
    Pagination,
} from '../types';
import { AnalyticsAction, AnalyticsCategory, trackEvent } from '../_shared';
import { transformCollectionEvent } from '../_shared/transform';

const creatorCollectionsUtmOptions = {
    'utm-campaign': 'social',
    'utm-content': 'attendeeshare',
    'utm-medium': 'discovery',
    'utm-term': 'creator-collections',
    'utm-share-source': 'creator-collections',
};

const eventClient = new EventClient();

export const PAST_EVENTS_SHOWED_WHEN_ONLY_PAST_EVENTS = 3;
export const EVENTS_IN_COLLECTION_PAGE_SIZE = 24;

enum Tab {
    Upcoming = 0,
    Past = 1,
}
interface Props {
    env: CoreEnv;
}

export const CollectionEvents: React.FC<Props> = ({ env }) => {
    const context = useContext(ApplicationContext) as AppProps;
    const { collectionId } = useContext(AnalyticsContext);
    const { past, upcoming } = context.events_in_collection;
    const organizerName = context.collection.organizer?.name;
    const [pastEvents, upcomingEvents] = [past, upcoming].map(
        (sublist) =>
            (sublist?.events || []).map((event: EventOriginType) =>
                transformCollectionEvent(event),
            ) || [],
    );

    const hasPastAndUpcomingEvents =
        pastEvents?.length > 0 && upcomingEvents?.length > 0;
    const hasOnlyPastEvents =
        pastEvents?.length > 0 && upcomingEvents?.length === 0;
    const hasOnlyUpcomingEvents =
        pastEvents?.length === 0 && upcomingEvents?.length > 0;
    const notPastNorUpcomingEvents =
        pastEvents?.length === 0 && upcomingEvents?.length === 0;
    env;
    return (
        <div
            className="eds-l-mar-bot-10"
            data-testid="collection-events-section"
        >
            <h2 className="eds-text-hm eds-l-mar-bot-6 cc-heading">
                {gettext('Events in this collection')}
            </h2>
            <EventClientProvider client={eventClient}>
                {hasPastAndUpcomingEvents && (
                    <Tabs
                        role="tablist"
                        shouldKeepContentMounted={true}
                        onTabChange={(tab: Tab) => {
                            trackEvent({
                                action: AnalyticsAction.EventsTabClick,
                                category: AnalyticsCategory.Listing,
                                label:
                                    tab === Tab.Upcoming ? 'upcoming' : 'past',
                            });
                        }}
                        items={[
                            {
                                displayName: `${gettext('Upcoming')} (${
                                    upcoming.pagination?.object_count || 0
                                })`,
                                value: Tab.Upcoming,
                                content: (
                                    <EventList
                                        collectionId={collectionId}
                                        events={upcomingEvents}
                                        pagination={upcoming.pagination}
                                        eventType={EventType.Upcoming}
                                        initialMaxEventsToShow={
                                            EVENTS_IN_COLLECTION_PAGE_SIZE
                                        }
                                        env={env}
                                    />
                                ),
                            },
                            {
                                displayName: `${gettext('Past')} ${
                                    past.pagination?.object_count ===
                                    pastEvents.length
                                        ? `(${
                                              past.pagination?.object_count || 0
                                          })`
                                        : ''
                                }`,
                                value: Tab.Past,
                                content: (
                                    <EventList
                                        collectionId={collectionId}
                                        events={pastEvents}
                                        pagination={past.pagination}
                                        eventType={EventType.Past}
                                        initialMaxEventsToShow={
                                            EVENTS_IN_COLLECTION_PAGE_SIZE
                                        }
                                        env={env}
                                    />
                                ),
                            },
                        ]}
                    />
                )}
                {(hasOnlyPastEvents || notPastNorUpcomingEvents) && (
                    <>
                        <div className="cc-no-upcoming-events">
                            <Icon
                                type={<CalendarFill />}
                                size="medium"
                                color="ui-800"
                            />

                            <h3 className="eds-text-bl eds-l-mar-top-5">
                                {gettext('No upcoming events')}
                            </h3>
                            {organizerName && (
                                <>
                                    <p className="eds-text-color--grey-600 eds-l-mar-top-1">
                                        {gettext(
                                            'Follow %(organizerName)s to never miss a moment',
                                            {
                                                organizerName,
                                            },
                                        )}
                                    </p>
                                    <div className="eds-l-mar-top-5">
                                        <FollowOrganizer />
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
                {hasOnlyPastEvents && (
                    <>
                        <div className="eds-l-mar-top-10 eds-l-mar-bot-10">
                            <Divider color="grey-400" />
                        </div>
                        <h3 className="eds-text-bl eds-l-mar-bot-8">
                            {gettext('Here’s what you may have missed')}
                        </h3>
                        <EventList
                            collectionId={collectionId}
                            events={pastEvents}
                            pagination={past.pagination}
                            eventType={EventType.Past}
                            initialMaxEventsToShow={
                                PAST_EVENTS_SHOWED_WHEN_ONLY_PAST_EVENTS
                            }
                            env={env}
                        />
                    </>
                )}
                {hasOnlyUpcomingEvents && (
                    <EventList
                        collectionId={collectionId}
                        events={upcomingEvents}
                        pagination={upcoming.pagination}
                        eventType={EventType.Upcoming}
                        initialMaxEventsToShow={EVENTS_IN_COLLECTION_PAGE_SIZE}
                        env={env}
                    />
                )}
            </EventClientProvider>
        </div>
    );
};

interface EventListDisplayProps {
    events: CollectionEventCard[];
    collectionId: string;
    pagination: Pagination;
    eventType: EventType;
    initialMaxEventsToShow: number;
    env: CoreEnv;
}

enum EventType {
    Past = 'past',
    Upcoming = 'current_future',
}

enum EventSortType {
    Past = 'start_desc',
    Upcoming = 'start_asc',
}
interface EventListItemProps {
    event: CollectionEventCard;
    pageOwnerOrganizerId: String;
    env: CoreEnv;
    eventType: EventType;
}

const EvenListItem: React.FC<EventListItemProps> = ({
    event,
    env,
    eventType,
}) => {
    return (
        <li className="cc-card-list__item">
            <CreatorCollectionsVerticalEventCard
                event={event}
                affCode={AffiliateCodes.EventsInOrganizerCollection}
                isAuthenticated={true}
                locale={env?.localeInfo.locale || 'en_US'}
                statsigLocationString=""
                moreActions={
                    event &&
                    eventType != 'past' && (
                        <EventCardShareAction
                            {...getShareActionProps({
                                name: event.name,
                                id: event.id,
                                url: event.url,
                                affCode:
                                    AffiliateCodes.EventsInOrganizerCollection,
                                utmOptions: creatorCollectionsUtmOptions,
                            })}
                        />
                    )
                }
            />
        </li>
    );
};
// TODO: Add types to argument object.
const EventList: React.FC<EventListDisplayProps> = ({
    events: initialEvents,
    collectionId,
    pagination,
    eventType,
    initialMaxEventsToShow,
    env,
}) => {
    const {
        events,
        numberOfEventsToShow,
        hasMoreEvents,
        loadNextPage,
        isLoadingMoreEvents,
    } = useCollectionPaginatedEvents({
        events: initialEvents,
        collectionId,
        eventType,
        hasMoreEvents: (pagination.object_count || 0) > initialMaxEventsToShow,
        hasPaginatedEvents: pagination.has_more_items,
        initialMaxEventsToShow,
    });
    const { organizerId: pageOwnerOrganizerId } = useContext(AnalyticsContext);
    return (
        <>
            <ul className="cc-card-list">
                {events
                    .slice(0, numberOfEventsToShow)
                    .map((event: CollectionEventCard) => (
                        <EvenListItem
                            key={event.id}
                            event={event}
                            pageOwnerOrganizerId={pageOwnerOrganizerId}
                            env={env}
                            eventType={eventType}
                        />
                    ))}
            </ul>

            {hasMoreEvents && (
                <>
                    {isLoadingMoreEvents ? (
                        <div className="cc-loading-cards">
                            <LoadingCards style="grid" cardCount={3} />
                        </div>
                    ) : (
                        <div className="cc-card-list-show-more-events">
                            <Button
                                onClick={() => {
                                    loadNextPage();
                                    trackEvent({
                                        action: AnalyticsAction.EventsShowMore,
                                        category: AnalyticsCategory.Listing,
                                        label:
                                            eventType === EventType.Upcoming
                                                ? 'upcoming'
                                                : 'past',
                                    });
                                }}
                            >
                                {gettext('Show more events')}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export const getCollectionEventsPageByTimeFilterUrl = (
    collectionId: string,
    timeFilter: string,
    page: number,
) => {
    const sortOrder =
        timeFilter === EventType.Upcoming
            ? EventSortType.Upcoming
            : EventSortType.Past;
    return `/api/v3/collections/${collectionId}/events/public?time_filter=${timeFilter}&page=${page}&page_size=${EVENTS_IN_COLLECTION_PAGE_SIZE}&order_by=${sortOrder}`;
};

interface collectionEventsPaginationProps {
    collectionId: string;
    events: CollectionEventCard[];
    eventType: string;
    hasMoreEvents: boolean;
    hasPaginatedEvents: boolean;
    initialMaxEventsToShow: number;
}

export async function updateEventData(eventIdList: string[]) {
    const defaultChunkSize = 50;
    const destinationExpansions = [
        'event_sales_status',
        'image',
        'primary_venue',
        'saves',
        'series',
        'ticket_availability',
        'primary_organizer',
    ];
    try {
        return await getEventsById(
            eventIdList,
            defaultChunkSize,
            destinationExpansions,
        );
    } catch {
        return { events: [] };
    }
}
function useCollectionPaginatedEvents({
    collectionId,
    events: initialEvents,
    eventType,
    hasMoreEvents: initialHasMoreEvents,
    hasPaginatedEvents,
    initialMaxEventsToShow,
}: collectionEventsPaginationProps) {
    const [events, setEvents] = useState(initialEvents);
    const [page, setPage] = useState(0);
    const [hasMoreEvents, setHasMoreEvents] = useState(initialHasMoreEvents);
    const [isLoadingMoreEvents, setIsLoadingMoreEvents] = useState(false);
    useEffect(() => {
        if (page > 0) {
            const nextPage = page + 1;
            const url = getCollectionEventsPageByTimeFilterUrl(
                collectionId,
                eventType,
                nextPage,
            );

            if (hasPaginatedEvents) {
                setIsLoadingMoreEvents(true);
                sdkRequest(url)
                    .then((newUpcomingEvents: EventListProps) => {
                        const eventIdList = newUpcomingEvents.events.map(
                            (event) => event.id,
                        );
                        updateEventData(eventIdList).then(
                            (newUpcomingEventsData) => {
                                setEvents((events) => [
                                    ...events,
                                    ...newUpcomingEventsData.events,
                                ]);
                                setHasMoreEvents(
                                    newUpcomingEvents.pagination.has_more_items,
                                );
                                setIsLoadingMoreEvents(false);
                            },
                        );
                    })
                    .catch(translateServerErrors);
            } else {
                //display past hidden ones
                setHasMoreEvents(false);
                setIsLoadingMoreEvents(false);
            }
        } else {
            const eventIdList = events.map((event) => event.id);
            updateEventData(eventIdList).then((newUpcomingEventsData) => {
                if (newUpcomingEventsData?.events?.length > 0) {
                    setEvents(newUpcomingEventsData.events);
                }
            });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, collectionId, eventType, setEvents]);

    const numberOfEventsToShow =
        page === 0
            ? initialMaxEventsToShow
            : initialMaxEventsToShow + page * EVENTS_IN_COLLECTION_PAGE_SIZE;

    return {
        events,
        loadNextPage: () => setPage(page + 1),
        numberOfEventsToShow,
        hasMoreEvents,
        isLoadingMoreEvents,
    };
}
