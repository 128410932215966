import { IconButton } from '@eventbrite/eds-icon-button';
import { CrossChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { BarButton } from './BarButton';
import { ModalType } from './constants';
import './modal.scss';

export interface CloseButtonProps {
    onClose?: () => void;
    hasBackgroundColor?: boolean;
    type?: ModalType;
}

const CloseButton: FunctionComponent<CloseButtonProps> = ({
    onClose,
    hasBackgroundColor = false,
    type,
}) => {
    if (!onClose) {
        return null;
    }

    const isTakeoverModal = type === ModalType.takeover;
    const takeoverButton = isTakeoverModal ? (
        <div className="eds-show-down-mn">
            <BarButton onClick={onClose} />
        </div>
    ) : null;

    return (
        <>
            {takeoverButton}
            <div
                className={classNames('eds-modal__close-button', {
                    'eds-show-up-md': !!takeoverButton,
                })}
            >
                <IconButton
                    data-automation="modal-close-button"
                    data-spec="close-button"
                    onClick={onClose}
                    iconType={<CrossChunky aria-hidden="true" />}
                    title={gettext('Close')}
                    isOverImage={hasBackgroundColor}
                />
            </div>
        </>
    );
};

export default CloseButton;
