import React from 'react';
import { GlobalNav } from '../modules/GlobalNav';
import { GlobalNavProps } from '../modules/types';
export interface RenderGlobalNavOrUndefined {
    config: GlobalNavProps;
    featureFlag?: boolean;
}
export const RenderGlobalNavOrUndefined = ({
    config,
}: RenderGlobalNavOrUndefined) => {
    const { positionSticky, searchBar, logo, navigation, user, env } = config;
    if (!user.isAuthenticated) {
        return (
            <GlobalNav
                positionSticky={positionSticky}
                searchBar={searchBar}
                logo={logo}
                navigation={navigation}
                user={user}
                env={env}
            />
        );
    }
};

export const getDefaultLocale = (default_language: string) => {
    switch (default_language) {
        case 'it-it':
            return 'it';
        case 'dk-dk':
            return 'da';
        case 'es-es':
            return 'es';
        case 'de-de':
            return 'de';
        case 'en-fi':
            return 'fi';
        case 'fr-fr':
            return 'fr';
        case 'es-er':
        case 'en-au':
        case 'en-ca':
        case 'en-gb':
        case 'en-us':
        case 'fr-ca':
        case 'nl-nl':
        case 'pt-pt':
        case 'pt-br':
            return default_language;
        default:
            return 'en-us';
    }
};

export const alternativePath = (path: string, tld: string) => {
    const mapByTld: any = {
        '.ca': {
            'l/sell-tickets/': 'l/sell-tickets-online/',
        },
        '.co.uk': {
            'l/conferences/': 'l/conference-planning/',
        },
    };

    return mapByTld?.[tld]?.[path] || path;
};

export const getSigninOrSignupUrl = (serverUrl?: string) => {
    if (
        !CREATOR_INTENTED_PAGES.some((path) =>
            decodeURIComponent(serverUrl ?? '').includes(path),
        )
    )
        return serverUrl ?? '/signin/';

    const AUTH_PATH = serverUrl?.includes('/signup')
        ? '/signin/signup/'
        : '/signin/';
    const url = new URL(serverUrl ?? 'https://www.eventbrite.com');

    url.pathname = AUTH_PATH;
    url.searchParams.set('referrer', '/manage/events/create/');
    url.searchParams.delete('bust_wagtail_cache');

    return url.toString();
};

export const onEnterKey = (
    event: React.KeyboardEvent,
    callback: () => void,
) => {
    if (event.key === 'Enter') {
        callback();
    }
};

const CREATOR_INTENTED_PAGES = ['/l/', '/organizer/', '/boost', '/reconvene'];
