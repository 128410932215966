import React from 'react';
import { AppProps } from './types';

export const ApplicationContext = React.createContext<AppProps | null>(null);

interface AnalyticsContext {
    collectionId: string;
    organizerId: string;
}

export const AnalyticsContext = React.createContext<AnalyticsContext>({
    collectionId: '',
    organizerId: '',
});
