import React from 'react';
import PropTypes from 'prop-types';
import * as constants from './constants';
import classNames from 'classnames';
import { gettext } from '@eventbrite/i18n';

import './label.scss';

const LabelSpan = ({ children, dataSpec, ...componentAttrs }) => {
    let labelDataSpec = 'label-span';

    if (dataSpec) {
        labelDataSpec = dataSpec;
    }

    return (
        <span {...componentAttrs} data-spec={labelDataSpec}>
            {children}
        </span>
    );
};

const LabelLabel = ({ children, htmlFor, dataSpec, ...componentAttrs }) => {
    let labelDataSpec = 'label-label';

    if (dataSpec) {
        labelDataSpec = dataSpec;
    }

    return (
        <label {...componentAttrs} htmlFor={htmlFor} data-spec={labelDataSpec}>
            {children}
        </label>
    );
};

const TYPES_COMPONENT_MAP = {
    [constants.TYPE_SPAN]: LabelSpan,
    [constants.TYPE_LABEL]: LabelLabel,
};

/**
 * Class that represents a label for a single or multiple inputs.
 * @extends React.PureComponent
 * @example
 * // <Label htmlFor="my-input" type="span">My Label</Label>
 **/
export default class Label extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        /**
         * An id string referencing the input this label pertains to.
         */
        htmlFor: PropTypes.string,
        /**
         * One of 'label' or 'span'.
         */
        type: PropTypes.oneOf(constants.TYPES),
        /**
         * Whether or not the field is required.
         */
        required: PropTypes.bool,
        /**
         * Whether or not the field is hidden.
         */
        hidden: PropTypes.bool,
        /**
         * Identifier for tests
         */
        'data-spec': PropTypes.string,
        /**
         * Static state of the input field
         */
        shouldShowAsStatic: PropTypes.bool,
    };

    static defaultProps = {
        type: constants.TYPE_LABEL,
        required: false,
        hidden: false,
    };

    _getRequiredIndicator(shouldShowAsStatic) {
        const requiredIndicatorClassName = classNames(
            'eds-label__required-indicator',
            {
                'eds-text-bs': shouldShowAsStatic,
                'eds-text-bm': !shouldShowAsStatic,
            },
        );

        return (
            <span
                className={requiredIndicatorClassName}
                data-spec="required-indicator"
            >
                <span> *</span>
                <span className="eds-is-hidden-accessible">
                    ({gettext('required')})
                </span>
            </span>
        );
    }

    render() {
        const {
            children,
            type,
            required,
            hidden,
            htmlFor,
            shouldShowAsStatic,
            'data-spec': dataSpec,
            ...extraAttrs
        } = this.props;
        const labelClass = classNames('eds-field-styled__label', {
            'eds-label-primary': !hidden,
            'eds-is-hidden-accessible': hidden,
            'eds-field-styled__label--required': required,
        });
        const LabelSubComponent = TYPES_COMPONENT_MAP[type];
        let requiredIndicator;

        if (required) {
            requiredIndicator = this._getRequiredIndicator(shouldShowAsStatic);
        }

        return (
            <LabelSubComponent
                className={labelClass}
                htmlFor={htmlFor}
                dataSpec={dataSpec}
                {...extraAttrs}
            >
                <span className="eds-label__content">{children}</span>
                {requiredIndicator}
            </LabelSubComponent>
        );
    }
}
