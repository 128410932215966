import * as React from 'react';

const CalendarFillSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" {...props}>
        <defs>
            <path
                id="calendar-fill_svg__24_small_calendar_fill-a"
                d="M14 0h1v2h5v4H0V2h5V0h1v2h8V0zM0 20V7h20v13H0zm13-10v2h2v-2h-2zm-4 0v2h2v-2H9zm-4 0v2h2v-2H5zm8 4v2h2v-2h-2zm-4 0v2h2v-2H9zm-4 0v2h2v-2H5z"
            />
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(2 2)">
            <mask id="calendar-fill_svg__24_small_calendar_fill-b" fill="#fff">
                <use xlinkHref="#calendar-fill_svg__24_small_calendar_fill-a" />
            </mask>
            <use
                fill="#45494E"
                xlinkHref="#calendar-fill_svg__24_small_calendar_fill-a"
            />
            <g mask="url(#calendar-fill_svg__24_small_calendar_fill-b)">
                <path fill="#000" d="M-2-2h24v24H-2z" />
            </g>
        </g>
    </svg>
);

CalendarFillSvg.displayName = 'CalendarFillSvg';
export default CalendarFillSvg;
