import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { GlobalNavProvider } from '../../../../../state';
import { GlobalNavProps } from '../../../../types';
import styles from '../SearchBar.module.scss';
import SearchBarFull from '../SearchBarFull';

const ConsumerHeaderSearchBar: React.FC<GlobalNavProps> = ({
    searchBar,
    user,
    env,
}) => {
    return (
        <GlobalNavProvider
            positionSticky={{ desktop: false, mobile: false }}
            searchBar={searchBar}
            user={user}
            env={env}
        >
            <div className={styles.consumerHeaderSearchBar}>
                <nav
                    aria-label={gettext('Main Navigation').toString()}
                    data-testid="consumer-header-nav"
                >
                    <SearchBarFull />
                </nav>
            </div>
        </GlobalNavProvider>
    );
};

export default ConsumerHeaderSearchBar;
