import { Layout } from '@eventbrite/eds-layout';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { CardsCarousel, CardsType } from '../CardsCarousel/CardsCarousel';
import { AffiliateCodes, OtherEventsYouMayLike } from '../types';
import { OTHER_EVENTS_YOU_MAY_LIKE_TITLE } from '../_shared/constants';
import { CollectionErrorSvg } from './CollectionErrorSvg';
interface Props {
    otherEventsYouMayLikeProps?: OtherEventsYouMayLike;
}

export const CollectionError: React.FC<Props> = ({
    otherEventsYouMayLikeProps,
}) => {
    const otherEventsYouMayLike = otherEventsYouMayLikeProps?.events;

    return (
        <>
            <Layout maxWidth="large" hasHorizontalGutters={true}>
                <div className="eds-text--center eds-l-mar-vert-20">
                    <i
                        className="eds-vector-image eds-vector-image-size--reset"
                        aria-hidden="true"
                    >
                        <CollectionErrorSvg />
                    </i>
                    <h1 className="eds-text-hs eds-l-mar-top-10">
                        {gettext(
                            'This collection either doesn’t exist or has changed.',
                        )}
                    </h1>
                </div>
            </Layout>
            {otherEventsYouMayLike?.length ? (
                <Layout maxWidth="large" hasHorizontalGutters={true}>
                    <div className="cc-outer-wrapper">
                        <CardsCarousel
                            cards={otherEventsYouMayLike}
                            cardsType={CardsType.EVENT}
                            title={OTHER_EVENTS_YOU_MAY_LIKE_TITLE}
                            newTitleStyle={true}
                            affCode={
                                AffiliateCodes.RelatedEventsOtherOrganizers
                            }
                            isFromDestination={true}
                            dataTestId="oeyml-carousel"
                        />
                    </div>
                </Layout>
            ) : null}
        </>
    );
};
