import { AppProps, Collection, CollectionStatus } from '../types';
import { defaultAutocreatedCollectionName } from './constants';

export const initSignalProp = (
    pageOwnerOrganizerId: string,
    cardOrganizerId?: string,
) => {
    const shouldShowOrganizerInfo = pageOwnerOrganizerId !== cardOrganizerId;

    return shouldShowOrganizerInfo ? {} : { signal: undefined };
};

export const isAutocreatedCollectionUnedited = (collection?: Collection) => {
    if (!collection) {
        return false;
    }

    if (
        collection.is_autocreated &&
        collection.name === defaultAutocreatedCollectionName &&
        collection.summary === '' &&
        collection.status === CollectionStatus.Draft
    ) {
        return true;
    }

    return false;
};

export const isAutocreatedCollectionPreviewWithTips = (
    context: AppProps | null,
) => {
    return context?.request?.params?.autocreated === 'true';
};
