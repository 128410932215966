import React from 'react';
import classNames from 'classnames';

import { gettext } from '@eventbrite/i18n';
import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { ANCHOR_LINK } from '@eventbrite/eds-nav-list-item';
import { GlobeChunky } from '@eventbrite/eds-iconography';
import { InfoChunky } from '@eventbrite/eds-iconography';
import { FlagChunky } from '@eventbrite/eds-iconography';
import { KeyboardChunky } from '@eventbrite/eds-iconography';
import { UserChunky } from '@eventbrite/eds-iconography';
import { Badge } from '@eventbrite/eds-badge';
import { getAnalyticsClickHandler } from '@eventbrite/site-analytics';

import {
    ACTION_DISCOVER,
    ACTION_PROFILE_DROPDOWN,
    ACTION_LOGOUT,
    CATEGORY_HEADER,
    LABEL_ACCOUNT,
    LABEL_MY_EVENTS,
    LABEL_ORGANIZER_PROFILE,
    LABEL_SAVED,
    LABEL_TICKETS,
    LABEL_FOLLOWING,
    LABEL_COLLECTIONS,
    LABEL_INTERESTS,
    LABEL_SPONSORSHIPS,
    LABEL_STUDIO,
    LABEL_EMAIL_NAME_ACCOUNT,
    LABEL_CREDITS,
} from '../constants';

/**
 * @typedef {object} Config
 * @property {string} requestPath Current URL
 * @property {string} serverUrl Full base URL to the WWW site
 * @property {string} adminServerUrl Full base URL to the Admin site
 * @property {string} logoutUrl Path to the logout page
 * @property {string} accountSettingsPath Path to the user account settings page
 * @property {string} publicId Public user ID
 * @property {string} email User email
 * @property {string} fullName User full name
 * @property {string} userImageUrl Path to the user's image avatar
 * @property {number} numOrders Number of orders (or tickets) placed as an attendee
 * @property {number} numSavedEvents Number of saved events as an attendee
 * @property {number} numFollowing How many accounts the user is following
 */
/**
 * @typedef {object} MenuItem
 * @property {'anchor' | 'routerLink'} type The type of link
 * @property {import('react').Element} content The content to display
 * @property {string} path The URL path
 * @property {function} [onClick] A function to call when the menu item is clicked (typically for analytics tracking)
 *
 */
/**
 * Gets a lookup of all the pre-configured user menu items given the specified data.
 * This can be used to build up an ordered list of user menu items in a global header.
 * @param {Config} config
 * @returns {Object.<string, MenuItem>}
 */

const logOutFunctions = (requestPath) => {
    // NOTE: We should clean the referal on the local storage to not put the user to a loop between where they come and the login
    if (HAS_WINDOW && localStorage) {
        localStorage.removeItem('referal');
    }

    getAnalyticsClickHandler({
        requestPath,
        action: ACTION_LOGOUT,
        category: CATEGORY_HEADER,
        label: LABEL_ACCOUNT,
    });
};

const getReferrerUrl = () => {
    if (HAS_WINDOW) {
        return window.location.href;
    }
    return '';
};

export const getUserMenuItemsLookup = ({
    // NOTE: If you add/change/remove arguments, you need to update all the callers
    requestPath,
    serverUrl,
    adminServerUrl,
    logoutUrl,
    accountSettingsPath,
    publicId,
    email,
    fullName,
    userImageUrl,
    numOrders,
    numSavedEvents,
    numFollowing,
    isManageOverviewActive = false,
    isDetachedInterests = false,
}) => {
    const maxTruncate = { width: '150px' };
    const userContent = (
        <div>
            <h4 className="eds-text-color--ui-800">{fullName}</h4>
            <div style={maxTruncate}>
                <p className="eds-text-bs eds-text--truncate">{email}</p>
            </div>
        </div>
    );
    const badgeClassName = classNames(
        'eds-l-mar-left-2',
        'eds-l-pad-hor-2',
        'eds-l-pad-vert-1',
    );
    const shouldShowNumSavedEvents = typeof numSavedEvents === 'number';
    const shouldShowFollowCount = typeof numFollowing === 'number';

    const creatorUrl = () => {
        let url = isManageOverviewActive
            ? `${serverUrl}/manage/`
            : `${serverUrl}/myevents/`;

        // IMPORTANT: remove when New Creator Experience is over
        // https://eventbrite.atlassian.net/browse/EB-199459
        if (HAS_WINDOW) {
            const isCreatorHomeActivated =
                window.__SERVER_DATA__?.isCreatorHomeActivated;
            url = isCreatorHomeActivated
                ? `${serverUrl}/organizations/home`
                : url;
        }
        return url;
    };

    return {
        accountSettings: {
            type: ANCHOR_LINK,
            content: gettext('Account settings'),
            path: `${serverUrl}/${accountSettingsPath}/`,
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_PROFILE_DROPDOWN,
                category: CATEGORY_HEADER,
                label: LABEL_ACCOUNT,
            }),
        },
        accountSettingsWithIcon: {
            type: ANCHOR_LINK,
            // NOTE: Even though `accountSettings` & `accountSettingsWithIcon` go to the same place,
            // we need their `path` property to be different because it's ultimately used as
            // the `key` when rendering the menu items. So this one below omits the trailing
            // slash to be different.
            path: `${serverUrl}/${accountSettingsPath}`,
            content: userContent,
            name: 'user-menu-divider',
            'data-spec': 'user-menu-divider',
            imageUrl: userImageUrl,
            imageAlt: fullName,
            iconType: userImageUrl ? null : <UserChunky />,
            iconColor: 'grey-500',
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_PROFILE_DROPDOWN,
                category: CATEGORY_HEADER,
                label: LABEL_EMAIL_NAME_ACCOUNT,
            }),
        },
        admin: {
            type: ANCHOR_LINK,
            content: gettext('Admin'),
            path: `${adminServerUrl}/admin/search/`,
            iconType: <GlobeChunky />,
            target: '_blank',
            rel: 'noopener noreferrer',
        },
        attendeeNewsletter: {
            type: ANCHOR_LINK,
            content: gettext('Attendee newletter'),
            path: `${serverUrl}/hybrid_newsletter/`,
            target: '_blank',
            rel: 'noopener noreferrer',
        },
        browseEvents: {
            type: ANCHOR_LINK,
            path: `${serverUrl}/d/local/all-events/`,
            content: gettext('Browse events'),
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_DISCOVER,
                category: CATEGORY_HEADER,
            }),
        },
        collections: {
            type: ANCHOR_LINK,
            content: gettext('Collections'),
            path: `${serverUrl}/u/${publicId}/favorites/collections`,
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_PROFILE_DROPDOWN,
                category: CATEGORY_HEADER,
                label: LABEL_COLLECTIONS,
            }),
        },
        contacts: {
            type: ANCHOR_LINK,
            content: gettext('Contacts'),
            path: `${serverUrl}/contacts/`,
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_PROFILE_DROPDOWN,
                category: CATEGORY_HEADER,
                label: LABEL_ORGANIZER_PROFILE,
            }),
        },
        create: {
            type: ANCHOR_LINK,
            content: gettext('Create event'),
            path: `${serverUrl}/create/`,
        },
        featureFlags: {
            type: ANCHOR_LINK,
            content: gettext('Feature Flags'),
            path: `${adminServerUrl}/admin/nexus/gargoyle/`,
            iconType: <FlagChunky />,
            target: '_blank',
            rel: 'noopener noreferrer',
        },
        following: {
            type: ANCHOR_LINK,
            content: shouldShowFollowCount
                ? gettext('Following (%(numFollowing)s)', { numFollowing })
                : gettext('Following'),
            path: `${serverUrl}/u/${publicId}/followed/`,
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_PROFILE_DROPDOWN,
                category: CATEGORY_HEADER,
                label: LABEL_FOLLOWING,
            }),
        },
        interests: {
            type: ANCHOR_LINK,
            content: gettext('Interests'),
            path: isDetachedInterests
                ? `${serverUrl}/u/interests/tags/?mode=detached&referrer=${getReferrerUrl()}`
                : `${serverUrl}/u/interests`,
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_PROFILE_DROPDOWN,
                category: CATEGORY_HEADER,
                label: LABEL_INTERESTS,
            }),
        },
        logout: {
            type: ANCHOR_LINK,
            content: gettext('Log out'),
            path: logoutUrl,
            onClick: logOutFunctions.bind(null, requestPath),
        },
        manageEvents: {
            type: ANCHOR_LINK,
            content: gettext('Manage my events'),
            path: creatorUrl(),
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_PROFILE_DROPDOWN,
                category: CATEGORY_HEADER,
                label: LABEL_MY_EVENTS,
            }),
        },
        organizerProfile: {
            type: ANCHOR_LINK,
            content: gettext('Organizer profile'),
            path: `${serverUrl}/myprofile/`,
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_PROFILE_DROPDOWN,
                category: CATEGORY_HEADER,
                label: LABEL_ORGANIZER_PROFILE,
            }),
        },
        saved: {
            type: ANCHOR_LINK,
            content: shouldShowNumSavedEvents
                ? gettext('Liked (%(numSavedEvents)s)', { numSavedEvents })
                : gettext('Liked'),
            path: `${serverUrl}/u/${publicId}/saved`,
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_PROFILE_DROPDOWN,
                category: CATEGORY_HEADER,
                label: LABEL_SAVED,
            }),
        },
        sponsorships: {
            type: ANCHOR_LINK,
            content: (
                <div>
                    <span>{gettext('Sponsorships')}</span>
                    <span className={badgeClassName}>
                        <Badge text={gettext('Beta')} bgColor="ui-600" />
                    </span>
                </div>
            ),
            path: `${serverUrl}/sponsorship/connect/search/`,
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_PROFILE_DROPDOWN,
                category: CATEGORY_HEADER,
                label: LABEL_SPONSORSHIPS,
            }),
        },
        studio: {
            type: ANCHOR_LINK,
            // NOTE: "Studio" is the product name and therefore is intentionally not translated below
            content: (
                <div>
                    <span>Studio</span>
                    <span className={badgeClassName}>
                        <Badge text={gettext('New')} bgColor="success" />
                    </span>
                </div>
            ),
            path: `${serverUrl}/studio/`,
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_PROFILE_DROPDOWN,
                category: CATEGORY_HEADER,
                label: LABEL_STUDIO,
            }),
        },
        credits: {
            type: ANCHOR_LINK,
            content: gettext('Credits'),
            path: `${serverUrl}/u/credits/`,
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_PROFILE_DROPDOWN,
                category: CATEGORY_HEADER,
                label: LABEL_CREDITS,
            }),
        },
        tickets: {
            type: ANCHOR_LINK,
            content: gettext('Tickets (%(numOrders)s)', { numOrders }),
            path: `${serverUrl}/u/${publicId}/`,
            onClick: getAnalyticsClickHandler({
                requestPath,
                action: ACTION_PROFILE_DROPDOWN,
                category: CATEGORY_HEADER,
                label: LABEL_TICKETS,
            }),
        },
        tikibar: {
            type: ANCHOR_LINK,
            content: gettext('Tikibar'),
            path: '/tikibar/settings/',
            iconType: <InfoChunky />,
            target: '_blank',
            rel: 'noopener noreferrer',
        },
    };
};
