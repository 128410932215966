import {
    Bug as BugSvg,
    HeartChunky as HeartChunkySvg,
    TicketChunky as TicketChunkySvg,
} from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import { getAnalyticsClickHandler } from '@eventbrite/site-analytics';
import React from 'react';

const CATEGORY_HEADER = 'header';

// These were originally exported from core-js-global
// But ever since the refactor to @eventbrite/site-header, they
// seem to have removed these.
export const getLikesQuickLinks = (
    serverUrl: string,
    ebDomain: string,
    requestPath: string,
    action_from: string,
    publicId?: string,
) => [
    {
        url: `${serverUrl}/u/${publicId}/favorites/events`,
        content: gettext('Likes'),
        iconType: <HeartChunkySvg />,
        iconColor: 'grey-800',
        onClick: getAnalyticsClickHandler(
            {
                requestPath,
                action: action_from,
                category: CATEGORY_HEADER,
            },
            {
                checkpointName: `${action_from}_header_link_clicked`,
                domain: ebDomain,
            },
        ),
    },
];

export const getMyTicketsQuickLinks = (
    serverUrl: string,
    ebDomain: string,
    requestPath: string,
    action_from: string,
    publicId?: string,
) => [
    {
        url: `${serverUrl}/u/${publicId}/`,
        content: gettext('Tickets'),
        iconType: <TicketChunkySvg />,
        iconColor: 'grey-800',
        onClick: getAnalyticsClickHandler(
            {
                requestPath,
                action: action_from,
                category: CATEGORY_HEADER,
            },
            {
                checkpointName: `${action_from}_header_link_clicked`,
                domain: ebDomain,
            },
        ),
    },
];

export const getBugQuickLink = () => [
    {
        url: 'https://docs.google.com/forms/d/e/1FAIpQLSfgAjPWo5RoQMiChhY1SlrrYycUnUUQjFTPkGAfCc1UCjXyWg/viewform',
        content: gettext('Report a Bug'),
        iconType: <BugSvg />,
    },
];
