import pick from 'lodash/pick';
import React, { PureComponent } from 'react';
import classNames from 'classnames';

import BaseCarousel from './_internal/BaseCarousel';
import FullCarousel from './_internal/FullCarousel';

import {
    BASE_CAROUSEL_PROPS,
    FULL_CAROUSEL_PROPS,
    CAROUSEL_PROP_TYPES,
    TYPE_ICON,
} from './constants';
import './carousel.scss';

export default class Carousel extends PureComponent {
    static propTypes = CAROUSEL_PROP_TYPES;

    static defaultProps = {
        initialDisplayIndex: 0,
        itemsPerSlide: 1,
        fullWidth: false,
        alwaysShowSideNavigation: false,
        alwaysShowBottomNavigation: false,
        bottomNavigationType: TYPE_ICON,
        fixAspectRatio: false,
        isNotContinuous: false,
        hasTopControls: false,
        usesNewStyle: false,
    };

    render() {
        const {
            children,
            alwaysShowSideNavigation,
            alwaysShowBottomNavigation,
            autoTransition,
            hasTopControls,
            usesNewStyle,
            title,
            newTitleStyle,
            subtitle,
            ...props
        } = this.props;
        const carouselClassNames = classNames('eds-carousel', {
            'eds-carousel--has-top-controls': hasTopControls,
            'eds-carousel--new-style': usesNewStyle,
        });

        const fullCarousel = (
            <FullCarousel
                {...pick(props, FULL_CAROUSEL_PROPS)}
                initialShowSideNavigation={alwaysShowSideNavigation}
                initialAutoTransition={autoTransition}
                showBottomNavigation={alwaysShowBottomNavigation}
                usesNewStyle={usesNewStyle}
                title={title}
                subtitle={subtitle}
                newTitleStyle={newTitleStyle}
            >
                {children}
            </FullCarousel>
        );

        if (hasTopControls) {
            return (
                <section className={carouselClassNames}>{fullCarousel}</section>
            );
        }

        return (
            <section className={carouselClassNames}>
                <div className="eds-show-up-sw">{fullCarousel}</div>
                <div className="eds-show-down-sm eds-align--center">
                    <BaseCarousel
                        {...pick(props, BASE_CAROUSEL_PROPS)}
                        customNavigation={undefined}
                        itemsPerSlide={1}
                        showNavigation={true}
                    >
                        {children}
                    </BaseCarousel>
                </div>
            </section>
        );
    }
}
