import {
    initDimensionsFromState,
    trackAnalyticsEvent,
    trackInitialPageView,
} from '@eventbrite/site-analytics';

export enum AnalyticsCategory {
    EventCard = 'eventcard',
    Collections = 'creator-collections',
    Create = 'creator-collections-create',
    Listing = 'creator-collections-listing',
    RelatedEvents = 'related-events',
}

export enum AnalyticsAction {
    AutoCollectionInitiate = 'AutoCollectionInitiate',
    CollectionPromote = 'CollectionPromote',
    CollectionView = 'CollectionView',
    CopyURL = 'CopyURL',
    DevicePreview = 'DevicePreview',
    EditCollection = 'EditCollection',
    EventsShowMore = 'EventsShowMore',
    EventsTabClick = 'EventsTabClick',
    OrganizerName = 'OrganizerName',
    PreviewPage = 'PreviewPage',
}

export const initAnalytics = (props: any) => {
    initDimensionsFromState(props);
    trackInitialPageView(props.gaSettings);
};

export const trackEvent = ({
    action,
    label,
    category,
    dimensions = {},
}: {
    action: AnalyticsAction;
    label: string;
    category: AnalyticsCategory;
    dimensions?: object;
}) => {
    trackAnalyticsEvent({
        action,
        label,
        category,
        dimensions,
    });
};
