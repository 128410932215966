import PropTypes from 'prop-types';
import { TYPE_PROP_TYPE as ITEM_TYPE_PROP_TYPE } from '@eventbrite/eds-nav-list-item';
import { ICON_TYPE_PROP_TYPE } from '@eventbrite/eds-icon';

export const ITEMS_PROP_TYPE = PropTypes.arrayOf(
    PropTypes.shape({
        type: ITEM_TYPE_PROP_TYPE.isRequired,
        content: PropTypes.node,
        secondaryContent: PropTypes.node,
        path: PropTypes.string,
        active: PropTypes.bool,
        isDisabled: PropTypes.bool,
        iconType: ICON_TYPE_PROP_TYPE,
        onActivate: PropTypes.func,
        onClick: PropTypes.func,
        showNewBadge: PropTypes.bool,
    }),
);
