import {
    CoreApplicationContext,
    CoreGoogleAnalyticsSettings,
} from '@eventbrite/context-gen';
import { DestinationEvent } from '@eventbrite/event-renderer';
import { FeatureFlags } from '@eventbrite/feature-flag-utils';
import { ShareProps } from '@eventbrite/social-share';

export type CollectionProps = {
    collection: Collection;
    events_in_collection: {
        past: EventListProps;
        upcoming: EventListProps;
    };
    share_props: ShareProps;
    permissions: {
        can_manage: boolean;
    };
    more_collections_from_this_organizer?: MoreCollectionsFromThisOrganizer;
    other_events_you_may_like?: OtherEventsYouMayLike;
    preview?: boolean;
};

export type CollectionMovedProps = {
    collection_moved: boolean;
    other_events_you_may_like?: OtherEventsYouMayLike;
    share_props: ShareProps;
    preview?: boolean;
};

type FeatureFlagsProps = {
    featureFlags?: FeatureFlags;
};

export type AppProps = CoreApplicationContext &
    CoreGoogleAnalyticsSettings &
    CollectionProps &
    FeatureFlagsProps;

export type CollectionMovedServerData = CoreApplicationContext &
    CoreGoogleAnalyticsSettings &
    CollectionMovedProps &
    FeatureFlagsProps;

export type CollectionServerData = AppProps;

export type ServerData = CollectionMovedServerData | CollectionServerData;
export interface EventListProps {
    pagination: Pagination;
    events: EventOriginType[];
}

export enum CollectionStatus {
    Archived = 'archived',
    Deleted = 'deleted',
    Draft = 'draft',
    Live = 'live',
}

export interface MoreCollectionsFromThisOrganizer {
    pagination: Pagination;
    event_groups: CollectionCardOriginType[];
}

export interface OtherEventsYouMayLike {
    pagination: Pagination;
    events: DestinationEvent[];
}

export interface CollectionImage {
    edge_color?: string | null;
    url: string;
    srcSet?: string;
    crop_mask: null | {
        width: number;
        height: number;
        top_left: {
            x: number;
            y: number;
        };
    };
}

export interface Collection {
    id: string;
    name: string;
    slug: string;
    image: CollectionImage | null;
    organizer: Organizer;
    summary?: string;
    status: string;
    url: string;
    is_autocreated?: boolean;
    is_private?: boolean;
}

export interface CollectionEventsProps {
    past: EventListProps;
    upcoming: EventListProps;
}

interface Organizer {
    id: string;
    name: string;
    url: string;
    logo?: null | {
        url: string;
    };
    description: string;
    follow_status: FollowStatus;
    organization_id: string;
}

interface FollowStatus {
    followed_by_you: boolean;
    followed_at?: any;
    num_followers: number;
}

export interface Pagination {
    object_count: number;
    continuation?: string;
    page_count: number;
    page_size: number;
    has_more_items: boolean;
    page_number: number;
}

export interface CollectionEventCard {
    id: string;
    imageUrl: string;
    isFree: boolean;
    isPrivate?: boolean;
    isRestricted?: boolean;
    isOnlineEvent: boolean;
    locationInfo: string;
    name: string;
    isRepeatingEvent?: boolean;
    savedByYou: boolean;
    startDate?: string;
    startTime: string;
    timezone: string;
    url: string;
    eventSalesStatus?: {
        salesStatus: string;
        message: string;
        messageType: string;
        messageCode: string;
        defaultMessage?: string;
    };
    isSoldOut?: boolean;
    edgeColor?: string;
    formattedPriceString?: string;
    minPrice?: {
        currency: string;
        currencyFormat?: string;
        minPriceValue: number;
    };
    repeatingInstanceCount?: number;
    subtitle?: string;
    ticketsBy?: string;
    organizerId?: string;
    organizerName?: string;
    organizerFollowerCount?: number;
}

export interface EventOriginType {
    id: string;
    logo?: {
        url: string;
    };
    listed: boolean;
    is_free: boolean;
    online_event: boolean;
    start: {
        utc: string;
        timezone: string;
        local: string;
        date_header?: string;
        formatted_time?: string;
    };
    name: {
        text: null | string;
    };
    saves?: {
        saved_by_you: boolean;
    };
    hide_start_date: boolean;
    url: string;
    event_sales_status?: {
        default_message: null | string;
        sales_status: string;
        message: null | string;
        message_code: null | string;
        message_type: null | string;
    };
    series_id?: string | null;
    image?: {
        edge_color_set: boolean;
        edge_color: string | null;
        url: string;
        original: {
            url: string;
            width: number;
            height: number;
        };
        crop_mask: {
            width: number;
            height: number;
            top_left: {
                y: number;
                x: number;
            };
        };
        aspect_ratio: string;
        id: string;
    };
    is_protected_event?: boolean;
    price_range?: string;
    ticket_availability?: {
        is_sold_out: boolean;
        is_free: boolean;
        minimum_ticket_price: {
            currency: string;
            major_value: string;
            value: number;
            display: string;
        };
        has_available_tickets: boolean;
        maximum_ticket_price: {
            currency: string;
            major_value: string;
            value: number;
            display: string;
        };
    };
    subtitle?: string;
    tickets_by?: string;
    organizer_id: string;
    primary_organizer?: {
        name: string;
        num_followers: number;
    };
    is_series_parent: boolean;
    num_children?: number;
    series?: {
        url: string;
        counts: { current_future: number };
        next_dates: { start: string }[];
    };
    venue?: {
        age_restriction: string;
        user_id: string;
        name: string;
        longitude: string;
        address: {
            city: string;
            country: string;
            region: string;
            longitude: string;
            localized_address_display: string;
            postal_code: string;
            address_1: string;
            latitude: string;
            localized_multi_line_address_display: string[];
            localized_area_display: string;
        };
        latitude: string;
        capacity: 500;
        organizer_id: string;
        google_place_id: string;
        id: string;
    };
}

export interface CollectionCardOriginType {
    status: CollectionStatus[keyof CollectionStatus];
    name: string;
    summary: string;
    organization_id: string;
    image_id: string | null;
    // TODO: Need image_url
    image?: { url: string };
    id: string;
    organizer_id: string;
    relative_url: string;
    type: string;
    event_count: {
        current_future_published: number;
    };
}

export enum AffiliateCodes {
    EventsInOrganizerCollection = 'odcleoeventsincollection', // Events in Organizer Collection
    SocialShareToolsInCollection = 'odclsxcollection', // Social Share
    RelatedCollectionsFromOrganizer = 'odclrlmcfto', // More Collections from this organizer
    RelatedEventsFromOrganizer = 'odclrlmefto', // More Events from this organizer
    RelatedEventsOtherOrganizers = 'ebdsreoeymlcollection', // Other Events you may like
}
