import { Icon } from '@eventbrite/eds-icon';
import { ArrowRightChunky, CloudAbstract } from '@eventbrite/eds-iconography';
import { Illustration } from '@eventbrite/eds-illustration';
import { setWindowLocation } from '@eventbrite/http';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { AnalyticsAction, AnalyticsCategory, trackEvent } from '../../_shared';
import { defaultAutocreatedCollectionPillTitle } from '../../_shared/constants';
import {
    ArrowIcon,
    BackgroundLayer,
    FinishEditing,
    PillWrapper,
    Step,
    StepCounter,
    StepsList,
    Title,
} from './AutocreatedPill.styles';

export const LABEL_EDIT = 'Edit';

export const PUBLISHING_STEPS = [
    gettext("Update your collection's name, description, and image").toString(),
    gettext('Add or remove events').toString(),
    gettext('Publish and share with the world').toString(),
];

export interface Props {
    collectionId: string;
}

export const AutocreatedPill: React.FC<Props> = ({ collectionId }) => (
    <PillWrapper>
        <BackgroundLayer>
            <Illustration height="100%" width="100%" type={<CloudAbstract />} />
        </BackgroundLayer>
        <Title>{defaultAutocreatedCollectionPillTitle}</Title>
        <StepsList>
            {PUBLISHING_STEPS.map((text, index) => (
                <Step key={`autocreated-publishing-step-${index}`}>
                    <StepCounter>{index + 1}</StepCounter>
                    {text}
                </Step>
            ))}
        </StepsList>
        <FinishEditing
            onClick={() => {
                trackEvent({
                    action: AnalyticsAction.AutoCollectionInitiate,
                    category: AnalyticsCategory.Collections,
                    label: LABEL_EDIT,
                });
                setWindowLocation(`/manage/collections/${collectionId}/events`);
            }}
        >
            {gettext('Finish editing collection').toString()}
            <ArrowIcon>
                <Icon color="white" size="xsmall" type={<ArrowRightChunky />} />
            </ArrowIcon>
        </FinishEditing>
    </PillWrapper>
);
