import { CoreEnv } from '@eventbrite/context-gen';
import { getWindowObject } from '../get-window-object';
import { DOMAINS } from './constants';
import { EBDomains } from './types';

export const getKeyWord = (domain: string): EBDomains | null => {
    for (const dom of DOMAINS) {
        if (domain.includes(dom)) {
            return dom;
        }
    }
    return null;
};

export const getDomain = (env?: CoreEnv): EBDomains | null => {
    if (env) {
        return getKeyWord(env.ebDomain);
    }
    const domainFromWindow = getWindowObject('__SERVER_DATA__')?.env?.ebDomain;
    return domainFromWindow
        ? getKeyWord(domainFromWindow)
        : EBDomains.EVENTBRITE;
};
