import { FormattedEvent } from '@eventbrite/event-renderer';
import { CreatorCollectionCardCarouselProps } from '../CardsCarousel/CardsCarousel';
import { CollectionCardOriginType, EventOriginType } from '../types';

// TODO: https://eventbrite.atlassian.net/browse/EB-176961
const getLocationInfo = (venue?: {
    name: string;
    address?: { localized_area_display: string };
}) => {
    if (venue?.name && venue?.address?.localized_area_display) {
        return `${venue?.name} \u2022 ${venue?.address?.localized_area_display}`;
    }

    return venue?.name || venue?.address?.localized_area_display || '';
};

export const transformCollectionEvent = (
    event: EventOriginType,
): FormattedEvent => {
    const [startDate, startTime] =
        event?.series?.next_dates?.[0]?.start.split('T') ||
        event.start.local.split('T');
    return {
        id: event.id,
        imageUrl: event?.logo?.url || '',
        isFree: event.is_free,
        isRepeatingEvent: event.is_series_parent,
        isOnlineEvent: event.online_event,
        locationInfo: getLocationInfo(event?.venue),
        name: event?.name.text || '',
        savedByYou: !!event.saves?.saved_by_you,
        startDate: event.hide_start_date ? undefined : startDate,
        startTime: startTime,
        timezone: event.start.timezone,
        url: event.url,
        isPrivate: !event.listed,
        isRestricted: !!event.is_protected_event,
        eventSalesStatus: {
            salesStatus: event?.event_sales_status?.sales_status || 'on_sale',
            message: event?.event_sales_status?.message || '',
            messageType: event?.event_sales_status?.message_type || '',
            messageCode: event?.event_sales_status?.message_code || '',
        },
        isSoldOut:
            event?.event_sales_status?.sales_status === 'sold_out' &&
            !event?.series_id,
        edgeColor: event?.image?.edge_color || undefined,
        formattedPriceString: event.is_free ? '' : event.price_range,
        minPrice: {
            currency:
                event?.ticket_availability?.minimum_ticket_price?.currency ||
                '',
            currencyFormat: '¤#,##0.00',
            minPriceValue:
                event?.ticket_availability?.minimum_ticket_price?.value || 0,
        },
        subtitle: event.subtitle,
        ticketsBy: event.tickets_by,
        organizerId: event.organizer_id,
        organizerName: event?.primary_organizer?.name || '',
        organizerFollowerCount:
            event?.primary_organizer?.num_followers || undefined,
    };
};

export const transformCollectionCard = (
    collection: CollectionCardOriginType,
): CreatorCollectionCardCarouselProps => {
    const {
        id,
        relative_url,
        name,
        summary,
        image,
        event_count,
        organizer_id,
    } = collection;
    return {
        id,
        slug: relative_url,
        name,
        summary,
        imageUrl: image?.url,
        numUpcomingEvents: event_count.current_future_published,
        organizerId: organizer_id,
    };
};
