export const HORIZONTAL_CARD_BADGE_STYLE: React.CSSProperties = {
    position: 'absolute',
    top: '4px',
    right: '4px',
};

export const HORIZONTAL_LEFT_CARD_BADGE_STYLE: React.CSSProperties = {
    position: 'absolute',
    top: '4px',
    left: '4px',
};
