import { gettext } from '@eventbrite/i18n';
import { getSelectPropValues } from '@eventbrite/eds-select';
import { PRIVACY_REGIME_CPRA, PRIVACY_SIGNAL_GPC } from './constants';
import { HAS_WINDOW } from '@eventbrite/feature-detection';

export const DEVELOPER_URL = 'https://www.eventbrite.com/platform/';

export const SALES_CONTACT_SUPPORTED_TLDS = {
    '.com': 1,
    '.co.uk': 1,
    '.com.au': 1,
    '.de': 1,
    '.ie': 1,
    '.com.ar': 1,
};

const _HTTP_REGEX = /http:/;

export const getLocales = (locales) =>
    getSelectPropValues(locales, 'locale_name', 'locale');

const _getSalesContactLinks = (serverUrl, localeInfo) => {
    const { tld, locale } = localeInfo;
    const supportsSalesContacts =
        tld in SALES_CONTACT_SUPPORTED_TLDS ||
        (tld === '.ca' && locale === 'en_CA');
    let links = [];

    if (supportsSalesContacts) {
        links = [
            {
                url: `${serverUrl}${localeInfo['sales_contact_landing_page']}`,
                content: localeInfo['sales_contact_link_text'],
                additionalClassName:
                    'eds-hide-md eds-hide-mw eds-hide-ln eds-hide-lg eds-hide-lw',
            },
        ];
    }

    return links;
};
const _getImpressumLinks = (impressumUrl, tld) => {
    let links = [];

    if (tld === '.de') {
        links = [
            {
                url: impressumUrl,
                content: 'Impressum',
            },
        ];
    }

    return links;
};
export const _getCAPrivacyNoticeLinks = (serverUrl, tld) => {
    let links = [];

    if (tld === '.com') {
        links = [
            {
                url: `${serverUrl}/support/articles/en_US/Troubleshooting/supplemental-privacy-notice-for-california-residents?lg=en_US`,
                content: 'CA Privacy Notice',
            },
        ];
    }

    return links;
};

export const _getEuDSAStatementLinks = (serverUrl, tld) => {
    let links = [];
    const DSAStatementDomainsMap = new Map([
        ['.be', true], // België and Belgique
        ['.dk', true], // Denmark
        ['.de', true], // Deutschland
        ['.es', true], // España
        ['.fi', true], // Finland
        ['.fr', true], // France
        ['.ie', true], // Ireland
        ['.it', true], // Italia
        ['.nl', true], // Nederland
        ['.at', true], // Österreich
        ['.pt', true], // Portugal
        ['.ch', true], // Suisse and Schweiz
        ['.se', true], // Sverige
        ['.co.uk', true], // United Kingdom
    ]);

    if (DSAStatementDomainsMap.has(tld)) {
        links = [
            {
                url: 'https://www.eventbrite.com/help/en-us/articles/640798/',
                content: 'EU DSA Statement',
            },
        ];
    }
    return links;
};

export const MANAGE_COOKIE_CONSENT = {
    url: '#',
    content: gettext('Manage Cookie Preferences'),
    onClick: (event) => {
        event.preventDefault();
        window?.transcend?.showConsentManager?.({
            viewState: 'CompleteOptions',
        });
    },
};

export const getCookieManagementLink = () => {
    return MANAGE_COOKIE_CONSENT;
};

export const CPRA_DO_NOT_SELL_LINK = {
    url: '#',
    content: gettext('Do Not Sell or Share My Personal Information'),
    onClick: (event) => {
        event.preventDefault();
        window?.transcend?.showConsentManager?.({
            viewState: 'DoNotSellExplainer',
        });
    },
};

export const getPrivacyLink = () => {
    if (!HAS_WINDOW) return false;

    const airgap = window?.airgap;
    const regimes = airgap?.getRegimes?.();
    const privacySignals = airgap?.getPrivacySignals?.();
    const CALIFORNIA_CPRA = regimes?.has?.(PRIVACY_REGIME_CPRA);
    const GPC = privacySignals?.has?.(PRIVACY_SIGNAL_GPC);
    let link;

    if (CALIFORNIA_CPRA || GPC) {
        link = CPRA_DO_NOT_SELL_LINK;
    }

    return link;
};

export const getMinimalGeneralLinks = (serverUrl) => [
    {
        url: `${serverUrl}/l/LegalTerms/`,
        content: gettext('Terms'),
        rel: 'nofollow',
    },
    {
        url: `${serverUrl}/privacypolicy/`,
        content: gettext('Privacy'),
        rel: 'nofollow',
    },
    {
        url: `${serverUrl}/cookies/`,
        content: gettext('Cookies'),
        rel: 'nofollow',
    },
];

export const getGeneralLinks = (serverUrl, localeInfo, helpLinks) => [
    {
        url: `${serverUrl}/how-it-works`,
        content: gettext('How It Works'),
        additionalClassName:
            'eds-hide-md eds-hide-mw eds-hide-ln eds-hide-lg eds-hide-lw',
    },
    {
        url: `${serverUrl}/organizer/pricing/`,
        content: gettext('Pricing'),
        additionalClassName:
            'eds-hide-md eds-hide-mw eds-hide-ln eds-hide-lg eds-hide-lw',
    },
    {
        url: `${localeInfo['contact_us_url']}`,
        content: gettext('Contact Support'),
        additionalClassName:
            'eds-hide-md eds-hide-mw eds-hide-ln eds-hide-lg eds-hide-lw',
    },
    ..._getSalesContactLinks(serverUrl, localeInfo),
    {
        url: `${serverUrl}/about`,
        content: gettext('About'),
    },
    {
        url: `https://${localeInfo['blog_url']}`,
        content: gettext('Blog'),
    },
    {
        url: helpLinks.home,
        content: gettext('Help'),
    },
    {
        url: `${serverUrl}/careers/`,
        content: gettext('Careers'),
    },
    {
        url: `${serverUrl}${localeInfo['press_blog_url']}`,
        content: gettext('Press'),
    },
    {
        url: 'https://www.eventbrite.com/l/impact/',
        content: gettext('Impact'),
    },
    {
        url: 'https://investor.eventbrite.com',
        content: gettext('Investors'),
    },
    {
        url: `${serverUrl}/security/`,
        content: gettext('Security'),
        rel: 'nofollow',
    },
    {
        url: DEVELOPER_URL,
        content: gettext('Developers'),
    },
    {
        url: `${serverUrl}/l/LegalTerms/`,
        content: gettext('Terms'),
        rel: 'nofollow',
    },
    {
        url: `${serverUrl}/privacypolicy/`,
        content: gettext('Privacy'),
        rel: 'nofollow',
    },
    ..._getCAPrivacyNoticeLinks(serverUrl, localeInfo.tld),
    {
        url: `${serverUrl}/l/accessibility/`,
        content: gettext('Accessibility'),
    },
    {
        url: `${serverUrl}/cookies/`,
        content: gettext('Cookies'),
        rel: 'nofollow',
    },
    ..._getEuDSAStatementLinks(serverUrl, localeInfo.tld),
    ..._getImpressumLinks(helpLinks.impressum, localeInfo.tld),
];

export const getSecondarySections = (isUserAuthenticated, countries) => {
    let secondarySections;

    if (isUserAuthenticated) {
        secondarySections = [
            {
                title: gettext('Country'),

                links: countries.map(
                    ({ href, locale, locale_name: content }) => {
                        const url = href.replace(_HTTP_REGEX, 'https:');

                        return {
                            url,
                            content,
                            'data-automation': `footer-${locale}`,
                        };
                    },
                ),
            },
        ];
    }

    return secondarySections;
};

const _getMainLinks = (links) =>
    links.map(({ link, title }) => ({
        url: link,
        content: title,
    }));

const _getSocialLinks = (serverUrl, localeInfo) => [
    {
        url: `${localeInfo['contact_us_url']}`,
        content: gettext('Contact Support'),
    },
    ..._getSalesContactLinks(serverUrl, localeInfo),
    {
        url: `https://${localeInfo['twitter_page']}`,
        content: gettext('Twitter'),
        target: '_blank',
        rel: 'noopener noreferrer',
    },
    {
        url: `https://${localeInfo['facebook_page']}`,
        content: gettext('Facebook'),
        target: '_blank',
        rel: 'noopener noreferrer',
    },
    {
        url: `https://${localeInfo['linked_in_page']}`,
        content: gettext('LinkedIn'),
        target: '_blank',
        rel: 'noopener noreferrer',
    },
    {
        url: `https://${localeInfo['instagram_page']}`,
        content: gettext('Instagram'),
        target: '_blank',
        rel: 'noopener noreferrer',
    },
];

export const getMainSections = (
    serverUrl,
    localeInfo,
    popularSearches,
    eventsToPlan,
    thirdFooterColumn,
    thirdFooterColumnName,
) => [
    {
        title: gettext('Use Eventbrite'),
        links: _getMainLinks(popularSearches),
    },
    {
        title: gettext('Plan Events'),
        links: _getMainLinks(eventsToPlan),
    },
    {
        title: thirdFooterColumnName,
        links: _getMainLinks(thirdFooterColumn),
    },
    {
        title: gettext('Connect With Us'),
        links: _getSocialLinks(serverUrl, localeInfo),
    },
];
