import { Icon } from '@eventbrite/eds-icon';
import { ScreenLarge, ScreenSmall } from '@eventbrite/eds-iconography';
import { setWindowLocation } from '@eventbrite/http';
import { gettext } from '@eventbrite/i18n';
import React, { useContext, useState } from 'react';
import { AutocreatedPill } from '../components/AutocreatedPill';
import { AnalyticsContext, ApplicationContext } from '../context';
import {
    AnalyticsAction,
    AnalyticsCategory,
    isAutocreatedCollectionPreviewWithTips,
    trackEvent,
} from '../_shared';

enum PreviewType {
    mobile = 'mobile',
    desktop = 'desktop',
}

export const PageLayoutPreview: React.FC = ({ children }) => {
    const [preview, setPreview] = useState(PreviewType.desktop);

    const { collectionId } = useContext(AnalyticsContext);
    const context = useContext(ApplicationContext);

    const previewAutocreated =
        (isAutocreatedCollectionPreviewWithTips(context) &&
            context?.collection?.is_autocreated) ||
        false;

    const onChangeDeviceClick = (type: PreviewType) => {
        setPreview(type);
        trackEvent({
            action: AnalyticsAction.DevicePreview,
            category: AnalyticsCategory.Create,
            label: type,
        });
    };

    return (
        <div className="cc-preview">
            <header aria-labelledby="heading" className="cc-preview__header">
                <div
                    id="heading"
                    className="eds-bg-color--background eds-text-bl eds-l-pad-all-4"
                >
                    {previewAutocreated
                        ? gettext('Your example collection')
                        : gettext('Collection preview')}
                </div>
                <button
                    onClick={() => {
                        if (previewAutocreated) {
                            setWindowLocation(`/organizations/collections`);
                        } else {
                            window.close();
                        }
                    }}
                    className="cc-preview__close-button eds-text-bm"
                >
                    {previewAutocreated
                        ? gettext('Back to collections')
                        : gettext('Close preview')}
                </button>
                <div className="cc-preview-nav">
                    <ul className="cc-preview-nav__list">
                        <li className="cc-preview-nav__item">
                            <button
                                disabled={preview === 'desktop'}
                                className="cc-preview-nav__button"
                                onClick={() => {
                                    onChangeDeviceClick(PreviewType.desktop);
                                }}
                            >
                                <Icon
                                    type={<ScreenLarge />}
                                    title={gettext('Show desktop preview')}
                                    size="small"
                                />
                            </button>
                        </li>
                        <li className="cc-preview-nav__item">
                            <button
                                disabled={preview === 'mobile'}
                                className="cc-preview-nav__button"
                                onClick={() => {
                                    onChangeDeviceClick(PreviewType.mobile);
                                }}
                            >
                                <Icon
                                    type={<ScreenSmall />}
                                    title={gettext('Show mobile preview')}
                                    size="small"
                                />
                            </button>
                        </li>
                    </ul>
                </div>
            </header>
            <main className="cc-preview__main-content">
                {previewAutocreated && (
                    <AutocreatedPill collectionId={collectionId} />
                )}
                <div
                    data-testid="preview-collection"
                    className={`cc-preview-collection cc-preview-collection--${preview}`}
                >
                    {children}
                </div>
            </main>
        </div>
    );
};
