import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { gettext } from '@eventbrite/i18n';

export default class NumberBottomNavigation extends PureComponent {
    static propTypes = {
        displayIndex: PropTypes.number.isRequired,
        totalItems: PropTypes.number.isRequired,
        leftNavigation: PropTypes.node,
        rightNavigation: PropTypes.node,
    };

    render() {
        const { displayIndex, totalItems, leftNavigation, rightNavigation } =
            this.props;

        return (
            <nav className="eds-align--center eds-l-pad-vert-4">
                {leftNavigation}
                <div className="eds-l-mar-hor-4">
                    {gettext('%(displayIndex)s/%(totalItems)s', {
                        displayIndex: displayIndex + 1,
                        totalItems,
                    })}
                </div>
                {rightNavigation}
            </nav>
        );
    }
}
