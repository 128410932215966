import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ProgressIndicator } from '@eventbrite/eds-progress-indicator';
import {
    SIZE_NAMES,
    SHAPE_CIRCULAR,
    TYPE_INDETERMINATE,
    STYLE_GRADIENT,
    SIZE_LARGE_CHUNKY,
} from '@eventbrite/eds-progress-indicator';

import './loadingOverlay.scss';

export default class LoadingOverlay extends PureComponent {
    static propTypes = {
        /**
         * Size of the loading indicator
         */
        size: PropTypes.oneOf(SIZE_NAMES),

        /**
         * Whether or not the loading overlay should be shown
         */
        isShown: PropTypes.bool,
    };

    static defaultProps = {
        size: SIZE_LARGE_CHUNKY,
        isShown: false,
    };

    render() {
        const { size, isShown } = this.props;

        if (!isShown) {
            return null;
        }

        return (
            <div className="eds-loading-overlay eds-align--center">
                <div className="eds-loading-overlay__indicator">
                    <ProgressIndicator
                        size={size}
                        shape={SHAPE_CIRCULAR}
                        type={TYPE_INDETERMINATE}
                        style={STYLE_GRADIENT}
                    />
                </div>
            </div>
        );
    }
}
