import { initializeErrorReporting } from '@eventbrite/error-reporting';
import {
    getLocaleOverride,
    getParentLocale,
    setupAsync,
} from '@eventbrite/i18n';
import React from 'react';
import { hydrate, render } from 'react-dom';
import { App } from './app';
import { initAnalytics } from './_shared';

// retrieve data from server to hydrate on the client
const props = window.__SERVER_DATA__ || ({} as any);
const root = document.getElementById('root');
const parentLocale = getParentLocale(
    getLocaleOverride() || props.env.localeInfo.locale,
);

const main = async () => {
    initializeErrorReporting({
        dsn: 'https://b25d12b2cde941bdb236763917f0d17c@o375540.ingest.sentry.io/6573593',
    });

    try {
        if (parentLocale !== 'en_US') {
            await setupAsync({
                parentLocale,
                translations:
                    /* webpackChunkName: "creator-collections-listing-i18n-translation" */ import(
                        `./i18n/translations/${parentLocale}.json`
                    ),
            });
        }
    } finally {
        /*
         * If root has children it means we did SSR
         * so we should hydrate instead of render.
         */
        if (root?.children.length === 0) {
            render(<App {...props} />, root);
        } else {
            hydrate(<App {...props} />, root);
        }
    }
};

main();

initAnalytics(props);
