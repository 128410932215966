import { gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import React from 'react';
import { useGlobalNavContext } from '../../state';
import { getSigninOrSignupUrl } from '../../utils';
import { Logo, MobileDropdown, NavLink, SearchBar } from '../common';
import { NavItemType } from '../types';
import styles from './MobileNav.module.scss';

const MobileNav = () => {
    const { positionSticky, env, navigation } = useGlobalNavContext();
    const { mobile } = navigation ?? {};
    const dropdownItems = mobile as NavItemType[];
    const isSticky = positionSticky?.mobile ? 'mobileSticky' : '';
    const navStyles = classNames(styles.nav, styles.navHeight, isSticky);

    return (
        <nav
            aria-label={gettext('Main Mobile').toString()}
            data-testid="mobile-nav"
            className={navStyles}
        >
            <ul className={styles.root}>
                <li className={styles.logo}>
                    <Logo />
                </li>
                <li
                    className={classNames(
                        styles.searchBarContainer,
                        styles.inlineSearchBar,
                    )}
                >
                    <SearchBar />
                </li>
                <li className={styles.right}>
                    <ul className={styles.linkContainer}>
                        <li className={styles.listLink}>
                            <NavLink
                                heapIdentifier={'login'}
                                translatedText={gettext('Log In')}
                                path={getSigninOrSignupUrl(env?.signinUrl)}
                                className={styles.login}
                            />
                        </li>
                        <li className={styles.listLink}>
                            <NavLink
                                heapIdentifier={'signup'}
                                translatedText={gettext('Sign Up')}
                                path={getSigninOrSignupUrl(env?.signupUrl)}
                                className={styles.signup}
                            />
                        </li>
                        <MobileDropdown dropdownItems={dropdownItems} />
                    </ul>
                </li>
            </ul>
            <div
                className={classNames(
                    styles.searchBarContainer,
                    styles.newlineSearchBar,
                )}
            >
                <SearchBar />
            </div>
        </nav>
    );
};

export default MobileNav;
