import * as React from 'react';

const LockChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="lock-chunky_svg__eds-icon-lock-chunky"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path d="M13 15.732V18h-2v-2.268a2 2 0 112 0z" fill="#4B4D63" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 8h3v14H4V8h3V7a5 5 0 0110 0v1zm-5-4a3 3 0 013 3v1H9V7a3 3 0 013-3zm6 16V10H6v10h12z"
            fill="#4B4D63"
        />
    </svg>
);

LockChunkySvg.displayName = 'LockChunkySvg';
export default LockChunkySvg;
