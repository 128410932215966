import { Button } from '@eventbrite/eds-button';
import { ALIGN_RIGHT } from '@eventbrite/eds-containers';
import { Icon } from '@eventbrite/eds-icon';
import { gettext } from '@eventbrite/i18n';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import classNames from 'classnames';
import findKey from 'lodash/findKey';
import React from 'react';
import { MenuInfoShape } from '../types/index';
import DropdownMenu from './DropdownMenuWithFallback';

const ACTION_PROFILE_DROPDOWN = 'ProfileDropdown';
const CATEGORY_HEADER = 'header';
const CONSUMER_DROPDOWN_HOVER_LABEL = 'ViewFromConsumer';

const GlobalHeaderDropdownMenuImage = ({ imageUrl }: { imageUrl: string }) => (
    <span className="authenticated-header__menu-profile-image--container">
        <img
            className="authenticated-header__menu-profile-image"
            src={imageUrl}
            alt={gettext('Profile Image').toString()}
            data-spec="authenticated-header-menu-profile-image"
        />
        <div className="authenticated-header__menu-profile-image--border" />
    </span>
);

const GlobalHeaderDropdownMenuIcon = ({
    iconType,
    iconColor,
}: {
    iconType: React.ReactNode;
    iconColor: string;
}) => {
    const className = classNames('authenticated-header__menu-icon');
    const icon = null;

    return (
        <span>
            <span
                className={className}
                data-spec="authenticated-header-menu-icon-container"
            >
                <GlobalHeaderMenuIcon
                    iconType={iconType}
                    iconColor={iconColor}
                />
            </span>
            {icon}
        </span>
    );
};

export const GlobalHeaderUserAvatar = (menuInfo: MenuInfoShape) => {
    const { imageUrl, iconType } = menuInfo;

    let component = null;

    const helperTypes: {
        [key: string]: {
            [key: string]: Function;
        };
    } = {
        image: { test: () => imageUrl, fn: GlobalHeaderDropdownMenuImage },
        icon: { test: () => iconType, fn: GlobalHeaderDropdownMenuIcon },
    };

    const _getType = (types: {
        [key: string]: {
            [key: string]: Function;
        };
    }) => {
        const type = findKey(types, (value: any) => value.test());

        let Klass = null;

        if (type) {
            Klass = helperTypes[type].fn;
        }
        return Klass;
    };

    const UserAvatarComponent = _getType(helperTypes);

    if (UserAvatarComponent) {
        component = <UserAvatarComponent {...menuInfo} />;
    }

    return component;
};

export const GlobalHeaderMenuIcon = ({
    iconType,
    iconColor = 'grey-700',
}: {
    iconType: React.ReactNode;
    iconColor: string;
}) => (
    <Icon
        type={iconType}
        size="small"
        color={iconColor}
        data-spec="authenticated-header-menu-icon"
    />
);

export const DropdownComponentByType = ({
    menuInfo,
    onAdditionalMenuTextItemSelect,
    noLabel,
    ...additionalProps
}: {
    menuInfo: MenuInfoShape;
    onAdditionalMenuTextItemSelect?: Function;
    noLabel?: boolean;
    additionalProps?: object;
}) => {
    const {
        label,
        fallbackUrl,
        navItems,
        textItems,
        className,
        preventMouseOver,
    } = menuInfo;

    const menuContainerClassName = classNames(
        className,
        'authenticated-header__menu',
    );
    const trackHoverDropdownMenu = () => {
        trackAnalyticsEvent({
            category: CATEGORY_HEADER,
            action: ACTION_PROFILE_DROPDOWN,
            label: CONSUMER_DROPDOWN_HOVER_LABEL,
        });
    };
    return (
        <div
            className={menuContainerClassName}
            onMouseEnter={trackHoverDropdownMenu}
        >
            <DropdownMenu
                {...additionalProps}
                fallbackUrl={fallbackUrl}
                navItems={navItems}
                textItems={textItems}
                dropdownAlign={ALIGN_RIGHT}
                preventMouseOver={preventMouseOver}
                onTextItemSelect={onAdditionalMenuTextItemSelect}
            >
                <GlobalHeaderUserAvatar {...menuInfo} />
                {!noLabel && (
                    <span
                        className="authenticated-header__menu-label"
                        data-spec="authenticated-header-menu-label"
                    >
                        {label}
                    </span>
                )}
            </DropdownMenu>
        </div>
    );
};

export const DropdownButton = ({
    item,
    iconType,
    iconColor,
    onClick,
}: {
    item: {
        value: string;
        content: JSX.Element;
    };
    iconType: React.ReactNode;
    iconColor: string;
    narrowIconColor?: string;
    narrowIconType?: React.ReactNode;
    onClick: Function;
}) => {
    let component = null;
    const { value, content } = item;

    let icon = null;

    if (iconType) {
        icon = (
            <span className="authenticated-header__menu-icon">
                <GlobalHeaderMenuIcon
                    iconType={iconType}
                    iconColor={iconColor}
                />
            </span>
        );
    }

    component = (
        <div className="authenticated-header__menu-button">
            <Button
                style="none"
                onClick={onClick.bind(null, value, 0)}
                data-spec="authenticated-header-dropdown-button"
            >
                {icon}
                <span className="authenticated-header__menu-label">
                    {content}
                </span>
            </Button>
        </div>
    );

    return component;
};
