import { deepKeysToSnake } from '@eventbrite/transformation-utils';
import { sdkRequest } from '@eventbrite/http';
import { formatUrl } from 'url-lib';

import { pruneObject } from '../utils';

const GET_PLACE_FROM_GOOGLE_PLACE_ID_URL =
    '/api/v3/geo/place_from_google_place_id/';
const GET_PLACE_FROM_COORDINATES_URL = '/api/v3/geo/place_from_coordinates/';

export const getPlaceFromGooglePlaceId = (
    googlePlaceId,
    excludeNeighborhoods,
) => {
    const queryParams = deepKeysToSnake(
        pruneObject({
            googlePlaceId,
            excludeNeighborhoods,
        }),
    );

    return sdkRequest(
        formatUrl(GET_PLACE_FROM_GOOGLE_PLACE_ID_URL, queryParams),
    );
};

export const getPlaceFromCoordinates = ({
    latitude,
    longitude,
    excludeNeighborhoods,
}) => {
    const queryParams = deepKeysToSnake(
        pruneObject({
            latitude,
            longitude,
            excludeNeighborhoods,
        }),
    );

    return sdkRequest(formatUrl(GET_PLACE_FROM_COORDINATES_URL, queryParams));
};
