import { useIsMobileDevice } from '@eventbrite/feature-detection';
import {
    MagnifyingGlassIcon,
    MapPinIcon,
    Typography,
} from '@eventbrite/marmalade';
import loadable from '@loadable/component';
import React, { useState } from 'react';
import { useGlobalNavContext } from '../../../../../state';
import { onEnterKey } from '../../../../../utils';
import {
    getHeapDataAttribute,
    HeapAttributes,
} from '../../../../../utils/heap-data';
import styles from '../SearchBar.module.scss';

const LazySearchTakeover = loadable.lib(
    () => import('@eventbrite/search-takeover'),
);

const SearchBar = () => {
    const { Teams, Features, Components, Actions } = HeapAttributes;
    const { searchBar } = useGlobalNavContext();
    const [showSearch, setShowSearch] = useState(false);

    const isMobile = useIsMobileDevice();

    const handleClick = () => {
        setShowSearch(true);
    };

    function handleClose() {
        setShowSearch(false);
    }

    if (!searchBar?.renderSearchBar) {
        return null;
    }

    return (
        <button
            className={styles.searchBarWrapper}
            data-testid="header-search"
            onClick={handleClick}
            onKeyDown={(e) => onEnterKey(e, handleClick)}
            aria-label={
                isMobile
                    ? searchBar?.mobileLabel?.toString()
                    : searchBar?.desktopLabel?.toString()
            }
            data-heap-id={getHeapDataAttribute({
                team: Teams.SEO,
                feature: Features.GlobalNav,
                component: Components.SearchBar,
                name: 'search-bar',
                action: Actions.Click,
            })}
        >
            <>
                <Typography
                    variant="body-md"
                    as="span"
                    color="neutral-700"
                    className={styles.searchBarContent}
                >
                    <div className={styles.searchContent}>
                        <MagnifyingGlassIcon size="small" />
                        <span className={styles.desktopLabel}>
                            {searchBar?.desktopLabel?.toString()}
                        </span>
                        <span className={styles.mobileLabel}>
                            {searchBar?.mobileLabel?.toString()}
                        </span>
                    </div>
                    <MapPinIcon size="small" />
                </Typography>

                {showSearch ? (
                    <LazySearchTakeover data-testid="search-takeover">
                        {({ SearchTakeover }: { SearchTakeover: any }) => (
                            <SearchTakeover
                                domNodeId="root"
                                onClose={handleClose}
                                id="search-takeover"
                                isVisible={showSearch}
                                location={searchBar?.location}
                            />
                        )}
                    </LazySearchTakeover>
                ) : null}
            </>
        </button>
    );
};

export default SearchBar;
