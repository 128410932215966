import * as React from 'react';

const ScreenSmallSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="screen-small_svg__eds-icon--screen-small_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="screen-small_svg__eds-icon--screen-small_base"
            d="M7 3h10v1H7V3zm0 2h10v12H7V5zm0 13h10v3H7v-3zM6 2v20h12V2H6z"
        />
        <path
            id="screen-small_svg__eds-icon--screen-small_dash"
            d="M11 19h2v1h-2z"
        />
    </svg>
);

ScreenSmallSvg.displayName = 'ScreenSmallSvg';
export default ScreenSmallSvg;
