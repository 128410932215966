import React from 'react';

export type FeatureFlags = {
    [key: string]: boolean;
};

export const FeatureFlagsContext = React.createContext<FeatureFlags | null>(
    null,
);

export const FeatureFlagsProvider: React.FC<{
    value: FeatureFlags;
}> = ({ value, children }) => {
    const [features, setFeatures] = React.useState<FeatureFlags>(value);

    React.useEffect(() => {
        setFeatures(value);
    }, [value]);

    return (
        <FeatureFlagsContext.Provider value={features}>
            {children}
        </FeatureFlagsContext.Provider>
    );
};

export const useFeatureFlags = () => {
    const features = React.useContext(FeatureFlagsContext);

    if (features === null) {
        throw new Error(
            'You must wrap your components in a FeatureFlagsProvider',
        );
    }

    return features;
};
