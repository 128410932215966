import { IconButton, STYLE_DARK } from '@eventbrite/eds-icon-button';
import { HeartChunky, HeartFillChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import { logEvent } from '@eventbrite/statsig';
import React, { lazy, Suspense } from 'react';
import { HEAP_EVENT_LIKE_CLICK } from '../../constants/analytics';
import { useEventCardTrackingContext } from '../EventCardTracking';
import { useSaveEvent } from './hooks';

const LoggedOutRedirect = lazy(() => import('./LoggedOutRedirect'));

interface EventCardSaveActionProps {
    isAuthenticated: boolean;
    savedByYou: boolean;
    id: string;
    eventName: string;
    imageUrl?: string;
    onClick?: (isSaved: boolean, eventId: string) => void;
    actionStyle?: string;
}

export const EventCardSaveAction: React.FunctionComponent<
    EventCardSaveActionProps
> = (props) => {
    const { savedByYou, id } = props;
    const { statsigLocationString } = useEventCardTrackingContext();
    const [renderLoggedOutRedirect, setRenderLoggedOutRedirect] =
        React.useState(false);

    const { isSaved, handleSaveEvent } = useSaveEvent({
        initialSaved: savedByYou,
        eventId: props.id,
        isAuthenticated: props.isAuthenticated,
        onCompleted: (saved: boolean) => {
            props.onClick?.(saved, props.id);
        },
    });

    const handleSaveClick = () => {
        //add statsig tracking here. pull it into central tracking?
        if (statsigLocationString) {
            logEvent(`${statsigLocationString} - ${HEAP_EVENT_LIKE_CLICK}`);
        }

        if (props.isAuthenticated) {
            handleSaveEvent({ savedByYou: isSaved, eventId: props.id });
        } else {
            setRenderLoggedOutRedirect(true);
        }
    };

    return (
        <>
            <IconButton
                title={gettext('Save this event: %(eventName)s', {
                    eventName: props.eventName,
                })}
                iconType={isSaved ? <HeartFillChunky /> : <HeartChunky />}
                onClick={handleSaveClick}
                aria-pressed={isSaved}
                iconColor={isSaved ? 'ui-orange--hover' : 'grey-700'}
                style={props.actionStyle ? props.actionStyle : STYLE_DARK}
                data-event-id={id}
            />
            {renderLoggedOutRedirect && (
                <Suspense fallback={<div />}>
                    <LoggedOutRedirect
                        isShown={renderLoggedOutRedirect}
                        eventId={props.id}
                        onClose={() => setRenderLoggedOutRedirect(false)}
                        eventName={props.eventName}
                        imageUrl={props.imageUrl}
                    />
                </Suspense>
            )}
        </>
    );
};
