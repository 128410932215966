import React, { PureComponent } from 'react';
import { HotKeys } from 'react-hotkeys';
import SideNavigation from './SideNavigation';
import BaseCarousel from './BaseCarousel';
import {
    DIRECTION_LEFT,
    DIRECTION_RIGHT,
    FULL_CAROUSEL_PROP_TYPES,
} from '../constants';
import { getPreviousIndex, getNextIndex } from './utils';
import { MOVE_TO_NEXT, MOVE_TO_PREV } from '@eventbrite/eds-hot-keys';
import { ACTION_KEY_MAP } from '../hotKeys';

export const Title = ({ title, subtitle, newTitleStyle }) => {
    const titleClasses = newTitleStyle
        ? 'eds-carousel__title--new-style'
        : 'eds-carousel__title';
    const headingClasses = newTitleStyle
        ? 'eds-text-hm eds-carousel__heading--new-style'
        : 'eds-text-hs eds-l-mar-bot-2';

    return (
        <div className={titleClasses}>
            <p className={headingClasses}>{title}</p>
            <p className="eds-text-bm eds-text-color--ui-500">{subtitle}</p>
        </div>
    );
};

class FullCarousel extends PureComponent {
    static propTypes = FULL_CAROUSEL_PROP_TYPES;

    constructor(props) {
        super(props);

        const {
            initialShowSideNavigation,
            initialAutoTransition,
            initialDisplayIndex,
            children,
        } = props;

        this.state = {
            displayIndex: initialDisplayIndex,
            showNavigation:
                children.length > 1 ? initialShowSideNavigation : false,
            autoTransition: initialAutoTransition,
        };
    }

    hotKeyHandlers = {
        [MOVE_TO_NEXT]: this._handleNext.bind(this),
        [MOVE_TO_PREV]: this._handlePrevious.bind(this),
    };

    _handleMouseEnter = () => {
        const { children, itemsPerSlide } = this.props;

        this.setState({
            showNavigation: children.length > itemsPerSlide,
            autoTransition: false,
        });
    };

    _handleMouseExit = () => {
        const { initialAutoTransition, initialShowSideNavigation, children } =
            this.props;

        this.setState({
            showNavigation:
                children.length > 1 ? initialShowSideNavigation : false,
            autoTransition: initialAutoTransition,
        });
    };

    _handlePrevious() {
        const {
            children: { length },
            onSwipeLeft,
            isNotContinuous,
        } = this.props;

        this.setState(
            getPreviousIndex.bind(null, length, !isNotContinuous),
            () => {
                if (onSwipeLeft) {
                    onSwipeLeft({ currentIndex: this.state.displayIndex });
                }
            },
        );
    }

    _handleNext() {
        const {
            children: { length },
            onSwipeRight,
            isNotContinuous,
        } = this.props;

        this.setState(getNextIndex.bind(null, length, !isNotContinuous), () => {
            if (onSwipeRight) {
                onSwipeRight({ currentIndex: this.state.displayIndex });
            }
        });
    }

    _handleTransition = (displayIndex) => {
        const { onTransition } = this.props;

        this.setState({ displayIndex });

        if (onTransition) {
            onTransition(displayIndex);
        }
    };

    render() {
        const {
            bottomNavigationType,
            children,
            fullWidth,
            itemsPerSlide,
            customNavigation,
            showBottomNavigation,
            fixAspectRatio,
            isNotContinuous,
            transitionDuration,
            usesNewStyle,
            title,
            newTitleStyle,
            subtitle,
        } = this.props;
        const { displayIndex, autoTransition, showNavigation } = this.state;
        let titleHeader = null;

        if (title) {
            titleHeader = (
                <Title
                    title={title}
                    newTitleStyle={newTitleStyle}
                    subtitle={subtitle}
                />
            );
        }
        const isLeftNavigationDisabled = isNotContinuous && displayIndex === 0;
        const leftNavigationNode = (
            <SideNavigation
                bottomNavigationType={bottomNavigationType}
                fullWidth={fullWidth}
                type={DIRECTION_LEFT}
                shouldShow={showNavigation}
                onClick={this._handlePrevious.bind(this)}
                isDisabled={isLeftNavigationDisabled}
                usesNewStyle={usesNewStyle}
            />
        );
        const isRightNavigationDisabled =
            isNotContinuous && displayIndex === children.length - 1;
        const rightNavigationNode = (
            <SideNavigation
                bottomNavigationType={bottomNavigationType}
                fullWidth={fullWidth}
                type={DIRECTION_RIGHT}
                shouldShow={showNavigation}
                onClick={this._handleNext.bind(this)}
                isDisabled={isRightNavigationDisabled}
                usesNewStyle={usesNewStyle}
            />
        );

        return (
            <HotKeys keyMap={ACTION_KEY_MAP} handlers={this.hotKeyHandlers}>
                <div
                    onMouseEnter={this._handleMouseEnter}
                    onMouseLeave={this._handleMouseExit}
                >
                    <BaseCarousel
                        bottomNavigationType={bottomNavigationType}
                        fullWidth={fullWidth}
                        itemsPerSlide={itemsPerSlide}
                        inheritedDisplayIndex={displayIndex}
                        autoTransition={autoTransition}
                        onTransition={this._handleTransition}
                        customNavigation={customNavigation}
                        showNavigation={showBottomNavigation}
                        leftNavigationNode={leftNavigationNode}
                        rightNavigationNode={rightNavigationNode}
                        fixAspectRatio={fixAspectRatio}
                        transitionDuration={transitionDuration}
                        usesNewStyle={usesNewStyle}
                        title={titleHeader}
                        onPrevious={this._handlePrevious.bind(this)}
                        onNext={this._handleNext.bind(this)}
                    >
                        {children}
                    </BaseCarousel>
                </div>
            </HotKeys>
        );
    }
}

export default FullCarousel;
