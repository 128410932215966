import {
    CreatorCollectionCard,
    CreatorCollectionCardProps,
} from '@eventbrite/creator-collection-card';
import { Carousel } from '@eventbrite/eds-carousel';
import {
    CONSUMER_CARD_DEFAULTS,
    DestinationEvent,
    EventCard,
    EventClient,
    EventClientProvider,
    FormattedEvent,
    transformDestinationEvent,
} from '@eventbrite/event-renderer';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import {
    getLabelFromShareType,
    ShareType,
    trackShareAction,
} from '@eventbrite/social-share';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { formatUrl } from 'url-lib';
import { updateEventData } from '../CollectionEvents/CollectionEvents';
import { AnalyticsContext, ApplicationContext } from '../context';
import {
    AffiliateCodes,
    AppProps,
    CollectionCardOriginType,
    EventOriginType,
} from '../types';
import {
    AnalyticsAction,
    AnalyticsCategory,
    initSignalProp,
    trackEvent,
} from '../_shared';
import {
    transformCollectionCard,
    transformCollectionEvent,
} from '../_shared/transform';
import './CardsCarousel.scss';

export type OriginCards =
    | EventOriginType[]
    | CollectionCardOriginType[]
    | DestinationEvent[];

export interface RelatedCardsProps {
    affCode?: AffiliateCodes;
    cards: OriginCards;
    cardsType: CardsType;
    newTitleStyle?: boolean;
    organizerId: string;
    title?: GenericLazyString;
    isFromDestination?: boolean;
    dataTestId?: string;
}

export type RelatedCardsChildrenProps = Omit<RelatedCardsProps, 'organizerId'>;

export enum CardsType {
    COLLECTION = 'collection',
    EVENT = 'event',
}

export interface CreatorCollectionCardCarouselProps
    extends CreatorCollectionCardProps {
    id: string;
    organizerId: string;
}

function useExtendedEventData(
    initialEvents: FormattedEvent[],
): FormattedEvent[] {
    const [events, setEvents] = useState(initialEvents);
    useEffect(() => {
        const eventIdList = events.map((event) => event.id);
        updateEventData(eventIdList).then((newUpcomingEventsData) => {
            if (newUpcomingEventsData?.events.length > 0) {
                setEvents(newUpcomingEventsData.events);
            }
        }); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setEvents]);
    return events;
}

const _getEventCards = ({
    events,
    affCode,
}: {
    events: FormattedEvent[];
    affCode?: AffiliateCodes;
}) => {
    const context = (useContext(ApplicationContext) as AppProps) || undefined;
    const { organizerId: pageOwnerOrganizerId } = useContext(AnalyticsContext);

    const updatedEvents = useExtendedEventData(events);
    return updatedEvents.map((event: FormattedEvent) => {
        const shareEventProps = {
            eventId: event.id,
            eventName: event.name,
            eventUrl: event.url,
            ...context?.share_props,
        };

        // Prop to set or not the signal data on the EventCard component
        const signalProp = initSignalProp(
            pageOwnerOrganizerId as string,
            event.organizerId,
        );

        return (
            <EventCard
                {...CONSUMER_CARD_DEFAULTS}
                key={event.id}
                affCode={affCode}
                eventData={event}
                gaCategory={AnalyticsCategory.RelatedEvents}
                isAuthenticated={context?.user.isAuthenticated || false}
                shareOptions={
                    context?.share_props ? shareEventProps : undefined
                }
                description={
                    event.isRepeatingEvent ? (
                        <div className="eds-text-color--ui-orange">
                            {gettext('More dates available')}
                        </div>
                    ) : undefined
                }
                onDidShareClick={(type: ShareType) =>
                    trackShareAction({
                        label: getLabelFromShareType(type),
                        category: AnalyticsCategory.EventCard,
                    })
                }
                onWillShareClick={() =>
                    trackShareAction({
                        label: 'open-modal',
                        category: AnalyticsCategory.EventCard,
                    })
                }
                {...signalProp}
            />
        );
    });
};

const _getCollectionCards = ({
    collections,
    affCode,
}: {
    collections: CreatorCollectionCardCarouselProps[];
    affCode?: AffiliateCodes;
}) => {
    return collections.map((collection: CreatorCollectionCardCarouselProps) => {
        const { id, name, slug, summary, imageUrl, numUpcomingEvents } =
            collection;

        const handleClick = () => {
            trackEvent({
                action: AnalyticsAction.CollectionView,
                category: AnalyticsCategory.RelatedEvents,
                label: 'more-collections-from-this-organizer',
            });
        };

        return (
            <CreatorCollectionCard
                key={id}
                name={name}
                slug={
                    affCode
                        ? formatUrl(slug, {
                              aff: affCode,
                          })
                        : slug
                }
                summary={summary}
                imageUrl={imageUrl}
                onClick={handleClick}
                numUpcomingEvents={numUpcomingEvents}
            />
        );
    });
};

const getCardMap = ({
    cardsType,
    formattedCards,
    affCode,
}: {
    cardsType: CardsType;
    formattedCards: FormattedEvent[] | CreatorCollectionCardCarouselProps[];
    affCode?: AffiliateCodes;
}): JSX.Element[] => {
    if (formattedCards === undefined) {
        return [];
    }

    return cardsType === CardsType.EVENT
        ? _getEventCards({
              events: formattedCards as FormattedEvent[],
              affCode,
          })
        : _getCollectionCards({
              collections:
                  formattedCards as CreatorCollectionCardCarouselProps[],
              affCode,
          });
};

type Carousels = {
    title?: GenericLazyString;
    newTitleStyle?: boolean;
    CardMap: JSX.Element[];
    CardMapForDesktop: JSX.Element[];
    affCode?: AffiliateCodes;
    dataTestId?: string;
};

const Carousels = ({
    title,
    newTitleStyle,
    CardMap,
    CardMapForDesktop,
    affCode,
    dataTestId,
}: Carousels) => {
    const props = {
        alwaysShowSideNavigation: true,
        hasTopControls: true,
        isNotContinuous: true,
        usesNewStyle: true,
        title: title,
        newTitleStyle: newTitleStyle,
        fullWidth: true,
        affCode: affCode,
    };

    return (
        <section
            className="carousel-cards g-grid g-grid--page-margin-manual"
            data-testid={dataTestId}
        >
            <div className="eds-g-cell eds-g-cell--has-overflow eds-g-cell-1-1 eds-l-pad-hor-5 eds-l-ln-pad-hor-0 eds-l-lg-pad-hor-0 eds-l-lw-pad-hor-0 eds-l-mar-bot-16">
                <div className="eds-show-up-mn" data-testid="carousel-desktop">
                    <Carousel {...props}>{CardMapForDesktop}</Carousel>
                </div>

                <div
                    className="eds-show-down-sw carousel-mobile"
                    data-testid="carousel-mobile"
                >
                    <Carousel {...props}>{CardMap}</Carousel>
                </div>
            </div>
        </section>
    );
};

const formatEventsFromDestination = (cards: DestinationEvent[]) => {
    return cards.map((event: DestinationEvent) =>
        transformDestinationEvent(event),
    );
};

const formatEventsFromOrigin = (cards: EventOriginType[]) => {
    return cards.map((event: EventOriginType) =>
        transformCollectionEvent(event),
    );
};

export const formatEvents = (
    cards: EventOriginType[] | DestinationEvent[],
    isFromDestination?: boolean,
): FormattedEvent[] =>
    isFromDestination
        ? formatEventsFromDestination(cards as DestinationEvent[])
        : formatEventsFromOrigin(cards as EventOriginType[]);

export const formatCollections = (
    cards: CollectionCardOriginType[],
): CreatorCollectionCardCarouselProps[] =>
    cards.map((collection) => transformCollectionCard(collection));

const formatCards = (
    isEvent: boolean,
    cards: OriginCards,
    isFromDestination?: boolean,
): FormattedEvent[] | CreatorCollectionCardCarouselProps[] => {
    return isEvent
        ? formatEvents(
              cards as EventOriginType[] | DestinationEvent[],
              isFromDestination,
          )
        : formatCollections(cards as CollectionCardOriginType[]);
};

enum ItemsPerSlide {
    EVENTS = 3,
    COLLECTIONS = 2,
}

const _getItemsPerSlideDesktop = (isEvent: boolean) =>
    isEvent ? ItemsPerSlide.EVENTS : ItemsPerSlide.COLLECTIONS;

export const CardsCarousel = (
    props: RelatedCardsChildrenProps,
): JSX.Element => {
    const {
        cardsType,
        cards,
        title,
        newTitleStyle,
        affCode,
        isFromDestination,
        dataTestId,
    } = props;

    const isEvent: boolean = cardsType === CardsType.EVENT;
    const desktopSlideClasses = classNames(
        'desktop-slide',
        { 'desktop-slide--events': isEvent },
        { 'desktop-slide--collections': !isEvent },
    );

    const formattedCards = formatCards(isEvent, cards, isFromDestination);

    const CardMap = getCardMap({
        cardsType,
        formattedCards,
        affCode,
    });

    const itemsPerSlideDesktop = _getItemsPerSlideDesktop(isEvent);
    const wrapCardMapForDesktop = (itemsPerSlideDesktop: number) => {
        const slides = [];

        for (let i = 0; i < CardMap.length; i += itemsPerSlideDesktop) {
            const slideItem = CardMap.filter(
                (_, index) => index >= i && index < i + itemsPerSlideDesktop,
            );
            slides.push(slideItem);
        }
        return slides;
    };
    const CardMapForDesktop = wrapCardMapForDesktop(itemsPerSlideDesktop).map(
        (slide, index) => {
            return (
                <div className={desktopSlideClasses} key={index}>
                    {slide}
                </div>
            );
        },
    );

    const Component = (props: Carousels): JSX.Element => {
        if (isEvent) {
            const eventClient = new EventClient();

            return (
                <EventClientProvider client={eventClient}>
                    <Carousels {...props} />
                </EventClientProvider>
            );
        }
        return <Carousels {...props} />;
    };

    return (
        <Component
            {...{
                title,
                newTitleStyle,
                CardMap,
                CardMapForDesktop,
                affCode,
                dataTestId,
            }}
        />
    );
};
